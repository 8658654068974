import i18n from '@/plugins/i18n';

export default {
  nl: {
    name: 'Dutch',
    messages: i18n.global.messages.nl,
  },
  fr: {
    name: 'French',
    messages: i18n.global.messages.fr,
  },
};
