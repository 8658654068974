const getDefaultState = () => {
    return {
        categories: {
            all: [],
            promise: null
        },
        current_category: {
            data: {},
            promise: null,
        },
    }
};

export default {
    namespaced: true,
    state: () => (getDefaultState()),

    getters: {
        ARTICLE_CATEGORIES(state) {
            return state.categories.all;
        },
        CURRENT_ARTICLE_CATEGORY(state) {
            return state.current_category.data;
        }
    },

    actions: {
        FETCH_ARTICLE_CATEGORIES({state, commit}) {
            if (state.categories.promise) {
                return state.categories.promise;
            }

            const promise = axios.get('/news-categories').then((response) => {
                commit('UPDATE_ARTICLE_CATEGORIES', response.data.data);
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_ARTICLE_CATEGORIES', null);
            });

            commit('UPDATE_PROMISE_ARTICLE_CATEGORIES', promise);
            return promise;
        },

        FETCH_CURRENT_CATEGORY({state, commit, dispatch}, slug) {
            if (state.current_category.promise) {
                return state.current_category.promise;
            }

            const promise = axios.get('/news-categories/' + slug).then((response) => {
                commit('UPDATE_CURRENT_CATEGORY',  _.get(response,'data.data', {}));
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_CURRENT_CATEGORY', null);
            });

            commit('UPDATE_PROMISE_CURRENT_CATEGORY', promise);
            return promise;
        },

        RESET_CATEGORIES({commit}) {
            commit('SET_DEFAULT_DATA');
        },
    },

    mutations: {
        UPDATE_ARTICLE_CATEGORIES(state, data) {
            return state.categories.all = data;
        },

        UPDATE_PROMISE_ARTICLE_CATEGORIES(state, data) {
            return state.categories.promise = data;
        },

        UPDATE_CURRENT_CATEGORY(state, data) {
            return state.current_category.data = data;
        },

        UPDATE_PROMISE_CURRENT_CATEGORY(state, data) {
            return state.current_category.promise = data;
        },

        SET_DEFAULT_DATA(state) {
            const defaultState = getDefaultState();
            return _.assign(state, defaultState);
        }
    }
}
