<template>
  <footer class="position-absolute start-0 end-0">
    <div class="categories-section">
      <div class="categories-section__header">
        <default-space>
          <h3>{{ $t('footer.title') }}</h3>
        </default-space>
      </div>
      <div class="categories-section__categories">
        <default-space container-class="grid-container">
          <div v-for="(category, i) in categories" :key="i">
            <router-localized-link
              :to="{name : 'promos'}"
              :params="category.id ? {ch_filter: [category.id]} : {}"
            >
              {{ category.translation.simplified_title }}
            </router-localized-link>
          </div>
        </default-space>
      </div>
    </div>
    <div class="pages-section">
      <default-space container-class="d-flex">
        <div class="pages-section__container">
          <div>
            <router-localized-link :to="{name: 'promos'}" v-t="'menu.main.promos'"/>
          </div>
          <div class="dot">&#183;</div>
          <div>
            <router-localized-link :to="{name: 'folders'}" v-t="'menu.main.folders'"/>
          </div>
          <div class="dot">&#183;</div>
          <div v-if="ARTICLES.length">
            <router-localized-link :to="{name: 'news'}" v-t="'header.menu.news'"/>
          </div>
          <div v-if="ARTICLES.length" class="dot">&#183;</div>
          <div>
            <router-localized-link :to="{name: 'stores'}" v-t="'menu.main.stores'"/>
          </div>
          <div class="dot">&#183;</div>
          <div>
            <router-localized-link :to="{name: 'webshops'}" v-t="'menu.main.webshops'"/>
          </div>
          <div class="dot">&#183;</div>
          <div>
            <router-localized-link :to="{name: 'brands'}" v-t="'menu.main.brands'"/>
          </div>
          <div class="dot">&#183;</div>
          <div>
            <router-localized-link :to="{name: 'products'}" v-t="'menu.main.products'"/>
          </div>
          <div class="dot">&#183;</div>

          <template v-for="(page, i) in pagesInFooter" :key="i">
            <div>
              <router-localized-link :to="{path: page.route}">{{ page.title }}</router-localized-link>
            </div>
            <div class="dot">&#183;</div>
          </template>

          <div v-if="!xsOnly && mobileApp">
            <router-localized-link :to="{path: mobileApp.route}" v-text="mobileApp.title"/>
            <div class="dot">&#183;</div>
          </div>
        </div>
      </default-space>
    </div>
    <div class="policy-section">
      <default-space container-class="d-flex justify-content-between">
        <div class="py-1 d-md-inline-flex">
          <div class="policy-section__promojagers">
            © Promojagers {{ currentYear }}
          </div>

          <div class="d-md-inline-flex">
            <span v-if="!xsOnly" class="mx-1">|</span>
            <a class="hover:text-underline" href="https://pricebuddy.be/" target="_blank">PriceBuddy</a>
            <span class="mx-1">|</span>
            <router-localized-link class="hover:text-underline" :to="{path: '/terms'}" v-t="'footer.terms_and_conditions'">
            </router-localized-link>
            <span v-if="!xsOnly" class="mx-1">|</span>
            <br v-else/>
            <router-localized-link class="hover:text-underline" :to="{path: '/privacy-policy'}">Privacy policy
            </router-localized-link>
          </div>
        </div>
        <div class="contacts d-inline-flex align-items-center px-2">
          <a href="https://www.facebook.com/promojagersbelgie" class="me-2" target="_blank"><i class="icon-Facebook-2"/></a>
          <a href="https://www.instagram.com/promojagersbelgie_official" class="me-2" target="_blank">
            <i class="icon-Instagram-2"/>
          </a>
          <a href="https://www.linkedin.com/company/promojagers/" class="me-2" target="_blank"><i
            class="icon-Linkedin"/></a>
          <a href="https://www.tiktok.com/@promojagers_belgie" class="me-2" target="_blank"><i class="icon-TikTok"/></a>
          <a href="https://www.youtube.com/@promojagersbelgie" target="_blank"><i class="icon-YouTube"/></a>
        </div>
      </default-space>
    </div>
  </footer>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';
import DefaultSpace from '@/views/DefaultSpace.vue';

export default {
  name: 'main-footer',
  components: {DefaultSpace, RouterLocalizedLink},
  computed: {
    ...mapGetters('page', ['PAGES', 'PAGES_LOADED']),
    ...mapGetters('auth', ['LOCALE']),
    ...mapGetters('category', ['CATEGORIES']),
    ...mapGetters('article', [
      'ARTICLES'
    ]),
    currentYear() {
      return new Date().getFullYear()
    },
    pagesInFooter() {
      const thisElem = this
      const excludedPages = ['privacy-policy', 'mobile-app', 'terms', 'folders']

      return _.filter(this.PAGES, function (page) {
        return page.in_footer && thisElem.$i18n.locale === page.locale.slug && !excludedPages.includes(page.slug);
      })
    },
    mobileApp() {
      return _.find(this.PAGES, {slug: 'mobile-app'})
    },
    /**
     * Filtered Categories
     * @returns {*}
     */
    categories() {
      return this.CATEGORIES.filter((c) => c.translation?.simplified_title)
    },
  },
  beforeMount() {
    if (!this.CATEGORIES.length) {
      this.FETCH_CATEGORIES()
    }

    if (!this.PAGES_LOADED) {
      this.FETCH_PAGES()
    }
  },
  methods: {
    ...mapActions('page', ['FETCH_PAGES']),
    ...mapActions('category', ['FETCH_CATEGORIES']),
  },
}
</script>
