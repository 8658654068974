<template>
  <div class="folder-announcement-section">
    <default-space>
      <folder-announcement-carousel :folders="POPULAR_FOLDERS"/>
    </default-space>
  </div>
</template>

<script>
import DefaultSpace from '@/views/DefaultSpace.vue';
import FolderAnnouncementCarousel from '@/components/feed/carousels/FolderAnnouncementCarousel.vue';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'FolderAnnouncementSection',
  components: {FolderAnnouncementCarousel, DefaultSpace},
  computed: {
    ...mapGetters('folder-popular', {
      POPULAR_FOLDERS: 'FOLDERS',
      POPULAR_FOLDERS_PROMISE: 'FOLDERS_PROMISE',
    }),
  },
  mounted() {
    this.FETCH_FOLDERS({
      folder_client_types: ['1'],
    })
  },
  methods: {
    ...mapActions('folder-popular', [
      'FETCH_FOLDERS',
    ]),
  }
}
</script>
