<template>
  <base-filter ref="filter" filter-key="reset_filter" must-have hide-divider>
    <template v-slot:header>
      <div class="filter-header bg-secondary--dark" @click="reset">
        <span class="filter-header__text" style="color: #3a00029e">{{ $t('filters.filters') }}:</span>
        <span class="filter-header__text--mini" style="color: #3a00029e" v-t="'filters.reset'"/>
      </div>
    </template>
  </base-filter>
</template>

<script>
import BaseFilter from '@/components/left_bar/filters/BaseFilter';
import {mapActions} from 'vuex';

export default {
  name: 'reset-filter',
  components: {BaseFilter},
  methods: {
    ...mapActions('filter', [
      'RESET_FILTERS',
    ]),
    reset() {
      if (this.$store.getters['auth/GUEST']) {
        window.open(this.$router.resolve({name: 'promos'}).href, '_self');
        return;
      }

      this.$router.replace({name: 'promos'}).then(() => {
        this.RESET_FILTERS(true)
        this.$emit('reset')
      })
    }
  }
}
</script>
