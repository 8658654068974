const post = {
    getTitle(post) {
        if (post.title && post.title.length < 150) {
            return post.title;
        } else if (post.webshop) {
            return post.webshop.translation.title;
        } else if (post.webshops.length) {
            return post.webshops[0].translation.title;
        } else {
            return post.short_content;
        }
    },
};

export default post;
