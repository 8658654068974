<template>
  <div class="free-client-recommendations">
    <div class="flex-grow-1 centered">
      <h3 class="text-danger">
        <span>{{ $t('folder.pager.check_these_folders') }}</span>
      </h3>
    </div>

    <div class="w-100 centered">
      <div class="store-items-container flex-wrap">
        <template v-for="(store, i) in storesList" :key="i">
          <div class="store-image-item">
            <router-localized-link :to="{name: 'folders.store', params: {storeSlug: String(store.translation.route)}}">
              <img :src="store.image_url" alt="" :title="store.translation.title">
            </router-localized-link>
          </div>
        </template>
      </div>
    </div>

    <div class="flex-grow-1 centered">
      <h3>
        <span>{{ $t('folder.pager.or_check_more_folder_current_store.1') }}</span>
        <router-localized-link
          :to="{name: 'folders.store', params: {storeSlug: String(CURRENT_STORE.translation.route)}}"
        >
          &nbsp;<span>{{ $t('folder.pager.or_check_more_folder_current_store.2') }}</span>
        </router-localized-link>
        <span>{{ $t('folder.pager.or_check_more_folder_current_store.3') }}</span>
        {{ storeTitle }}
      </h3>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';

export default {
  name: 'free-client-recommendations',
  components: {RouterLocalizedLink},
  computed: {
    ...mapGetters('store', [
      'STORES',
      'CURRENT_STORE',
    ]),
    ...mapGetters('folder', [
      'CURRENT_FOLDER',
    ]),

    storeTitle() {
      return _.get(this.CURRENT_STORE, 'translation.title', '');
    },

    storesList() {
      const currentFolderCategories = _.map(this.CURRENT_FOLDER.categories, 'id');
      let result = _.filter(this.STORES, (need) => {
        if (need.is_paid_client) {
          return true;
        }
        if (need.folders_count < 1) {
          return false;
        }
        if (_.intersection(_.map(need.categories, 'id'), currentFolderCategories).length > 0) {
          return true;
        }
        return false;
      });
      result = _.sortBy(result, 'folder_client_type');
      result = _.take(result, 9);
      return result;
    },
  },
  mounted() {
    if (!this.STORES.length) {
      this.FETCH_STORES();
    }
  },
  methods: {
    ...mapActions('store', [
      'FETCH_STORES',
    ]),
  }
}
</script>
