export const breadcrumbs = {
    methods: {
        getBreadcrumbStructure() {
            const breadcrumb = this.$route.meta.breadcrumb();
            const itemListElement = [];
            _.forEach(breadcrumb, function (item, i) {
                itemListElement.push({
                    '@type': 'ListItem',
                    'position': i + 1,
                    'name': _.get(item, 'text', ''),
                    'item': _.get(item, 'href', ''),
                });
            });

            return {
                '@context': 'https://schema.org',
                '@type': 'BreadcrumbList',
                'itemListElement': itemListElement,
            }
        }
    },
};
