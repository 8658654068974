<template>
  <div class="folder-promo">
    <div class="folder-promo-header" ref="folder_promo_header">
      <el-avatar
        :src="folderPromo.folderPage.folder.store.image_url"
        shape="circle"
        class="folder-promo-author-avatar"
      />

      <div class="folder-promo-author">
        <div class="folder-promo-author-name">
          {{ folderPromo.folderPage.folder.store.translation.title }}
        </div>
        <div class="folder-promo-time" :class="{'color-primary': !folderPromo.is_valid}">
          {{ folderPromo.valid_text }}
        </div>
      </div>

      <div class="folder-promo-actions">
        <div class="folder-promo-prices" v-if="!xsOnly">
          <template v-if="folderPromo.old_price">
            <div class="old-price">
              {{ folderPromo.old_price }} €
            </div>
            <div class="price-divider">|</div>
          </template>
          <div class="new-price">
            {{ folderPromo.new_price }} €
          </div>
        </div>

        <div class="folder-promo-favourite">
          <i
            class="cursor-pointer"
            :class="{'active icon-heart-2': folderPromo.is_favourite, 'icon-heart': !folderPromo.is_favourite}"
            @click="processFavourite"
          />
        </div>
      </div>
    </div>

    <div class="folder-promo-prices" v-if="xsOnly">
      <template v-if="folderPromo.old_price">
        <div class="old-price">
          {{ folderPromo.old_price }} €
        </div>
        <div class="price-divider">|</div>
      </template>
      <div class="new-price">
        {{ folderPromo.new_price }} €
      </div>
    </div>

    <div class="folder-promo-content">
      {{ folderPromo.title }}

      <hash-tags :tags="tags"/>

      <div class="folder-promo-carousel" ref="folder_promo_carousel">
        <el-carousel :height="carouselHeight + 'px'" arrow="never">
          <el-carousel-item>
            <div class="folder-promo-carousel-image">
              <img
                :src="folderPromo.image_url"
                :style="`max-width: ${carouselWidth}px; height: ${carouselHeight}px`"
                alt=""
                @click="openFolder">
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="folder-link my-3">
        <el-button
          type="primary"
          class="hover:button-primary--dark"
          :class="{'w-75': xsOnly,'w-50': !xsOnly}"
          @click="openFolder"
        >
          <span class="font-size-16 font-weight-bold" v-t="'promo.view_the_folder'"/>
          <i class="icon-arrow-3"/>
        </el-button>
      </div>
    </div>

    <reactions
      class="px-3.25"
      :rating="folderPromo.rating_calculated"
      :is-like="folderPromo.is_hit"
      :comments-count="commentsCount"
      @react="$refs.commentForm.focusTextarea()"
      @hit="processHit"
      @share="processShare"
    />

    <div class="folder-promo-footer">
      <div class="comments-wrapper mt-1">
        <comments-header :hidden-total="hiddenComments" :total="commentsCount" @show-more="showMoreComments"/>

        <comments-loading :loading="commentsLoading"/>

        <comments :comments-data="visibleCommentsData" @hit="hitCommentProcess"/>

        <comments-loading :loading="commentsPushing"/>

        <comment-form ref="commentForm" @submit="pushComment"/>
      </div>
    </div>
  </div>
</template>

<script>
import CommentForm from '@/components/feed/comment/CommentForm';
import Comments from '@/components/feed/comment/Comments';
import {comments} from '@/mixins/comments';
import CommentsHeader from '@/components/feed/comment/CommentsHeader';
import CommentsLoading from '@/components/feed/comment/CommentsLoading';
import {slug} from '@/mixins/slug';
import {needAuth} from '@/mixins/auth/needAuth';
import {actions} from '@/mixins/post/actions';
import HashTags from '@/components/feed/post/HashTags';
import Reactions from '@/components/feed/Reactions.vue';

export default {
  name: 'folder-promo',
  components: {Reactions, HashTags, CommentsLoading, CommentsHeader, Comments, CommentForm},
  props: {
    folderPromo: Object,
    mini: {
      type: Boolean,
      default: false
    },
    vuexModule: {
      type: String,
      default: 'promo',
    },
  },
  mixins: [actions, comments, slug, needAuth],
  data() {
    return {
      carouselHeight: 0,
      carouselWidth: 0,
    }
  },
  computed: {
    /**
     * Data for mixin
     * @returns {{morphable_type: string, entity: Object}}
     */
    entityData() {
      return {
        entity: this.folderPromo,
        morphable_type: 'FolderItem'
      }
    },
    tags() {
      const result = [];
      result.push({
        text: this.convertToSlug(this.$t('post.promos_filter.folder_promos')),
        link: this.$router.resolve({name: 'promos'}).href,
        entityType: 'type'
      });

      if (_.get(this.folderPromo, 'folderPage.folder.store.id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.folderPromo, 'folderPage.folder.store.translation.title')),
          link: this.$router.resolve({
            name: 'store.show',
            params: {storeSlug: _.get(this.folderPromo, 'folderPage.folder.store.translation.route')}
          }).href,
          entityType: 'store'
        });
      }

      if (_.get(this.folderPromo, 'brands[0].id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.folderPromo, 'brands[0].translation.title')),
          link: this.$router.resolve({
            name: 'brand.show',
            params: {brandSlug: _.get(this.folderPromo, 'brands[0].translation.route')}
          }).href,
          entityType: 'brand'
        });
      }

      return result;
    }
  },
  mounted() {
    new ResizeObserver(() => this.calculateHeight()).observe(this.$refs.folder_promo_header);

    this.commentsCount = this.folderPromo.comments_count;
    this.comments = this.folderPromo.comments;
    this.visibleComments = _.min([3, this.folderPromo.comments.length]);
  },
  methods: {
    calculateHeight() {
      if (!this.$refs.folder_promo_header) {
        return 0;
      }
      // cropped images are too small. So 2 times smaller(50% of container width)
      this.carouselWidth = this.$refs.folder_promo_header.clientWidth / 2 - 20;
      this.carouselHeight = 0;

      const currentImage = new Image();
      currentImage.src = this.folderPromo.image_url;
      const thisElem = this;
      currentImage.onload = function () {
        const currentHeight = thisElem.carouselWidth < currentImage.width
          ? (thisElem.carouselWidth / currentImage.width * currentImage.height)
          : currentImage.height

        if (thisElem.carouselHeight < currentHeight) {
          thisElem.carouselHeight = _.min([currentHeight, thisElem.mini ? 250 : 400]);
        }
      };
    },
    showMoreComments() {
      this.showMoreCommentsProcess('FolderItem', this.folderPromo.id);
    },
    pushComment(form) {
      this.pushCommentProcess('FolderItem', this.folderPromo.id, form);
    },
    openFolder() {
      this.routerPush({
        name: 'folder.view',
        params: {
          storeSlug: this.folderPromo.folderPage.folder.store.translation.route,
          folderDate: this.folderPromo.folderPage.folder.valid_from,
        },
        query: {
          id: this.folderPromo.folderPage.folder.id,
          page: this.folderPromo.folderPage.page
        }
      });
      this.$emit('folder-link-clicked');
    },
    processShare(destination) {
      const link = new URL(
          this.$router.resolve({
            name: 'folder_promos.show',
            params: {id: this.folderPromo.id}
          }).href,
          window.location.origin
      ).href;
      this.shareLink(link, destination);
    },
  }
}
</script>
