<template>
  <default-grid :show-right-container="false">
    <template v-slot:left-container>
      <left-bar :show-bookmarks="false"/>
    </template>

    <template v-slot:main-container>
      <div class="feed-container favorites-container">
        <!--
            Header
        -->
        <div class="favorites-header">
          <div class="w-100 position-absolute">
            <tabs :tabs="[]" menu-class="border-bottom-right-radius-3.5"/>
          </div>

          <div class="d-flex align-items-center" :class="{'ms-4.5': !mdAndUp}">
            <h2>{{ $t('menu.user.favourites') }}</h2>
          </div>
        </div>

        <!--
            Page content
        -->
        <div class="favorites-content">
          <!--     Folders     -->
          <div
            v-if="folders.length > 0 && (defaultChecked.length === 0 || defaultChecked.includes('folders'))"
            class="mb-5 position-relative"
          >
            <div class="btn-container">
              <button v-if="buttons.folders.more" @click="openFull('folders')" class="btn-more">
                {{ $t('favorites.more') }}
              </button>
              <button v-else-if="buttons.folders.less" @click="hideFull('folders')" class="btn-more">
                {{ $t('favorites.less') }}
              </button>
            </div>
            <template v-if="fullContent.folders">
              <div class="header-title">
                <h3 class="favorites-content__title m-0">{{ $t('favorites.favorite-folders') }}</h3>
              </div>
              <div class="scrollbar-flex-content wrapper-items-content">
                <div class="scrollbar-item folders-scrollbar" v-for="(item, i) in folders" :key="i">
                  <folder-preview :folder="item"/>
                </div>
              </div>
            </template>
            <folders-carousel
              v-else
              header-class="folders-carousel-header px-0"
              :title="$t('favorites.favorite-folders')"
              :folders="folders"
              :is-loading="!!FOLDERS_PROMISE"
              :custom-width="customWidth"
              hide-arrows-if-ended
              @rendered="carouselRendered"
            />
          </div>

          <!--     Promos  & Discounts   -->
          <div
            v-if="promo.length > 0 && (defaultChecked.length === 0 || defaultChecked.includes('promos'))"
            class="mb-5 position-relative"
          >
            <div class="btn-container">
              <button v-if="buttons.promos.more" @click="openFull('promos')" class="btn-more">
                {{ $t('favorites.more') }}
              </button>
              <button v-else-if="buttons.promos.less" @click="hideFull('promos')" class="btn-more">
                {{ $t('favorites.less') }}
              </button>
            </div>
            <template v-if="fullContent.promos">
              <div class="header-title">
                <h3 class="favorites-content__title m-0">{{ $t('favorites.favorite-promos') }}</h3>
              </div>
              <div class="scrollbar-flex-content position-relative wrapper-items-content">
                <div class="scrollbar-item" v-for="(item, i) in promo" :key="i">
                  <post-mini v-if="'Post' === item.morphable_type" :post="item.data"/>
                  <folder-promo-mini v-else-if="'FolderItem' === item.morphable_type" :folder-promo="item.data"/>
                  <online-promo-mini v-else-if="'OnlinePromo' === item.morphable_type" :online-promo="item.data"/>
                </div>
              </div>
            </template>
            <promos-carousel
              v-else
              header-class="folder-promos-carousel-header px-0"
              :title="$t('favorites.favorite-promos')"
              :promos="promo"
              :is-loading="!!PROMOS_PROMISE"
              :custom-width="customWidth"
              @rendered="carouselRendered"
            />
          </div>

          <!--     Article   -->
          <div
              v-if="articles.length > 0 && (defaultChecked.length === 0 || defaultChecked.includes('articles'))"
              class="mb-5 position-relative"
          >
            <div class="btn-container">
              <button v-if="buttons.articles.more" @click="openFull('articles')" class="btn-more">
                {{ $t('favorites.more') }}
              </button>
              <button v-else-if="buttons.articles.less" @click="hideFull('articles')" class="btn-more">
                {{ $t('favorites.less') }}
              </button>
            </div>
            <template v-if="fullContent.articles">
              <div class="header-title">
                <h3 class="favorites-content__title m-0">{{ $t('favorites.favorite-article') }}</h3>
              </div>
              <div class="scrollbar-flex-content articles-wrapper-items-content">
                <div class="scrollbar-item" v-for="(item, i) in articles" :key="i">
                  <news-preview :article="item" :layout="{col_lg: 24, col_xl: 24}"/>
                </div>
              </div>
            </template>
            <articles-carousel
                v-else
                header-class="articles-carousel-header px-0"
                :title="$t('favorites.favorite-article')"
                :articles="articles"
                :is-loading="!!ARTICLES_PROMISE"
                :custom-width="customWidthArticle"
                hide-arrows-if-ended
                @rendered="carouselRendered"
            />
          </div>

          <infinite-loading v-if="(defaultChecked.length !== 0)" @infinite="load">
            <template #spinner>
              <div class="spinner-container" v-loading="true"></div>
            </template>
            <template #complete>
              <div/>
            </template>
            <template #error>
              <div/>
            </template>
          </infinite-loading>
        </div>
      </div>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from '@/views/DefaultGrid';
import LeftBar from '@/components/left_bar/LeftBar';
import {mapActions, mapGetters} from 'vuex';
import FoldersCarousel from '@/components/feed/carousels/FoldersCarousel.vue';
import PromosCarousel from '@/components/feed/carousels/PromosCarousel.vue';
import PostMini from '@/components/feed/post/PostMini.vue';
import Post from '@/components/feed/post/Post.vue';
import FolderPreview from '@/components/feed/folder/FolderPreview.vue';
import OnlinePromoMini from '@/components/feed/promo/OnlinePromoMini.vue';
import InfiniteLoading from 'v3-infinite-loading';
import FolderPromoMini from '@/components/feed/promo/FolderPromoMini.vue';
import Tabs from '@/components/feed/Tabs.vue';
import ArticlesCarousel from '@/components/feed/carousels/ArticlesCarousel';
import NewsPreview from '@/components/feed/news/NewsPreview';

export default {
  name: 'FavouritesView',
  components: {
    NewsPreview,
    ArticlesCarousel,
    Tabs,
    FolderPromoMini,
    OnlinePromoMini,
    FolderPreview,
    PostMini,
    PromosCarousel,
    // Post,
    InfiniteLoading,
    FoldersCarousel,
    DefaultGrid,
    LeftBar,
  },
  data() {
    return {
      fetchOptions: {
        include_filters: ['morphable_types'],
        morphable_types: [],
        per_page: 100,
        folders_included: 1,
        not_paid_folders_included: 1,
        only_favourites: 1,
        order: 'favourite_at'
      },
      fullContent: {
        folders: false,
        promos: false,
        posts: false,
        articles: false,
      },
      buttons: {
        folders: {
          more: false,
          less: false,
        },
        promos: {
          more: false,
          less: false,
        },
        posts: {
          more: false,
          less: false,
        },
        articles: {
          more: false,
          less: false,
        },
      },
      defaultChecked: [],
      fetchFoldersOptions: {
        per_page: 100,
        only_favourites: 1,
      },
      onlinePromoFetchOptions: {
        include_filters: ['morphable_types'],
        morphable_types: ['OnlinePromo', 'FolderItem', 'Post'],
        per_page: 100,
        only_favourites: 1,
        type_kind: 2,
        order: 'favourite_at'
      },
      postsFetchOptions: {
        include_filters: ['morphable_types'],
        morphable_types: ['Post'],
        per_page: 100,
        only_favourites: 1,
        type_kind_not: 2,
        order: 'favourite_at'
      },
      loadingEmitted: false,
      defaultPage: _.get(this.$route.query, 'page', 1),
    }
  },
  beforeUnmount() {
    this.RESET_FILTERS();
  },
  computed: {
    ...mapGetters('promo', [
      'PROMOS',
      'PROMOS_PROMISE',
      'PROMOS_TOTAL',
      'PROMOS_IS_COMPLETE',
    ]),
    ...mapGetters('filter', [
      'FILTERS_VALUES',
    ]),
    ...mapGetters('folder', [
      'FOLDERS',
      'FOLDERS_PROMISE',
      'FOLDERS_IS_COMPLETE'
    ]),
    ...mapGetters('posts', {
      'POSTS': 'PROMOS',
      'POSTS_PROMISE': 'PROMOS_PROMISE',
      'POSTS_IS_COMPLETE': 'PROMOS_IS_COMPLETE',
    }),
    ...mapGetters('article', [
      'ARTICLES',
      'ARTICLES_PROMISE',
      'ARTICLES_TOTAL',
      'ARTICLES_CURRENT_PAGE',
      'ARTICLES_PER_PAGE',
      'ARTICLES_IS_COMPLETE',
      'MOST_RECENTLY',
      'LAST_YEAR',
      'LAST_WEEK'
    ]),
    folders() {
      return this.FOLDERS.filter(item => item.is_favourite === true)
    },
    promo() {
      return _.concat(this.posts, this.PROMOS.filter(item => item.data.is_favourite === true))
    },
    posts() {
      return this.POSTS.filter(item => item.data.is_favourite === true)
    },
    articles() {
      return this.ARTICLES.filter(item => item.is_favourite === true)
    },
    /**
     * Custom Promo/Folder width in scrollbar
     * @returns {string}
     */
    customWidth() {
      if (this.xsOnly) {
        return '200px'
      } else if (this.lgAndUp) {
        return '32%'
      } else {
        return '31%'
      }
    },
    /**
     * Custom Promo/Folder width in scrollbar
     * @returns {string}
     */
    customWidthArticle() {
      if (this.clientWidth < 360) {
        return '88%'
      } else if (this.xsOnly && this.clientWidth > 360) {
        return '91%'
      } else if (this.lgAndUp) {
        return '47%'
      } else {
        return '47%'
      }
    }
  },
  beforeMount() {
    this.RESET_PROMOS();
    this.RESET_POSTS();
    this.prepareFetchOptions();
    this.loadFolders();
    this.loadPromos();
    this.loadPosts();
    this.loadArticles();
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_ACTIVE',
      'RESET_FILTERS',
      'SET_FILTER_VALUE',
    ]),
    ...mapActions('promo', [
      'FETCH_PROMOS',
      'RESET_PROMOS',
    ]),
    ...mapActions('posts', {
      'FETCH_POSTS': 'FETCH_PROMOS',
      'RESET_POSTS': 'RESET_PROMOS',
    }),
    ...mapActions('folder', [
      'FETCH_FOLDERS',
      'RESET_FOLDERS_FAVORITE',
    ]),
    ...mapActions('article', [
      'FETCH_ARTICLES',
    ]),
    openFull(type) {
      this.fullContent[type] = true
      this.buttons[type].more = false
      this.buttons[type].less = true
    },
    hideFull(type) {
      this.fullContent[type] = false
      this.buttons[type].more = true
      this.buttons[type].less = false
    },
    filter(checked) {
      this.defaultChecked = checked;

      this.SET_FILTER_VALUE({favorite_filter: checked});
    },
    prepareFetchOptions() {
      this.SET_FILTER_ACTIVE('favorite_filter');
    },

    load(state) {
      //Show login dialog modal instead of loading
      if(this.defaultChecked.length === 0){
        if (_.size(this.POSTS)) {
          state.complete();
          return;
        }

        if (_.size(this.PROMOS)) {
          state.complete();
          return;
        }

        if (_.size(this.FOLDERS)) {
          state.complete();
          return;
        }

        if (_.size(this.ARTICLES)) {
          state.complete();
          return;
        }
        state.complete();
        return;
      }
      // folders
      // promos
      // posts
      // articles
      if(this.defaultChecked.includes('folders')){
        if (_.size(this.FOLDERS)) {
          state.complete();
          return;
        }

        this.loadFolders().then(() => {
          if (this.FOLDERS_IS_COMPLETE) {
            state.complete();
          } else {
            state.loaded();
          }
        });
      }

      if(this.defaultChecked.includes('posts')){
        if (_.size(this.POSTS)) {
          state.complete();
          return;
        }

        this.loadPosts().then(() => {
          if (this.POSTS_IS_COMPLETE) {
            state.complete();
          } else {
            state.loaded();
          }
        });
      }

      if(this.defaultChecked.includes('promos')){
        if (_.size(this.PROMOS)) {
          state.complete();
          return;
        }

        this.loadPromos().then(() => {
          if (this.PROMOS_IS_COMPLETE) {
            state.complete();
          } else {
            state.loaded();
          }
        });
      }

      if(this.defaultChecked.includes('articles')){
        if (_.size(this.ARTICLES)) {
          state.complete();
          return;
        }

        this.loadArticles().then(() => {
          if (this.ARTICLES_IS_COMPLETE) {
            state.complete();
          } else {
            state.loaded();
          }
        });
      }
    },
    /**
     * Carousel was rendered
     * @param carousel
     */
    carouselRendered(carousel) {
      this.buttons[carousel.name].more = carousel.maxScrollPx > 5
    },
    loadPromos() {
      return this.FETCH_PROMOS(this.onlinePromoFetchOptions)
    },
    loadPosts() {
      return this.FETCH_POSTS(this.postsFetchOptions).then(() => {
        this.buttons.posts.more = this.posts.length > 1
      });
    },
    loadFolders() {
      return this.FETCH_FOLDERS(this.fetchFoldersOptions);
    },
    loadArticles() {
      return this.FETCH_ARTICLES({page: this.defaultPage}).then(() => {
        this.currentPage = this.ARTICLES_CURRENT_PAGE;
        this.totalArticles = this.ARTICLES_TOTAL;
      });
    },
  },
  watch: {
    FILTERS_VALUES: {
      // the callback will be called immediately after the start of the observation
      deep: true,
      handler(newVal, oldVal) {
        this.defaultChecked = newVal.favorite_filter;

        if(this.defaultChecked.includes('folders')) {
          if (this.FOLDERS_PROMISE) {
            this.FOLDERS_PROMISE.then(() => {
              this.RESET_FOLDERS_FAVORITE();
              this.loadFolders();
            });
          } else {
            this.RESET_FOLDERS_FAVORITE();
            this.loadFolders();
          }
        }
      }
    },
  }
}
</script>
