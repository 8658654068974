<template>
  <div class="scroll-top" :style="scrollTopStyle">
    <div class="icon-scroll-top" @click="goToTop">
      <i class="icon-triangle-arrow"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'scroll-top',
  computed: {
    showScrollTop() {
      //Removing the ScrollTop block when we reach the main-footer
      if (this.currentScrollTop > document.documentElement.offsetHeight - 550) {
        return false;
      }
      return this.currentScrollTop > 300;
    },
    scrollTopStyle(){
      return this.showScrollTop ? {opacity: 1} : {opacity: 0, display: 'none'};
    }
  },
  methods: {
    goToTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
}
</script>
