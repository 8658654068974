export const randomSubscriptions = {
    data() {
        return {
            subscriptionsRandomBonus: 0,
        }
    },
    computed: {
        subscriptionsCount() {
            return _.get(this.$store.getters['store/CURRENT_STORE'], 'subscriptions_count', 0) + this.subscriptionsRandomBonus;
        },
    },
    mounted() {
        this.subscriptionsRandomBonus = this.generateRandomSubscriptionsCount();
    },
    methods: {
        generateRandomSubscriptionsCount() { // need random bonus from 12 to 52
            // 0 to 99
            let num = _.get(this.$store.getters['store/CURRENT_STORE'], 'created_at.timestamp', 0) % 100 + 1;
            // 24 to 123
            num += 24;
            // 12 to 61
            num /= 2;
            // 12 to 52
            num = _.min([~~num, 52]);

            return num;
        }
    }
}
