<template>
  <base-filter ref="filter"
               :filter-label="$t('filters.post')"
               :filter-options="filterOptions"
               :default-checked="[]"
               :short-length="4"
               filter-key="types_filter"
               @filter="filter">
  </base-filter>
</template>

<script>
import BaseFilter from '@/components/left_bar/filters/BaseFilter';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'types-filter',
  components: {BaseFilter},
  props: {
    customClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // filterOptionsVuexModule: 'promo'
    };
  },
  computed: {
    filterOptionsVuexModule() {
      return _.get(this.$store.getters['filter/FILTERS_VUEX_SOURCES'], 'types_filter', 'promo');
    },
    filterOptions() {
      const resultOptions = [];

      _.forEach(_.sortBy(this.$store.getters[this.filterOptionsVuexModule + '/PROMOS_TYPES'], ['additional_order']), function (type) {
        if (type.additional_order > 0) {
          resultOptions.push({
            key: type.id,
            label: type.translation.title,
          });
        }
      });

      return resultOptions;
    }
  },
  mounted() {
    let defaultFilterValue = _.union(_.castArray(this.$route.query['morphable_types_filter[]']), _.castArray(this.$route.query['types_filter[]']));

    defaultFilterValue = _.filter(defaultFilterValue, function (o) {
      return !_.isEmpty(o)
    });

    if (!_.isEmpty(defaultFilterValue)) {
      let newValues = _.difference(_.castArray(defaultFilterValue), ['Post']);

      newValues = _.map(newValues, function (o) {
        const number = parseInt(o);
        if (_.isNaN(number)) {
          return o;
        }
        return number;
      });

      this.$refs.filter.setCheckedList(newValues);
    }
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_VALUE',
    ]),

    filter(checked) {
      const typesValues = _.filter(checked, (need) => {
        return _.findIndex(this.$store.getters[this.filterOptionsVuexModule + '/PROMOS_TYPES'], (value) => {
          return value.id === parseInt(need);
        }) > -1;
      });

      const morphableTypesValues = _.filter(checked, (need) => {
        return _.findIndex(this.$store.getters[this.filterOptionsVuexModule + '/PROMOS_MORPHABLE_TYPES'], (value) => {
          return value.morphable_type === need;
        }) > -1;
      });

      if (typesValues.length) {
        morphableTypesValues.push('Post');
      }

      this.SET_FILTER_VALUE({morphable_types_filter: morphableTypesValues, types_filter: typesValues});
    },
  }
}
</script>
