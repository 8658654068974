<template>
  <el-scrollbar :native="false">
    <div class="blog-filter">
      <el-tag
          class="blog-tag"
          :class="{'cursor-pointer': !activeCategoriesIds.includes(0)}"
          :effect="activeCategoriesIds.includes(0) ? 'dark' : 'plain'"
          :size="tagSize"
      >
        <router-localized-link
            class="hover:text-underline"
            :to="{name: 'news'}"
        >
          <span v-t="'notifications.tabs.all'"/>
        </router-localized-link>
      </el-tag>
      <el-divider direction="vertical"/>

      <template v-for="(category, key) in ARTICLE_CATEGORIES" :key="key">
        <el-divider v-if="key !== 0" direction="vertical"/>
        <el-tag
          class="blog-tag"
          :class="{'cursor-pointer': !activeCategoriesIds.includes(category.id)}"
          :effect="activeCategoriesIds.includes(category.id) ? 'dark' : 'plain'"
          :size="tagSize"
        >
          <router-localized-link
            class="hover:text-underline"
            :to="{name: 'news.category', params: {categorySlug: String(category.translation.slug)}}"
          >
            {{ category.translation.title }}
          </router-localized-link>
        </el-tag>
      </template>
    </div>
    <!--      <div class="pt-2">-->
    <!--            <span-->
    <!--                v-for="subcategory in subCategories"-->
    <!--                :key="subcategory"-->
    <!--                :class="{'active': subcategory.translation.slug === feedSubCategory}"-->
    <!--                class="sub-category px-2"-->
    <!--            >-->
    <!--                <router-localized-link-->
    <!--                    :to="{name: 'news.category', params: {['categorySlug']: String(subcategory.translation.slug)}}">-->
    <!--                    {{ subcategory.translation.title }}-->
<!--                </router-localized-link>-->
<!--            </span>-->
<!--      </div>-->
    </el-scrollbar>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';

export default {
  name: 'CategoriesList',
  components: {RouterLocalizedLink},
  computed: {
    ...mapGetters('article_category', [
      'ARTICLE_CATEGORIES',
      'CURRENT_ARTICLE_CATEGORY'
    ]),

    activeCategoriesIds(){
      return [_.get(this.CURRENT_ARTICLE_CATEGORY, 'id', 0), _.get(this.CURRENT_ARTICLE_CATEGORY, 'parent_id', 0)];
    }
  },
  beforeMount() {
    this.FETCH_ARTICLE_CATEGORIES();
  },
  methods: {
    ...mapActions('article_category', [
      'FETCH_ARTICLE_CATEGORIES',
    ]),
  }
}
</script>
