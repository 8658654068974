<template>
  <div class="folder-pager-wrapper">
    <div class="mx-1 mx-md-5">
      <div class="folder-pager" ref="page_container">
        <Carousel :mouse-drag="false" ref="folder_carousel">
          <Slide v-for="(pagesChunk, index) in pagesSlides" :key="index">
            <div class="carousel__item centered h-100">
              <div
                v-for="(page, pageIndex) in pagesChunk"
                :key="pageIndex"
                class="h-100 centered"
              >
                <template v-if="page.isAdd">
<!--                  <div class="folder-pager-googleads-container mx-lg-2" :style="stylesForRecommendationsBlock">-->
<!--                    <Adsense-->
<!--                      :ins-style="stylesForAdsense"-->
<!--                      data-ad-client="ca-pub-9787170221354582"-->
<!--                        data-ad-slot="7368930655">-->
<!--                    </Adsense>-->
<!--                  </div>-->
                </template>
                <template v-else-if="page.freeClientRecommendations">
                  <div class="h-100" :style="stylesForRecommendationsBlock">
                    <free-client-recommendations></free-client-recommendations>
                  </div>
                </template>
                <template v-else-if="page.semiPaidClientRecommendations">
                  <div class="h-100" :style="stylesForRecommendationsBlock">
                    <semi-paid-client-recommendations></semi-paid-client-recommendations>
                  </div>
                </template>
                <template v-else>
                  <folder-page-zoomer
                      :show-map="!CURRENT_STORE_IS_FREE_CLIENT"
                      :carousel-index="carouselIndex"
                      :image="page.image_url"
                      :items="page.items"
                      :animations="page.animations"></folder-page-zoomer>
                </template>
              </div>
            </div>
          </Slide>

          <template #addons>
            <Navigation/>
          </template>
        </Carousel>
      </div>

      <div class="folder-pager-slider">
        <el-slider v-model="sliderIndex"
                   :show-tooltip="false"
                   :step="1"
                   :min="0"
                   :max="pagesSlides.length - 1"
                   :marks="marks"
                   @change="changePage"
                   show-stops/>
      </div>

      <div class="my-3" v-if="PROMOS.length">
        <folder-promos-carousel :title="$t('folder.promos_from_this_page')"
                                :promos="PROMOS"
                                @load="loadPromos"
                                child-class="bg-secondary"></folder-promos-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import {Carousel, Slide, Navigation} from 'vue3-carousel';
import {mapActions, mapGetters} from 'vuex';
import FolderPageZoomer from '@/components/feed/folder/FolderPageZoomer';
import FolderPromosCarousel from '@/components/feed/carousels/FolderPromosCarousel.vue';
import FreeClientRecommendations from '@/components/feed/folder/Slides/FreeClientRecommendations';
import SemiPaidClientRecommendations from '@/components/feed/folder/Slides/SemiPaidClientRecommendations';

export default {
  name: 'folder-pager',
  components: {
    SemiPaidClientRecommendations,
    FreeClientRecommendations,
    FolderPromosCarousel,
    FolderPageZoomer,
    Carousel,
    Slide,
    Navigation
  },
  data() {
    return {
      sliderIndex: 0,
      marks: {},
      carouselIndex: 0,
      viewedPages: []
    };
  },
  computed: {
    ...mapGetters('store', [
      'CURRENT_STORE',
      'CURRENT_STORE_IS_PAID_CLIENT',
      'CURRENT_STORE_IS_FREE_CLIENT',
    ]),
    ...mapGetters('folder', [
      'CURRENT_FOLDER',
    ]),
    ...mapGetters('promo', [
      'PROMOS',
    ]),
    allPages() {
      return this.CURRENT_FOLDER.pages;
    },
    singlePageSlides() {
      const pages = _.sortBy(this.allPages, 'page');
      const result = _.chunk(pages, 1);

      if (this.CURRENT_STORE_IS_FREE_CLIENT) {
        // result.push([{isAdd: true}]);
        result.push([{freeClientRecommendations: true}]);
      } else {
        result.push([{semiPaidClientRecommendations: true}]);
      }

      return result;
    },
    pagesSlides() {
      return this.mdAndUp ? this.duoPageSlides : this.singlePageSlides;
    },
    duoPageSlides() {
      const pages = _.sortBy(this.allPages, 'page');

      let result = _.union([_.take(pages, 1)], _.chunk(_.slice(pages, 1), 2));
      if (2 === this.CURRENT_STORE.folderview_pages_pattern) {
        result = _.chunk(pages, 2);
      }

      if (this.CURRENT_STORE_IS_FREE_CLIENT) {
        // result.push([{isAdd: true}, {freeClientRecommendations: true}]);
        result.push([{freeClientRecommendations: true}]);
      } else {
        result.push([{semiPaidClientRecommendations: true}]);
      }

      return result;
    },
    stylesForRecommendationsBlock() {
      return {
        'max-width': '95vw',
        'width': '450px',
        'min-width': '280px',
      }
    },
    stylesForAdsense() {
      return 'display:inline-block;max-width:90vw;width:400px;min-width:280px;min-height:80vh';
    }
  },
  mounted() {
    this.$watch(() => {
      if (this.$refs.folder_carousel) {
        return this.$refs.folder_carousel.data.currentSlide.value;
      }
      return 0;
    }, (val) => {
      this.carouselIndex = val;
    });
    this.prepareMarks();
    this.resolveStartingSlide();
  },
  methods: {
    ...mapActions('promo', [
      'FETCH_PROMOS',
      'RESET_PROMOS',
    ]),

    resolveStartingSlide() {
      let startingIndex = 0;
      const startingPage = _.get(this.$router.currentRoute.value.query, 'page');
      if (!_.isEmpty(startingPage)) {
        startingIndex = _.findIndex(this.pagesSlides, function (need) {
          return _.size(_.filter(need, (o) => o.page == startingPage)) > 0;
        });
      }
      if (startingIndex > 0) {
        this.changePage(startingIndex);
      } else {
        this.loadPromos();
      }
    },
    loadPromos() {
      const pageIds = _.map(this.pagesSlides[this.carouselIndex], 'id');
      this.FETCH_PROMOS({
        morphable_type: 'FolderItem',
        folder_id: this.CURRENT_FOLDER.id,
        folder_page_ids: pageIds,
        per_page: 20,
        include_filters: []
      });
    },

    changePage(value) {
      this.$refs.folder_carousel.slideTo(value);
    },

    prepareMarks() {
      const thisElem = this;
      this.marks = {};

      let chunk = Math.floor(this.pagesSlides.length / (this.mdAndUp ? 10 : 5));
      chunk = _.max([chunk, 1]);
      _.forEach(this.pagesSlides, function (slide, key) {
        if ((key + 1) % chunk === 0) {
          thisElem.marks[key] = {
            label: _.join(_.map(slide, 'page'), '-'),
            style: {
              'white-space': 'nowrap'
            }
          };
        }
      });
    }
  },
  watch: {
    carouselIndex(newIndex) {
      const currentPage = _.get(_.first(_.get(this.pagesSlides, newIndex)), 'page');
      const query = _.clone(this.$router.currentRoute.value.query);
      query['page'] = currentPage;
      this.$router.replace({query: query});

      this.sliderIndex = newIndex;
      this.RESET_PROMOS();
      this.loadPromos();
    }
  }
}
</script>