<template>
  <div class="feed-container style-container">
    <div class="d-flex justify-content-between align-items-center instruction-header">
      <div class="title style-title" v-html="pageObject.title"></div>
      <div class="on-promocoins" v-if="pageObject.slug === 'bonus-points'">
        <router-localized-link class="text-dark" v-t="'prizes.on_promocoins'" :to="{name : 'prizes-pool'}"></router-localized-link>
      </div>
    </div>
    <div v-html="pageObject.content"></div>
  </div>
</template>

<script>
import {useHead} from '@vueuse/head';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';

export default {
  name: 'page',
  components: {RouterLocalizedLink},
  props: ['pageObject'],

  created() {
    const title = this.pageObject.tag_title;
    const description = this.pageObject.meta_description;
    useHead({
      title: title,
      meta: [
        {
          name: 'title',
          content: title,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:description',
          content: description,
        },
      ],
    })
  },
}
</script>
