<template>
  <div class="promos-section">
    <div class="d-flex justify-content-between">
      <div class="specific-border-line"/>
      <div class="specific-border-line"/>
    </div>

    <default-space>
      <div class="promos-section__container">
        <promos-carousel
          :promos="PROMOS"
          :is-loading="!!PROMOS_PROMISE"
          :custom-width="customWidth"
          :vuex-modules="{
            'post': 'promos-expires-soon',
            'online_promo': 'promos-expires-soon',
            'folder_promo': 'promos-expires-soon',
          }"
          show-load-more
          @more="$router.push({name: 'promos', query: { tab: 'expires-soon' }})"
        >
          <template #header>
            <h2 class="promos-carousel-header__title" v-html="$t('landing.final_chance.title')"/>
            <router-localized-link
              :to="{name: 'promos'}"
              :params="{tab: 'expires-soon'}"
              class="promos-section__link"
            >
              <span v-t="'landing.final_chance.subtitle'"/>
              <i class="icon-triangle-arrow ms-2"/>
            </router-localized-link>
          </template>
        </promos-carousel>
      </div>
    </default-space>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import DefaultSpace from '@/views/DefaultSpace.vue';
import PromosCarousel from '@/components/feed/carousels/PromosCarousel.vue';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink.vue';

export default {
  name: 'FinalChanceSection',
  components: {RouterLocalizedLink, PromosCarousel, DefaultSpace},
  computed: {
    ...mapGetters('promos-expires-soon', [
      'PROMOS',
      'PROMOS_PROMISE',
      'PROMOS_TOTAL',
      'PROMOS_IS_COMPLETE',
    ]),
    customWidth() {
      if (this.xsOnly) {
        return '200px'
      } else if (this.lgAndUp) {
        return '25%'
      } else {
        return '33.3%'
      }
    }
  },
  data() {
    return {
      fetchOptions: {
        per_page: 15,
        folders_included: 0,
        only_valid_promos: 1,
        only_expires_soon: 1,
        order: 'rating_count'
      },
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    ...mapActions('promos-expires-soon', [
      'FETCH_PROMOS',
    ]),
    load() {
      return this.FETCH_PROMOS(this.fetchOptions);
    }
  }
}
</script>
