<template>
  <el-form
    ref="form"
    :model="form"
    class="post-step__container type-step"
  >
    <el-input
      class="post-step__title"
      :placeholder="`${titleNumber}. ${$t('post.steps.type_post')}`"
      disabled
    />

    <div class="type-step__types">
      <!--   Type   -->
      <el-form-item prop="typeId" :rules="rules.typeId">
        <div
          class="d-flex flex-wrap w-100 pb-1"
          :class="{
            'justify-content-center': xsOnly,
            'justify-content-md-between': LOCALE === 'fr',
            'justify-content-md-evenly': LOCALE === 'nl'
          }"
        >
          <el-tag
            v-for="type in types"
            class="type-tag"
            :key="type.id"
            :class="{'cursor-pointer hover:bg-secondary--dark': type.id !== formState.type.id}"
            :effect="type.id === formState.type.id ? 'dark' : 'plain'"
            :size="tagSize"
            @click="typeChanged(type)"
          >
            {{ type.translation.title }}
          </el-tag>
        </div>
      </el-form-item>

      <!--   Subtype   -->
      <div v-if="type.children && type.children.length" class="type-select">
        <el-input
          class="post-step__title mb-3.5"
          :placeholder="`${titleNumber + 1}. ${$t('post.steps.type_promo')}`"
          disabled
        />

        <el-form-item prop="subtypeId" :rules="rules.subtypeId">
          <el-select
            v-model="form.subtypeId"
            :size="tagSize"
            :placeholder="$t('post.select_subtype')"
            @change="subTypeChanged"
          >
            <el-option
              v-for="subtype in type.children"
              :key="subtype.id"
              :label="subtype.translation.title"
              :value="subtype.id"
            />
          </el-select>
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {step} from '@/mixins/post/step';

export default {
  name: 'TypeStep',
  mixins: [step],
  computed: {
    ...mapGetters('auth', [
      'LOCALE',
    ]),
    ...mapGetters('type', [
      'TYPES',
    ]),
    ...mapState('promo', {
      formState: ({form}) => form,
      type: ({form}) => form.type,
      subtype: ({form}) => form.subtype,
    }),
    /**
     * Sorted types
     * @returns {*}
     */
    types() {
      const types = this.TYPES;

      return types.sort((a, b) => a.order - b.order)
    },
    /**
     * Titles count
     * @returns {number}
     */
    titlesCount() {
      return this.type.id === this.$consts.POST_TYPES.REPORT ? 2 : 1
    }
  },
  data() {
    return {
      form: {
        typeId: '',
        subtypeId: '',
      },
      rules: {
        typeId: [
          {required: true, message: this.$t('post.errors.type')}
        ],
        subtypeId: [
          {required: true, message: this.$t('post.errors.subtype')}
        ],
      }
    }
  },
  /**
   * Mounted
   */
  mounted() {
    if (this.type.id) {
      this.form.typeId = this.type.id
    }

    if (this.subtype.id) {
      this.form.subtypeId = this.subtype.id
    }
  },
  methods: {
    /**
     * Type change event
     * @param type
     */
    typeChanged(type) {
      this.form.typeId = type.id
      this.updateForm({type})

      if (this.type.children.length) {
        this.updateForm({subtype: {}})
        this.form.subtypeId = ''
      } else {
        this.updateForm({subtype: type})
      }
    },
    /**
     * Subtype change event
     * @param subtypeId
     */
    subTypeChanged(subtypeId) {
      const subtype = this.type.children.find(need => need.id === subtypeId);

      this.updateForm({subtype})
    },
    /**
     * Validation
     * @returns {boolean}
     */
    async validate() {
      let result = false;

      await this.$refs.form.validate((isValid) => result = isValid)

      this.$emit('update', {titlesCount: this.titlesCount})

      return result
    }
  },
}
</script>
