<template>
  <el-dropdown
    :placement="'bottom-end'"
    class="locale-dropdown"
    popper-class="locale-dropdown__popper"
    trigger="click"
  >
    <slot>
      <button class="locale-dropdown__btn" :class="btnClass">
        <i class="font-size-20 me-0.5" :class="{'icon-Dutch': LOCALE === 'nl', 'icon-French': LOCALE === 'fr'}">
          <span class="path1"/>
          <span class="path2"/>
          <span class="path3"/>
        </i>
        <i class="icon-triangle-arrow font-size-7"/>
      </button>
    </slot>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item @click="changeLocale('nl')" :class="{'active': LOCALE === 'nl'}">
          <i class="icon-Dutch">
            <span class="path1"/>
            <span class="path2"/>
            <span class="path3"/>
          </i>
          {{ $t('header.locale.nl') }}
        </el-dropdown-item>
        <el-dropdown-item @click="changeLocale('fr')" :class="{'active': LOCALE === 'fr'}">
          <i class="icon-French">
            <span class="path1"/>
            <span class="path2"/>
            <span class="path3"/>
          </i>
          {{ $t('header.locale.fr') }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'LocaleDropdown',
  props: {
    btnClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('auth', [
      'LOCALE',
      'GUEST',
    ]),
    ...mapGetters('page', [
      'PAGES',
      'PAGES_LOADED',
    ]),
  },
  methods: {
    ...mapActions('auth', [
      'UPDATE_LOCALE',
    ]),
    ...mapActions('page', [
      'FETCH_PAGES',
    ]),
    changeLocale(newLocale) {
      if (this.GUEST) {
        window.open(this.resolveRouteForLocale(this.$route, newLocale), '_self');
        return;
      }
      this.UPDATE_LOCALE(newLocale).then(() => {
        window.open(this.resolveRouteForLocale(this.$route, newLocale), '_self');
      });
    },
    resolveRouteForLocale(currentRoute, newLocale) {
      if (currentRoute.name === 'not_found') {
        const path = this.resolvePageForLocale(currentRoute, newLocale);
        if (typeof path === 'string' || path instanceof String) {
          return this.$localizePath(path, newLocale).replace('/nl', '');
        }
      }
      return this.$localizePath(this.$router.resolve({
        name: currentRoute.name,
        params: currentRoute.params
      }).href, newLocale).replace('/nl', '');
    },
    resolvePageForLocale(currentRoute, newLocale) {
      const route = _.get(currentRoute, 'params.pathMatch[0]', false);
      if (!route) {
        return false;
      }

      const oldPage = _.find(this.PAGES, {'route': route}, false);
      if (!oldPage) {
        return false;
      }

      const newPage = _.find(this.PAGES, function (need) {
        return need.slug === oldPage.slug && need.locale.slug === newLocale;
      }, false);
      if (!newPage) {
        return false;
      }

      const params = currentRoute.params;
      _.set(params, 'pathMatch[0]', newPage.route);
      return this.$router.resolve({name: currentRoute.name, params: params}).href;
    }
  },
}
</script>
