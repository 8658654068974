<template>
  <default-grid :show-left-container="false" :show-right-container="false">
    <template v-slot:main-container>
      <div class="referral-container">
        <div class="main-header">
          <span class="color-text-primary" v-t="'menu.main.invite_friend'"/>
          <span class="text-danger color-primary ms-1 font-size-14">
            {{ referralRewardText }}
          </span>
        </div>

        <div class="page-content" v-html="pageContent"></div>

        <div class="d-flex justify-content-center align-items-center">
          <img src="@/assets/images/community.svg" class="images-community" alt="">
        </div>

        <div class="my-3 color-text-primary">
          <h3 class="text-center mb-2">
            <span v-t="'prizes.referral_page.link_label'" class="px-2 text-decoration-underline"></span>
          </h3>

          <el-row type="flex" justify="center">
            <el-col :span="24">
              <div class="p-4 text-center background-light-yellow" @click="doCopyReferralLink">
                <el-col :class="{'pb-3': !xsOnly}" :span="24" :xl="16">
                  <span class="referral-link text-center">{{ referralLink }}</span>
                </el-col>
                <el-col :span="24" :xl="8" class="mt-2 mt-xxl-0">
                  <span class="cursor-pointer text-center">
                    <span v-t="'prizes.referral_page.click_to_copy'"></span>
                    <i class="ps-2 icon-copy font-weight-bold font-size-18" v-if="!copied"></i>
                    <i class="ps-2 icon-checkmark1 font-weight-bold font-size-18" v-else></i>
                  </span>
                </el-col>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

    </template>
  </default-grid>
</template>

<script>
import {mapGetters} from 'vuex';
import DefaultGrid from '@/views/DefaultGrid';

export default {
  name: 'ReferralView',
  components: {DefaultGrid},
  data() {
    return {
      copied: false
    }
  },
  computed: {
    ...mapGetters('page', [
      'PAGES_LOADED',
      'PAGES'
    ]),
    ...mapGetters('auth', [
      'USER',
    ]),
    ...mapGetters('bonus_action', [
      'BONUS_ACTIONS',
      'BONUS_ACTION_TYPES',
    ]),

    pageContent() {
      const thisElem = this;
      const pages = _.filter(this.PAGES, function (need) {
        return _.get(need, 'locale.slug') === thisElem.$i18n.locale && _.get(need, 'slug') === 'referral-page';
      });

      return _.get(pages, '[0]content', '');
    },

    referralLink() {
      const link = this.$router.resolve({name: 'home', query: {doemee: this.USER.id}});
      return new URL(link.href, window.location.origin).href;
    },

    referralRewardText() {
      const action = this.BONUS_ACTIONS.filter(need => need.type === this.BONUS_ACTION_TYPES['referrals-first-post-approved'])[0];
      if (!action) {
        return '';
      }
      return '( +' + action.impact_on_balance + ' ' + this.$t('prizes.points') + ' )';
    },
  },
  methods: {
    doCopyReferralLink() {
      this.$copyText(this.referralLink, undefined, (error, event) => {
        this.copied = true;
        setTimeout(() => {
          this.copied = false
        }, 2000);
      });
    }
  }
}
</script>
