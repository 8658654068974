import axios from 'axios';
import store from '@/store/index';

// Full config:  https://github.com/axios/axios#request-config
window.axios = axios.create();
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL || '';
window.axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers['Content-Type'] = 'application/json';
window.axios.defaults.headers['Domain'] = process.env.VUE_APP_DOMAIN;
window.axios.defaults.headers.Accept = 'application/json';

window.axios.defaults.timeout = 60 * 1000;
window.axios.defaults.withCredentials = true;

window.axios.interceptors.request.use(
    request => {
        switch (request.method) {
            case 'post':
                // request.headers['Content-Type'] = 'application/x-www-form-urlencoded';
                break
        }

        if (store.getters['auth/AUTH_TOKEN']) {
            request.headers.Authorization = `Bearer ${store.getters['auth/AUTH_TOKEN']}`;
        }

        if (store.getters['auth/LOCALE']) {
            request.headers.Language = store.getters['auth/LOCALE'];
        }

        return request;
    },
    error => {
        return Promise.reject(error)
    }
)

// Add a response interceptor
window.axios.interceptors.response.use(
    response => {
        // Do something with response data
        return response;
    },
    error => {
        return Promise.reject(error);
    }
)

export default window.axios
