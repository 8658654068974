const getDefaultState = () => {
    return {
        products: {
            all: [],
            promise: null,
        },
        preview_count: 13,
        currentProduct: null
    }
};

export default {
    namespaced: true,
    state: () => (getDefaultState()),

    getters: {
        PRODUCTS(state) {
            return state.products.all;
        },
        PRODUCTS_PROMISE(state) {
            return state.products.promise;
        },
        PRODUCTS_PREVIEW_COUNT(state) {
            return state.preview_count;
        },
        CURRENT_PRODUCT(state) {
            return state.currentProduct;
        }
    },

    actions: {
        FETCH_PRODUCTS({state, commit, dispatch}, filter) {
            let params = filter;

            // Clean empty values
            params = _.omitBy(params, (i) => {
                return _.isNumber(i) ? i === 0 : _.isEmpty(i);
            });

            return axios.get('/products', {params}).then((response) => {
                commit('SET_PRODUCTS', response.data.data);
            });
        },
        async FETCH_CURRENT_PRODUCT({state, commit, dispatch}, route) {
            return axios.get('/product/' + route).then((response) => {
                commit('UPDATE_CURRENT_PRODUCT', response.data.data);
            });
        },

        RESET_PRODUCTS({commit}) {
            commit('SET_DEFAULT_DATA');
        },
        SET_SUBSCRIPTION({state, commit}, {id, ...data}) {
            return axios.put(`/products/${id}/subscribe`, data).then((response) => {
                commit('UPDATE_CURRENT_PRODUCT', response.data.data);
            });
        },
        REMOVE_SUBSCRIPTION({state, commit}, id) {
            return axios.put(`/products/${id}/unsubscribe`).then((response) => {
                commit('UPDATE_CURRENT_PRODUCT', response.data.data);
            });
        },
    },

    mutations: {
        SET_PRODUCTS(state, data) {
            _.forEach(data, function (product) {
                if (!_.find(state.products.all, {id: product.id})) {
                    state.products.all = _.union(state.products.all, [product]);
                }
            });
        },

        UPDATE_CURRENT_PRODUCT(state, data) {
            return state.currentProduct = data;
        },

        SET_DEFAULT_DATA(state) {
            return _.assign(state, getDefaultState());
        }
    }
}
