<template>
  <div class="promos-carousel-container w-100" :class="childClass">
    <!--
      Scroll header
    -->
    <div class="folder-promos-carousel-header" ref="carousel_header">
      <h3 class="folder-promos-carousel-header-title" v-text="title"/>

      <div class="nav-buttons float-end color-primary">
        <i v-if="currentScrollPx > 0"
           class="icon-arrow-4 float-start cursor-pointer icon-flipped"
           @click="scrollPrev"
        />
        <i v-if="currentScrollPx < maxScrollPx + promoWidth"
           class="icon-arrow-4 float-end cursor-pointer"
           @click="scrollNext"
        />
      </div>
    </div>

    <!--
      Scrollbar
    -->
    <el-scrollbar class="w-100" :native="false" ref="scrollbar_ref" :min-size="40" @scroll="scrolling">
      <div class="scrollbar-flex-content">
        <div v-for="(promo, i) in promos" :key="i" class="scrollbar-item">
          <div :style="previewItemContainerStyle">
            <folder-promo-mini :folder-promo="promo.data"/>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import FolderPromoMini from '@/components/feed/promo/FolderPromoMini.vue';

export default {
  name: 'folder-promos-carousel',
  components: {FolderPromoMini},
  props: {
    title: String,
    promos: Array,
    childClass: String,
  },
  data() {
    return {
      promoWidth: 230,
      containerWidth: 0,
      currentScroll: 0,
      currentScrollPx: 0,
      loadingEmitted: false,
    }
  },
  computed: {
    maxScrollPx() {
      return this.promos.length * this.promoWidth - this.containerWidth;
    },
    previewItemContainerStyle() {
      return {
        'width': this.promoWidth + 'px'
      }
    }
  },
  mounted() {
    new ResizeObserver(() => this.calculateContainerWidth()).observe(this.$refs.carousel_header);
  },
  methods: {
    scrolling(value) {
      this.currentScrollPx = value.scrollLeft;
    },
    calculateContainerWidth() {
      if (this.$refs.carousel_header) {
        this.containerWidth = this.$refs.carousel_header.clientWidth;
      }
    },
    scrollNext() {
      this.currentScroll++;
    },
    scrollPrev() {
      if (this.currentScroll > 0) {
        this.currentScroll--;
      }
    },
  },
  watch: {
    currentScroll(value) {
      this.currentScrollPx = this.promoWidth * value;
      this.$refs.scrollbar_ref.setScrollLeft(this.promoWidth * value);
    },
    currentScrollPx(value) {
      if (value > this.maxScrollPx && !this.loadingEmitted) {
        this.$emit('load');
        this.loadingEmitted = true;
        setTimeout(() => {
          this.loadingEmitted = false;
        }, 3000);
      }
    }
  }
}
</script>
