<template>
  <input-form
    ref="form"
    class="cta-form"
    :image-action="imageAction"
    actions-position="right"
  >
    <template v-slot:textarea>
      <el-input
        class="rounded-textarea input-textarea--large"
        type="textarea"
        :placeholder="$t('post.cta_placeholder', {name: USER.fname})"
        :autosize="{minRows: 1, maxRows: 5}"
        @click="$emit('click')"
      />
    </template>
  </input-form>
</template>

<script>
import InputForm from '@/components/feed/InputForm.vue';
import {mapGetters} from 'vuex';

export default {
  name: 'CallToAction',
  components: {InputForm},
  computed: {
    ...mapGetters('auth', [
      'USER',
    ]),
    imageAction() {
      return process.env.VUE_APP_API_URL + 'posts/upload-image'
    }
  },
}
</script>
