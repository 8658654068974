export default {
    namespaced: true,
    state: {
        ads: [],
    },

    getters: {
        ADS(state) {
            return state.ads;
        }
    },

    actions: {
        async FETCH_ADS({state, commit}, params = '') {
            return await axios.get('/ads?' + params).then((response) => {
                commit('SET_ADS', response.data.data);
            });
        },
    },

    mutations: {
        SET_ADS(state, data) {
            return (state.ads = data);
        },
    }
}
