<template>
  <div class="folders-feed">
    <h1 class="folders-feed-header-title">
      {{ $t('folder.folders_store_header', {store: CURRENT_STORE.translation.title}) }}
    </h1>

    <div class="d-none d-md-block mx-2.5" v-html="CURRENT_STORE.translation.content_for_folders"></div>

    <div class="me-3">
      <div v-if="FOLDERS.length">
        <folders-carousel :title="$t('folder.folders_store_title',{store:CURRENT_STORE.translation.title})"
                          :folders="FOLDERS"
                          @load="loadMoreFolders"/>
      </div>
      <div class="my-3" v-if="EXPIRED_FOLDERS.length">
        <folders-carousel :title="$t('folder.folders_store_expired_title',{store:CURRENT_STORE.translation.title})"
                          :folders="EXPIRED_FOLDERS"
                          @load="loadMoreExpiredFolders"
                          child-class="bg-secondary"/>
      </div>
      <div class="my-3" v-if="PROMOS.length">
        <folder-promos-carousel :title="$t('folder.top_folder_promos')"
                          :promos="PROMOS"
                          @load="loadMorePromos"
                          child-class="bg-secondary"/>
      </div>
    </div>

    <div class="my-3" v-if="!mdAndUp">
      <store-mailer :dividers="false"></store-mailer>
    </div>

    <div class="my-3" v-if="CURRENT_STORE.products.length">
      <store-offers :offers="CURRENT_STORE.products" :storeTitle="CURRENT_STORE.translation.title"></store-offers>
    </div>

    <h2 class="color-text-primary font-weight-medium font-size-20 mt-5 mb-3" v-t="'store.seo_folders.header'"></h2>

    <div v-for="(faqItem, key) in faqData" :key="key">
      <el-divider class="my-3"></el-divider>

      <div class="font-size-20">
        <i class="icon-minus"></i>&nbsp; <span v-text="faqItem.question"></span>
      </div>

      <div class="font-size-14 m-3" v-html="faqItem.answer"></div>
    </div>

    <div class="my-5" v-html="CURRENT_STORE.translation.additional_content_for_folders"></div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import FoldersCarousel from '@/components/feed/carousels/FoldersCarousel.vue';
import FolderPromosCarousel from '@/components/feed/carousels/FolderPromosCarousel.vue';
import StoreOffers from '@/components/feed/StoreOffers';

export default {
  name: 'folders-store-feed', components: { FolderPromosCarousel, FoldersCarousel, StoreOffers},
  props: ['faqData'],
  data() {
    return {
      //
    }
  },
  computed: {
    ...mapGetters('folder', [
      'FOLDERS',
    ]),
    ...mapGetters('folder-expired', {
      'EXPIRED_FOLDERS': 'FOLDERS'
    }),
    ...mapGetters('promo', [
      'PROMOS',
    ]),
    ...mapGetters('store', [
      'CURRENT_STORE',
    ]),
  },
  mounted() {
    this.loadFirstData();
  },
  methods: {
    async loadFirstData(){
      await this.loadMoreFolders();
      await this.loadMoreExpiredFolders();
      await this.loadMorePromos();
    },
    loadMorePromos() {
      this.$store.dispatch('promo/FETCH_PROMOS', {morphable_type: 'FolderItem', stores: [this.CURRENT_STORE.id], include_filters: []});
    },
    loadMoreFolders() {
      this.$store.dispatch('folder/FETCH_FOLDERS', {stores: [this.CURRENT_STORE.id]});
    },
    loadMoreExpiredFolders() {
      this.$store.dispatch('folder-expired/FETCH_FOLDERS', {stores: [this.CURRENT_STORE.id], show_expired: 1});
    }
  }
}
</script>
