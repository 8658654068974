<template>
  <el-dialog
    :title="$t('menu.verify_email.header')"
    v-model="visible"
    custom-class="verify-email-dialog"
    close-on-click-modal
    close-on-press-escape
    destroy-on-close
    lock-scroll
    show-close
    center
    @close="$dialogs.hide('verify-email')"
  >
    <p class="verify-email__text">{{ $t('menu.verify_email.line1') }}</p>
    <p class="verify-email__text mb-4.5">{{ $t('menu.verify_email.line2') }}</p>
    <p class="verify-email__text text-decoration-underline font-weight-600">{{ $t('menu.verify_email.line3') }}</p>
    <el-button
      class="verify-email__btn"
      :disabled="isFreezed"
      color="#E0050E"
      @click="resendEmailConfirmation"
    >
      {{ isFreezed ? `${freezeTime}` : $t('menu.verify_email.btn') }}
    </el-button>
    <Transition>
      <p v-if="success" class="verify-email__text color-green">{{ $t('menu.verify_email.success') }}</p>
    </Transition>
    <p v-if="error" class="verify-email__text color-primary">{{ $t('menu.verify_email.error') }}</p>
    <p class="verify-email__text--mini px-5">{{ $t('menu.verify_email.line4') }}</p>
  </el-dialog>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'verify-email-dialog',
  computed: {
    ...mapState('dialogs', {
      visible: ({dialogs}) => dialogs['verify-email'].visible,
    }),
    ...mapState('auth', {form: ({form}) => form}),
    /**
     * Is button freezed
     * @returns {boolean}
     */
    isFreezed() {
      return this.freezeTime < this.defaultFreezeTime && this.freezeTime > 0
    }
  },
  data() {
    return {
      success: false,
      error: false,
      freezeTime: 60,
      defaultFreezeTime: 60,
    }
  },
  methods: {
    /**
     * Check is verified
     */
    checkIsVerified() {
      this.$http.post('/is-verified', this.form)
        .then((response) => {
          if (!this.$dialogs.isVisible('verify-email')) {
            return
          }

          if (response.data.is_verified) {
            this.$dialogs.hide('verify-email')
          } else {
            setTimeout(() => this.checkIsVerified(), 3000)
          }
        })
    },
    /**
     * Start freeze countdown
     */
    startCountdown() {
      if (this.error) {
        this.freezeTime = this.defaultFreezeTime
        return
      }

      if (this.freezeTime !== 1) {
        this.freezeTime -= 1
        setTimeout(() => this.startCountdown(), 1000)
      } else {
        this.freezeTime = this.defaultFreezeTime
      }
    },
    /**
     * Resend email verification
     */
    resendEmailConfirmation() {
      this.startCountdown()

      this.$http.post('/resend-verification', this.form)
        .then((response) => {
          this.success = true;
          this.error = false;
        })
        .catch((e) => {
          this.success = false;
          this.error = true;
        })
    }
  },
  watch: {
    visible(val) {
      if (val) {
        setTimeout(() => this.checkIsVerified(), 5000)
      }
    }
  }
}
</script>
