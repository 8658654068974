<template>
  <post-dialog/>
  <auth-dialog/>
  <main-menu/>
  <verify-email-dialog/>
</template>

<script>
import PostDialog from '@/components/feed/post/creation/PostDialog.vue';
import AuthDialog from '@/components/header/AuthDialog.vue';
import {mapGetters} from 'vuex';
import MainMenu from '@/components/header/MainMenu.vue';
import VerifyEmailDialog from '@/components/header/auth/VerifyEmailDialog.vue';

export default {
  name: 'Dialogs',
  components: {VerifyEmailDialog, MainMenu, AuthDialog, PostDialog},
  computed: {
    ...mapGetters('auth', ['GUEST']),
  },
  methods: {
    openDialogIfMobileGuest() {
      if (this.webview && this.GUEST && !cookies.get('visited')) {
        this.$dialogs.show('auth', 'default')

        cookies.set('visited', true, '1y');
      }
    },
  },
  watch: {
    $route(to, from) {
      if (from.name !== undefined) {
        this.$dialogs.closeAll()
      }
      this.openDialogIfMobileGuest();
    },
  }
}
</script>
