<template>
  <base-filter ref="filter"
               :filter-label="$t('filters.brands')"
               :filter-options="filterOptions"
               :default-checked="defaultFilterValue"
               filter-key="brands_filter"
               @filter="filter">
  </base-filter>
</template>

<script>
import BaseFilter from '@/components/left_bar/filters/BaseFilter';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'brands-filter',
  components: {BaseFilter},
  props: {
    brands: {
      type: Array,
      default() {
        return []
      }
    },
  },
  computed: {
    ...mapGetters('promo', [
      'PROMOS_BRANDS',
    ]),

    filterOptions() {
      const resultOptions = {};
      const brands = this.PROMOS_BRANDS.length ? this.PROMOS_BRANDS : this.brands;

      _.forEach(brands, function (brand) {
        resultOptions[brand.id] = brand.translation.title;
      });
      return resultOptions;
    },
    brandsFilter() {
      return _.get(this.$router.currentRoute.value.query, 'brands_filter[]', [])
    },
    defaultFilterValue() {
      return _.castArray(this.brandsFilter)
    }
  },
  mounted() {
    if (!_.isEmpty(this.brandsFilter)) {
      this.$refs.filter.setCheckedList(this.defaultFilterValue);
    }
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_VALUE',
    ]),

    filter(checked) {
      this.SET_FILTER_VALUE({brands_filter: checked});
    }
  }
}
</script>
