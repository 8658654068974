<template>
  <div class="store-mailer m-2" v-if="currentItem">
    <el-divider class="mb-4" v-if="dividers"></el-divider>
    <el-dropdown placement="bottom-end" class="w-100" trigger="click" v-if="!GUEST && currentItem && currentItem.subscription && !isEdit">
      <el-button type="primary" plain class="notification-subscribe-btn">
        <span>{{$t('subscription.followed')}}</span>&nbsp;
        <i class="icon-bell"/>
      </el-button>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="onUnsubscribe">
            {{$t('subscription.unfollow', {value: currentItem.translation.title})}}
          </el-dropdown-item>
          <el-divider class="my-1"/>
          <el-dropdown-item @click="isEdit = true">
            {{$t('subscription.edit')}}
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <template v-else>
      <div class="store-notification-header" v-if="currentItem">
        <div v-if="isFolder" v-html="$t('subscription.notification_header_if_folder', {value: currentItem.translation.title})"></div>
        <div v-else v-html="$t('subscription.notification_header_if_not_folder', {value: currentItem.translation.title})"></div>
      </div>
      <div v-if="!GUEST">
        <el-checkbox v-model="data.daily_db_notification" :label="$t('subscription.via_notification')" class="mb-1.5"/>
      </div>
      <div>
        <el-checkbox v-model="data.isEmail" :label="$t('subscription.via_email')" class="mb-1.5"/>
      </div>
      <div v-if="data.isEmail">
        <div class="store-mailer-sub">
          <div>
            <el-checkbox :true-value="1" :false-value="0" v-model="data.daily_email_notification" :label="$t('subscription.daily')" class="mb-1.5"/>
          </div>
          <div>
            <el-checkbox :true-value="1" :false-value="0" v-model="data.weekly_email_notification" :label="$t('subscription.weekly')" class="mb-1.5"/>
          </div>
        </div>
        <div class="store-mailer-email">
          <el-input type="email" v-model="data.email" :placeholder="$t('auth.email')" autocomplete="off"/>
        </div>
      </div>
      <el-button type="primary" plain @click="onSubscribe" :disabled="!isValid" class="notification-subscribe-btn">
        <span>{{$t('subscription.follow', {value: currentItem.translation.title})}}</span>&nbsp;
        <i class="icon-bell"/>
      </el-button>
    </template>
    <el-divider class="my-3" v-if="dividers"></el-divider>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {randomSubscriptions} from '@/mixins/store/randomSubscriptions';

export default {
  name: 'store-mailer',
  props: {
    dividers: {
      type: Boolean,
      default: true,
    },
    isFolder: {
      type: Boolean,
      default: false,
    },
    showClientRecommendationsLogo: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'STORE'
    }
  },
  data() {
    return {
      isEdit: false,
      data: {
        isEmail: false,
        email: '',
        daily_db_notification: false,
        daily_email_notification: false,
        weekly_email_notification: false,
        locale_id: 1 // required: if Guest
      }
    }
  },
  mixins: [randomSubscriptions],
  computed: {
    ...mapGetters('store', ['CURRENT_STORE']),
    ...mapGetters('brand', ['CURRENT_BRAND']),
    ...mapGetters('webshop', ['CURRENT_WEBSHOP']),
    ...mapGetters('product', ['CURRENT_PRODUCT']),
    ...mapGetters('auth', ['GUEST', 'USER']),
    currentItem() {
      return this[`CURRENT_${this.type}`];
    },
    isValid() {
      if (this.GUEST) {
        return this.data.isEmail && this.data.email && (this.data.weekly_email_notification || this.data.daily_email_notification);
      }
      return this.data.email && (this.data.daily_db_notification || this.data.weekly_email_notification || this.data.daily_email_notification);
    }
  },
  created() {
    this.setSubscriptionData(this.currentItem);
  },
  watch: {
    currentItem(newValue) {
      this.setSubscriptionData(newValue);
    },
    'data.isEmail'(newValue, oldValue) {
      if (oldValue && !newValue) {
        this.data.daily_email_notification = false;
        this.data.weekly_email_notification = false;
      }
    }
  },
  methods: {
    ...mapActions('store', {
      'SET_SUBSCRIPTION_STORE': 'SET_SUBSCRIPTION',
      'REMOVE_SUBSCRIPTION_STORE': 'REMOVE_SUBSCRIPTION'
    }),
    ...mapActions('brand', {
      'SET_SUBSCRIPTION_BRAND': 'SET_SUBSCRIPTION',
      'REMOVE_SUBSCRIPTION_BRAND': 'REMOVE_SUBSCRIPTION'
    }),
    ...mapActions('webshop', {
      'SET_SUBSCRIPTION_WEBSHOP': 'SET_SUBSCRIPTION',
      'REMOVE_SUBSCRIPTION_WEBSHOP': 'REMOVE_SUBSCRIPTION'
    }),
    ...mapActions('product', {
      'SET_SUBSCRIPTION_PRODUCT': 'SET_SUBSCRIPTION',
      'REMOVE_SUBSCRIPTION_PRODUCT': 'REMOVE_SUBSCRIPTION'
    }),
    onUnsubscribe() {
      this[`REMOVE_SUBSCRIPTION_${this.type}`](this.currentItem.id).then(() => {
        this.data = {
          isEmail: false,
          email: this.USER.email,
          daily_db_notification: false,
          daily_email_notification: false,
          weekly_email_notification: false,
          locale_id: 1 // required: if Guest
        }
      })
    },
    onSubscribe() {
      this[`SET_SUBSCRIPTION_${this.type}`]({
        ...this.data,
        id: this.currentItem.id,
        locale_id: this.$i18n.locale,
      }).then(() => {
        this.isEdit = false
      })
    },
    setSubscriptionData(data) {
      if (data && data.subscription) {
        const {id, ...subscription} = JSON.parse(JSON.stringify(data.subscription));
        this.data = subscription
        if (!this.data) return null
        this.data.isEmail = !!(this.data.daily_email_notification || this.data.weekly_email_notification);
        this.data.daily_email_notification = !!this.data.daily_email_notification;
        this.data.weekly_email_notification = !!this.data.weekly_email_notification;
        this.data.daily_db_notification = !!this.data.daily_db_notification;
      } else if (data) {
        this.data.email = this.USER.email;
      }
    },
  }
}
</script>