import store from '@/store';

export const dropper = {
    methods: {
        resetAllFoldersModules() {
            store.dispatch('folder/RESET_FOLDERS');
            store.dispatch('folder-expired/RESET_FOLDERS');
            store.dispatch('folder-popular/RESET_FOLDERS');
            store.dispatch('folder-food/RESET_FOLDERS');
            store.dispatch('folder-garden/RESET_FOLDERS');
            store.dispatch('folder-media/RESET_FOLDERS');
        },
        resetAllPromosModules() {
            store.dispatch('promo/RESET_PROMOS');
            store.dispatch('promo-expired/RESET_PROMOS');
        },
    }
};
