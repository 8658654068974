<template>
  <div class="progress-bar">
    <div
      class="progress-bar__step"
      v-for="i in stepsCount"
      :class="{...stepClass, 'progress-bar__step--active': i <= currentStep}"
      :key="i"
    />
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    stepsCount: {
      type: Number,
    },
    currentStep: {
      type: Number,
      default: 1
    },
    stepClass: {
      type: [Object, String],
      default: ''
    },
  },
}
</script>
