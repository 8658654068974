<template>
  <div>
    <div class="w-100 login-label-header" v-t="'auth.register_chose_of'"></div>

    <div class="class-register-icons">
      <i class="icon-Facebook cursor-pointer" @click="externalRegister('facebook')"/>
      <div class="p-2" :class="{'font-size-20': !xsOnly, 'font-size-16': xsOnly}" v-t="'auth.or'"></div>
      <i class="icon-Google-Chrome cursor-pointer" @click="externalRegister('google')">
        <span class="path1"></span>
        <span class="path2"></span>
        <span class="path3"></span>
        <span class="path4"></span>
        <span class="path5"></span>
      </i>
      <div class="p-2" :class="{'font-size-20': !xsOnly, 'font-size-16': xsOnly}" v-t="'auth.or'"/>
      <i class="icon-Apple cursor-pointer" @click="externalRegister('apple')"/>
    </div>

    <div class="w-100 login-label" v-t="'auth.or_register_by_email'"></div>

    <el-form :model="form"
             ref="form"
             :rules="rules"
             class="form-error">

      <el-form-item class="email-form-error" prop="email">
        <el-input
            class="rounded-input"
            :placeholder="$t('auth.email')"
            :size="inputsSize"
            v-model="form.email">
        </el-input>
      </el-form-item>

      <el-row>
        <el-col :span="12">
          <el-form-item prop="fname">
            <el-input
                class="rounded-input me-2"
                v-model="form.fname"
                :size="inputsSize"
                :placeholder="$t('auth.fname')">
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item class="lname-form-error" prop="lname">
            <el-input
                class="rounded-input ms-2"
                v-model="form.lname"
                :size="inputsSize"
                :placeholder="$t('auth.lname')">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item prop="password">
            <el-input
                class="rounded-input me-2"
                :placeholder="$t('auth.password')"
                :size="inputsSize"
                v-model="form.password"
                show-password>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="password_confirmation">
            <el-input
                class="rounded-input ms-2"
                :placeholder="$t('auth.confirm_password')"
                :size="inputsSize"
                v-model="form.password_confirmation"
                show-password>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <div class="d-flex align-items-center mb-2">
        <div class="centered mb-2">
          <el-upload
              class="avatar-uploader"
              accept="image/png,image/jpg,image/jpeg"
              :action="uploadImageUrl"
              :headers="{'Accept':'application/json'}"
              :on-remove="handleImageRemove"
              :on-error="handleImageError"
              :on-change="handleImageChange"
              :show-file-list="false"
          >
            <img v-if="avatarPreview" :src="avatarPreview" class="avatar" ref="trigger_avatar">
            <div v-else class="avatar-uploader-icon" ref="trigger_avatar">
              <i class="icon-avatar"/>
            </div>
          </el-upload>
        </div>
        <div class="text-center text-decoration-underline mt-2 px-3 color-text-primary">
          <span class="cursor-pointer" v-t="'auth.upload_avatar'" @click="triggerAvatarUpload"></span>
        </div>
      </div>

      <el-button
        type="primary"
        class="w-100 hover:bg-primary--dark font-size-16"
        @click="validate"
      >
        <span v-t="'auth.register_step.next_step'"></span>
      </el-button>
    </el-form>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {validator} from '@/mixins/validator';
import {facebook} from '@/mixins/auth/facebook';
import {socialLogin} from '@/mixins/auth/socialLogin';
import {google} from '@/mixins/auth/google';
import {apple} from '@/mixins/auth/apple';
import {webviewLogin} from '@/mixins/auth/webviewLogin';
import validateStep from '@/mixins/auth/validateStep';

export default {
  name: 'register-step',
  mixins: [validator, validateStep, facebook, google, apple, socialLogin, webviewLogin],
  props: ['tryRegisterData'],
  data() {
    return {
      step: 1,
      webviewEventName: 'register',
      avatarPreview: '',
      form: {
        email: '',
        fname: '',
        lname: '',
        password: '',
        password_confirmation: '',
        avatar: ''
      },
      rules: {
        email: [
          {validator: this.backendValidator}
        ],
        fname: [
          {validator: this.backendValidator}
        ],
        lname: [
          {validator: this.backendValidator}
        ],
        password: [
          {validator: this.backendValidator}
        ],
        avatar: [
          {validator: this.backendValidator}
        ]
      }
    }
  },
  computed: {
    uploadImageUrl() {
      return process.env.VUE_APP_API_URL + 'register/upload-image';
    }
  },
  mounted() {
    this.emitter.on('try-login', (data) => {
      this.externalLogin(data.provider);
    });

    if (_.get(this.tryRegisterData, 'provider') !== undefined && _.get(this.tryRegisterData, 'options') !== undefined) {
      this.checkUser(this.tryRegisterData.provider, this.tryRegisterData.options);
    }
  },
  methods: {
    handleImageError(err, file, files) {
      const e = JSON.parse(err.message);

      this.$notify.error({
        title: this.$t('notifications.default.title'),
        message: _.get(e, 'errors.file.0', this.$t('notifications.default.message')),
        position: this.notificationPosition,
        duration: this.notificationDuration,
      });
    },

    handleImageChange(file) {
      this.avatarPreview = URL.createObjectURL(file.raw);
      this.form.avatar = '';

      if (_.get(file, 'response.file')) {
        this.form.avatar = _.get(file, 'response.file');
      }
    },

    handleImageRemove() {
      this.avatarPreview = '';
      this.form.avatar = '';
    },

    triggerAvatarUpload() {
      this.$refs.trigger_avatar.click();
    },

    externalLogin(provider) {
      switch (provider) {
        case 'apple':
          this.appleLogin(); // from apple mixin
          break;
        case 'facebook':
          this.facebookLogin(); // from facebook mixin
          break;
        case 'google':
          this.googleLogin(); // from google mixin
          break;
      }
    },

    externalRegister(provider) {
      switch (provider) {
        case 'apple':
          this.appleRegister(); // from apple mixin
          break;
        case 'facebook':
          this.facebookRegister(); // from facebook mixin
          break;
        case 'google':
          this.googleRegister(); // from google mixin
          break;
      }
    }
  }
}
</script>
