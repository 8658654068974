<template>
  <div class="top-folders">
    <!--
      Navigation + Scrollbar
    -->
    <div class="navigation-line mt-3" ref="navigation_line">
      <div class="nav-buttons">
        <button
          v-if="isLeftArrowActive"
          class="float-start"
          ref="leftArrow"
          @click="scrollPrev"
        >
          <i class="icon-triangle-arrow icon-flipped"/>
        </button>

        <el-scrollbar
          class="w-100"
          ref="scrollbar_ref"
          :native="false"
          :min-size="40"
          @touchstart="handleTouchStart"
          @touchmove="handleTouchMove"
          @touchend="handleTouchEnd"
          v-loading="isLoading"
        >
          <div class="scrollbar-flex-content">
            <div
              class="scrollbar-item"
              v-for="(folder, i) in folders"
              :style="previewItemContainerStyle"
              :key="i"
            >
              <folder-preview :folder="folder" :vuex-module="vuexModule"/>
            </div>
          </div>
        </el-scrollbar>

        <button
          :class="{'invisible': !isRightArrowActive}"
          class="float-end"
          ref="rightArrow"
          @click="scrollNext"
        >
          <i class="icon-triangle-arrow"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FolderPreview from '@/components/feed/folder/FolderPreview.vue';

export default {
  name: 'top-folders',
  emits: ['load', 'next', 'prev'],
  components: {FolderPreview},
  props: {
    folders: Array,
    vuexModule: {
      type: String,
      default: 'folder',
    },
    customWidth: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      containerWidth: 0,
      currentScroll: 0,
      currentScrollPx: 0,
      loadingEmitted: false,
    }
  },
  computed: {
    /**
     * @returns {boolean}
     */
    isLeftArrowActive() {
      return this.currentScrollPx > 0
    },
    /**
     * @returns {boolean}
     */
    isRightArrowActive() {
      return this.currentScrollPx < this.maxScrollPx
    },
    maxScrollPx() {
      return this.folders.length * this.widthInPx - this.containerWidth;
    },
    previewItemContainerStyle() {
      return {
        'width': this.widthInPx + 'px'
      }
    },
    widthInPx() {
      if (!this.containerWidth) {
        return;
      }

      let width = this.customWidth

      if (width.indexOf('%') !== -1) {
        width = width.replace(/%/g, '')

        return this.containerWidth * Number(width) / 100
      }

      width = width.replace(/[a-zA-Z]/g, '')

      return Number(width)
    },
    arrowWidth() {
      return this.xsOnly ? 23 : 28
    }
  },
  mounted() {
    new ResizeObserver(() => this.calculateContainerWidth()).observe(this.$refs.navigation_line);
  },
  methods: {
    handleTouchStart(event) {
      this.startX = event.touches[0].clientX;
    },
    handleTouchMove(event) {
      this.currentX = event.touches[0].clientX;
    },
    handleTouchEnd() {
      if (this.startX - this.currentX > 20) {
        this.scrollNext()
      } else if (this.currentX - this.startX > 20) {
        this.scrollPrev()
      }
    },
    calculateContainerWidth() {
      if (this.$refs.navigation_line) {
        this.containerWidth = this.$refs.navigation_line.clientWidth - this.arrowWidth;

        if (this.currentScroll !== 0) {
          this.containerWidth -= this.arrowWidth
        }
      }
    },
    scrollNext() {
      this.currentScroll++;
      this.$emit('next')
    },
    scrollPrev() {
      if (this.currentScroll > 0) {
        this.currentScroll--;
        this.$emit('prev')
      }
    },
  },
  watch: {
    currentScroll(value) {
      this.calculateContainerWidth()
      this.currentScrollPx = this.widthInPx * value;
      this.$refs.scrollbar_ref.setScrollLeft(this.currentScrollPx);
    },
    currentScrollPx(value) {
      if (value > this.maxScrollPx && !this.loadingEmitted) {
        this.$emit('load');
        this.loadingEmitted = true;
        setTimeout(() => {
          this.loadingEmitted = false;
        }, 3000);
      }
    }
  }
}
</script>
