import store from '@/store';

const getDefaultState = () => {
    return {
        queryParams: {
            page: 1,
            per_page: 100
        },
        all: [],
        is_complete: false,
        promise: null,
        checkPromise: null,
        refresh_delay: 180000, // 3min
    }
};

export default {
    namespaced: true,
    state: () => (getDefaultState()),

    getters: {
        NOTIFICATIONS(state) {
            return state.all;
        },
        REFRESH_DELAY(state) {
            return state.refresh_delay;
        },
        NOTIFICATIONS_IS_COMPLETE(state) {
            return state.is_complete;
        },
        NOTIFICATION_PROMISE(state) {
            return state.promise;
        },
        CHECK_PROMISE(state) {
            return state.checkPromise;
        },
    },

    actions: {
        async SYNC_NOTIFICATIONS({state, commit, dispatch}, params = {}) {
            setTimeout(() => dispatch('SYNC_NOTIFICATIONS', params), state.refresh_delay);

            return await dispatch('FETCH_NOTIFICATIONS', params);
        },

        async FETCH_NOTIFICATIONS({state, commit}, args = {}) {
            if (state.promise) {
                return state.promise;
            }

            const params = {...state.queryParams, ...args}

            const promise = axios.get('/notifications', {params: params})
                .then((response) => {
                    commit('SET_NOTIFICATIONS', response.data);
                })
                .catch((e) => console.log(e))
                .then(() => commit('UPDATE_NOTIFICATION_PROMISE', null));

            commit('UPDATE_NOTIFICATION_PROMISE', promise);

            return promise;
        },

        async READ_NOTIFICATION({commit}, id) {
            return await axios.post(`/notifications/${id}/read`).then((response) => {
                commit('UPDATE_NOTIFICATION', response.data.data);
                store.dispatch('notifications/SYNC_NOTIFICATIONS', {unread: 1, per_page: 100});
            });
        },

        async CHECK_NOTIFICATIONS({commit}, id) {
            return await axios.post(`/notifications/${id}/check`).then((response) => {
                commit('UPDATE_NOTIFICATIONS', response.data.data);
                store.dispatch('notifications/SYNC_NOTIFICATIONS', {unread: 1, per_page: 100});
            });
        },

        async READ_ALL_NOTIFICATIONS({commit}) {
            return await axios.post('/notifications/read').then((response) => {
                commit('UPDATE_NOTIFICATIONS', response.data.data);
                store.dispatch('notifications/SYNC_NOTIFICATIONS', {unread: 1, per_page: 100});
            });
        },

        async CHECK_ALL_NOTIFICATIONS({commit}) {
            const promise = axios.post('/notifications/check')
                .then((response) => {
                    commit('UPDATE_NOTIFICATIONS', response.data.data);
                })
                .then(() => commit('UPDATE_CHECK_PROMISE', null));

            commit('UPDATE_CHECK_PROMISE', promise);

            return promise;
        },

        async DELETE_NOTIFICATION({commit}, id) {
            return await axios.delete('/notifications/' + id).then((response) => {
                commit('REMOVE_NOTIFICATION', id);
                store.dispatch('notifications/SYNC_NOTIFICATIONS', {unread: 1, per_page: 100});
            });
        },

        async DELETE_ALL_NOTIFICATIONS({commit}, params) {
            return await axios.delete('/notifications' + params).then((response) => {
                commit('REMOVE_NOTIFICATIONS');
                store.dispatch('notifications/SYNC_NOTIFICATIONS', {unread: 1, per_page: 100});
            });
        },

        async RESET_NOTIFICATIONS({commit}) {
            commit('SET_DEFAULT_DATA');
        },
    },

    mutations: {
        SET_NOTIFICATIONS(state, data) {
            const notifications = data.data
            state.is_complete = _.isEmpty(notifications) || _.get(data, 'meta.current_page', 0) === _.get(data, 'meta.last_page', 1);
            state.queryParams.page += state.is_complete ? 0 : 1;

            return state.all = _.get(data, 'meta.current_page', 0) === 1 ? notifications : _.union(state.all, notifications);
        },
        UPDATE_NOTIFICATIONS(state, notifications) {
            for (const i in state.all) {
                for (const j in notifications) {
                    if (state.all[i].id === notifications[j].id) {
                        state.all[i] = notifications[j]
                    }
                }
            }
        },
        UPDATE_NOTIFICATION(state, notification) {
            for (const i in state.all) {
                if (state.all[i].id === notification.id) {
                    state.all[i] = notification
                }
            }
        },
        REMOVE_NOTIFICATION(state, id) {
            state.all = state.all.filter((notification) => notification.id !== id);
        },
        REMOVE_NOTIFICATIONS(state) {
            state.all = []
        },
        UPDATE_NOTIFICATION_PROMISE(state, data) {
            return state.promise = data;
        },
        UPDATE_CHECK_PROMISE(state, data) {
            return state.checkPromise = data;
        },
        SET_DEFAULT_DATA(state) {
            const defaultState = getDefaultState();

            return _.assign(state, defaultState);
        }
    }
}
