<template>
  <div class="banner-section">
    <default-space :span="lgAndUp ? 23 : 24">
      <ad-carousel :interval="10000" ad-type="long"/>
    </default-space>
  </div>
</template>

<script>
import AdCarousel from '@/components/feed/carousels/AdCarousel.vue';
import DefaultSpace from '@/views/DefaultSpace.vue';

export default {
  name: 'BannerSection',
  components: {DefaultSpace, AdCarousel},
}
</script>
