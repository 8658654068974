<template>
  <div>
    <div class="el-dialog__text" v-html="$t('auth.info_step.point1')"/>

    <div class="el-dialog__text" v-html="$t('auth.info_step.point2')"/>

    <div class="el-dialog__text" v-html="$t('auth.info_step.point3')"/>

    <div class="centered">
      <el-button
        type="primary"
        class="submit-btn"
      >
        <router-localized-link :to="{name: 'login'}">
          <span v-t="'auth.login'"></span>
        </router-localized-link>
      </el-button>
    </div>

    <div class="centered">
      <router-localized-link :to="{name: 'register'}">
        <div
            class="cursor-pointer secondary-btn"
            v-html="$t('auth.or_register')"
        />
      </router-localized-link>
    </div>
  </div>
</template>

<script>
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink.vue';

export default {
  name: 'default-step',
  components: {RouterLocalizedLink}
}
</script>
