<template>
  <base-filter ref="filter"
               :filter-label="$t('filters.stores')"
               :filter-options="filterOptions"
               :default-checked="defaultFilterValue"
               filter-key="stores_filter"
               @filter="filter">
  </base-filter>
</template>

<script>
import BaseFilter from '@/components/left_bar/filters/BaseFilter';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'stores-filter',
  components: {BaseFilter},
  props: {
    stores: {
      type: Array,
      default() {
        return []
      }
    },
  },
  computed: {
    ...mapGetters('promo', [
      'PROMOS_STORES',
      'PROMOS_TOTAL',
    ]),
    ...mapGetters('store', [
      'STORES',
    ]),
    ...mapGetters('filter', [
      'FILTERS_VALUES',
    ]),

    filterOptions() {
      const resultOptions = {};
      _.forEach(this.STORES, function (store) {
        resultOptions[store.id] = store.translation.title;
      });
      return resultOptions;
    },
    storesFilter() {
      return _.get(this.$router.currentRoute.value.query, 'stores_filter[]', [])
    },
    defaultFilterValue() {
      return _.castArray(this.storesFilter)
    }
  },
  mounted() {
    if (!_.isEmpty(this.storesFilter)) {
      this.$refs.filter.setCheckedList(this.defaultFilterValue);
    }
    if (!this.STORES.length) {
      this.FETCH_STORES();
    }
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_VALUE',
    ]),
    ...mapActions('store', [
      'FETCH_STORES',
    ]),

    filter(checked) {
      this.SET_FILTER_VALUE({stores_filter: checked});
    },
  }
}
</script>
