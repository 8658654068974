<template>
  <div class="actions">
    <div class="dot-menu">
      <el-dropdown placement="bottom-end" trigger="click">
        <div class="h-100 d-flex align-items-center">
          <i class="cursor-pointer icon-dots-menu"/>
        </div>
        <template #dropdown>
          <el-dropdown-menu class="post-menu-dropdown">
            <el-dropdown-item @click="processSubscription(isSubscribed)">
              <i class="icon-bell-4 me-2"/>
              {{ isSubscribed ? $t('post.disable_notification') : $t('post.enable_notification') }}
            </el-dropdown-item>

              <el-divider class="my-1"/>

            <el-dropdown-item @click="hide">
              <i class="icon-close-3 me-2"/>
              {{ $t('post.hide_post') }}
            </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    <div class="favourite">
      <i
        class="cursor-pointer"
        :class="{'active icon-heart-2': item.is_favourite, 'icon-heart': !item.is_favourite}"
        @click="processFavourite"
      />
    </div>
  </div>
</template>

<script>
import {needAuth} from '@/mixins/auth/needAuth';
import {actions} from '@/mixins/post/actions';
import {mapGetters} from 'vuex';

export default {
  name: 'Actions',
  emits: ['hidden', 'restored'],
  mixins: [needAuth, actions],
  props: {
    item: Object,
    morphable_type: String,
    vuexModule: {
      type: String,
      default: 'promo',
    },
  },
  computed: {
    /**
     * mapGetters
     */
    ...mapGetters('auth', [
      'USER',
      'GUEST',
    ]),
    /**
     * isSubscribed
     * @returns {*|boolean}
     */
    isSubscribed() {
      return !this.GUEST
        ? this.item.subscriptions.some(item => item.user_id === this.USER.id)
        : false;
    },
    /**
     * Data for mixin
     * @returns {{morphable_type: string, entity: Object}}
     */
    entityData() {
      return {
        entity: this.item,
        morphable_type: this.morphable_type
      }
    }
  },
  methods: {
    /**
     * Hide post
     */
    hide() {
      this.processHide().then((r) => {
        this.$emit('hidden')
      });
    },
    /**
     * Hide post
     */
    restore() {
      this.processRestore().then((r) => {
        this.$emit('restored')
      });
    },
  }
}
</script>
