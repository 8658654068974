import store from '@/store';
import { googleTokenLogin } from 'vue3-google-login';

export const google = {
  methods: {
    // Login methods
    googleLogin() {
      if (this.webview && typeof window.ReactNativeWebView != 'undefined') {
        this.googleWebviewEvent();
      } else {
        this.googleDefaultLogin();
      }
    },
    googleDefaultLogin() {
      store.dispatch('loader/SET_LOADER', true);
      googleTokenLogin()
        .then((response) => {
          this.socialLogin('google', {
            googleAuthToken: response.access_token,
          }); // from socialLogin mixin
        })
        .catch((error) => {
          store.dispatch('loader/SET_LOADER', false);
        });
    },
    googleWebviewEvent() {
      store.dispatch('loader/SET_LOADER', true);
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'true',
          actionType: 'loginGOOGLE',
        })
      );
    },
    // Register methods
    googleRegister() {
      if (this.webview && typeof window.ReactNativeWebView != 'undefined') {
        this.googleWebviewEvent();
      } else {
        this.googleDefaultRegister();
      }
    },
    googleDefaultRegister() {
      store.dispatch('loader/SET_LOADER', true);
      googleTokenLogin()
        .then((response) => {
          this.checkUser('google', { googleAuthToken: response.access_token });
        })
        .catch((error) => {
          store.dispatch('loader/SET_LOADER', false);
        });
    },
  },
};
