<template>
  <div class="position-relative promojagers-tabs">
    <el-scrollbar :native="false" :always="xsOnly">
      <div class="feed-filter">
        <div
          v-if="filtersIconActive"
          class="filter-icon d-flex d-lg-none"
          @click="filterMenuVisible = !filterMenuVisible"
        >
          <i class="icon-filter"/>
        </div>
        <el-divider v-if="xsOnly && filtersIconActive && Object.keys(tabs).length" direction="vertical"/>

        <template v-for="(tabName, tabKey, index) in tabs" :key="tabKey">
          <el-divider v-if="index !== 0" direction="vertical"/>
          <el-tag
            :class="{'cursor-pointer': tabKey !== feedTab}"
            :effect="tabKey === feedTab ? 'dark' : 'plain'"
            :size="tagSize"
            @click="changeTab(tabKey)">
            {{ tabName }}
          </el-tag>
        </template>
      </div>
    </el-scrollbar>

    <Transition name="filters-home-anime">
      <div v-show="filterMenuVisible" class="filters-menu" :class="menuClass" ref="filtersMenu">
        <div class="filters-overlay" @click="hideFilters"/>
        <div class="filters-menu__inner px-3.5">
          <div class="icon-filters-close" @click="hideFilters">
            <i class="icon-chevron-thin-right rotate-180"></i>
          </div>
          <filters-list/>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import FiltersList from '@/components/left_bar/filters/FiltersList';
import {needAuth} from '@/mixins/auth/needAuth';

export default {
  name: 'tabs',
  mixins: [needAuth],
  components: {
    FiltersList,
  },
  emits: ['change'],
  props: {
    tabs: {
      type: Object,
      default() {
        return {
          'default': 'Overzicht',
          'favourites': 'Favorieten',
        }
      }
    },
    default: {
      type: String,
      default: 'default'
    },
    blockForGuest: {
      type: Boolean,
      default: true
    },
    changeQuery: {
      type: Boolean,
      default: false
    },
    menuClass: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      filterMenuVisible: false,
      feedTab: _.has(this.tabs, this.default) ? this.default : 'default', // default,my-feed,most-discount,favourites,most-hits,last-7-days
    }
  },
  computed: {
    filtersIconActive() {
      return _.toArray(this.$store.getters['filter/ACTIVE_FILTERS']).includes(true)
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      if (this.xsOnly && this.$refs.filtersMenu && window.pageYOffset > this.$refs.filtersMenu.clientHeight + 260) {
        this.hideFilters()
      }
    })
    this.emitter.on('close-filter', () => {
      if (this.xsOnly) {
        this.hideFilters()
      }
    });
  },
  methods: {
    hideFilters() {
      this.filterMenuVisible = false
    },
    changeTab(newKey) {
      if (this.blockForGuest && this.triggerLoginDialog()) {
        return;
      }

      if (this.changeQuery) {
        let query = _.clone(this.$router.currentRoute.value.query);
        query = _.omit(query, ['tab']);
        if (newKey !== 'default') {
          query['tab'] = newKey;
        }
        this.$router.replace({query: query});
      }

      this.feedTab = newKey;
      this.$emit('change', newKey);
    }
  }
}
</script>
