<template>
  <div class="right-bar-container">
    <el-scrollbar height="calc(100vh - 70px - 50px)">

      <advertiser></advertiser>

      <div style="min-height: 210px"></div>
    </el-scrollbar>
  </div>
</template>

<script>
import Advertiser from '@/components/right_bar/Advertiser';
import {mapActions} from 'vuex';
import router from '@/router';

export default {
  name: 'right-bar',
  components: {Advertiser},
  computed:{
    isHomeRoute() {
      return router.currentRoute.value.name === 'home';
    },
  },
}
</script>
