import store from '@/store';

export default async (to) => {
    const currentCategory = store.getters['article_category/CURRENT_ARTICLE_CATEGORY'];

    await store.dispatch('article/FETCH_CURRENT_ARTICLE', {article_slug: to.params.articleSlug, category_id: currentCategory.id});
    if (_.isNil(store.getters['article/CURRENT_ARTICLE'])) {
        return {name: 'news.category', params: {categorySlug: currentCategory.translation.route}};
    }
};
