<template>
  <el-dialog
    v-model="showDiscountCode"
    custom-class="primary-header-dialog copied-discount-code-dialog"
    :lock-scroll="false"
    :title="$t('post.notifications.copied.title')"
    close-on-click-modal
    close-on-press-escape
    show-close
    center
  >
    <post :post="discountCode" :discount-code-copied="true"/>
  </el-dialog>
</template>

<script>
import Post from '@/components/feed/post/Post';

export default {
  name: 'copied-discount-code-dialog',
  components: {Post},
  data() {
    return {
      discountCode: null,
      showDiscountCode: false,
    }
  },
  mounted() {
    if (localStorage.getItem('CopiedDiscountCodeId')) {
      this.loadDiscountCode(localStorage.getItem('CopiedDiscountCodeId'));

      localStorage.removeItem('CopiedDiscountCodeId');
    }
  },
  methods: {
    loadDiscountCode(id) {
      axios.get('/feed-items?post=' + id).then((response) => {
        this.discountCode = _.get(response, 'data.data[0].data', null);
        if (this.discountCode) {
          this.showDiscountCode = true;
        }
      });
    }
  }
}
</script>
