<template>
  <el-dialog
    v-model="visible"
    :lock-scroll="!xsOnly"
    width="100vw"
    modal-class="main-menu"
    close-on-press-escape
    close-on-click-modal
    destroy-on-close
    show-close
    center
  >
    <template #header>
      <div class="menu-header-wrapper" ref="main_menu_header">
        <span class="menu-header font-weight-medium" v-t="'menu.main.menu'"/>

        <locale-switcher v-if="!xsOnly"/>
      </div>
    </template>

    <div class="menu-items-wrapper" ref="main_menu_body">
      <!--   Folders   -->
      <div class="menu-item">
        <div class="icon-wrapper">
          <i class="icon-folder"/>
        </div>
        <router-localized-link v-t="'menu.main.folders'" :to="{name: 'folders'}"/>
      </div>

      <!--   Promos   -->
      <div class="menu-item">
        <div class="icon-wrapper">
          <i class="icon-percent1"/>
        </div>
        <router-localized-link v-t="'menu.main.promos'" :to="{name: 'promos'}"/>
      </div>

      <!--   News   -->
      <div v-if="LOCALE === 'nl'" class="menu-item menu-delimiter">
        <div class="icon-wrapper">
          <i class="icon-bullhorn-2"/>
        </div>
        <router-localized-link v-t="'menu.main.news'" :to="{name: 'news'}"/>
      </div>

      <!--   Mobile app   -->
      <div class="menu-item" :class="{'menu-delimiter': !xsOnly}">
        <div class="icon-wrapper">
          <i class="icon-mobile-app"/>
        </div>
        <router-localized-link v-t="'menu.main.mobile_app'" :to="getPagePathBySlug('mobile-app')"/>
      </div>

      <div class="menu-item" :class="{'menu-delimiter': !xsOnly}">
        <div class="icon-wrapper">
          <i class="icon-question-mark-1"/>
        </div>
        <router-localized-link
            v-t="'menu.main.who_is_promojagers'"
            :to="getPagePathBySlug('wie-of-wat-zijn-promojagers')"
        />
      </div>

      <!--   Contact   -->
      <div class="menu-item menu-delimiter">
        <div class="icon-wrapper">
          <i class="icon-contact"/>
        </div>
        <router-localized-link v-t="'menu.main.contact'" :to="getPagePathBySlug('contact')"/>
      </div>

      <!--   Login   -->
      <router-localized-link class="menu-item" :to="{name: 'login'}">
        <div class="icon-wrapper">
          <i class="icon-login color-primary"/>
        </div>
        <span class="color-primary" v-t="'menu.main.login'"/>
      </router-localized-link>

      <div v-if="xsOnly" class="menu-item">
        <locale-switcher/>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import LocaleSwitcher from '@/components/header/LocaleSwitcher';
import {needAuth} from '@/mixins/auth/needAuth';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';

export default {
  name: 'main-menu',
  components: {RouterLocalizedLink, LocaleSwitcher},
  mixins: [needAuth],
  mounted() {
    if (!this.BONUS_ACTIONS.length) {
      this.FETCH_BONUS_ACTIONS();
    }
  },
  computed: {
    ...mapGetters('bonus_action', [
      'BONUS_ACTIONS',
      'BONUS_ACTION_TYPES',
    ]),
    ...mapGetters('page', [
      'PAGES',
      'PAGES_LOADED',
    ]),
    ...mapGetters('auth', [
      'LOCALE',
    ]),
    ...mapState('dialogs', {
      visible: ({dialogs}) => dialogs['main-menu'].visible,
    }),
    referralReward() {
      const action = this.BONUS_ACTIONS.filter(need => need.type === this.BONUS_ACTION_TYPES['referrals-first-post-approved'])[0];
      if (!action) {
        return '';
      }
      return '(+' + action.impact_on_balance + ')';
    },

    pagesInMenu() {
      const thisElem = this;
      return _.filter(this.PAGES, function (page) {
        return page.in_menu && thisElem.$i18n.locale === page.locale.slug;
      })
    }
  },
  beforeMount() {
    if (!this.PAGES_LOADED) {
      this.FETCH_PAGES();
    }
  },
  methods: {
    ...mapActions('page', [
      'FETCH_PAGES'
    ]),
    ...mapActions('bonus_action', [
      'FETCH_BONUS_ACTIONS',
    ]),
    /**
     * goReferralPage
     */
    goReferralPage() {
      if (this.triggerLoginDialog()) {
        return;
      }
      this.routerPush({name: 'referral_page'});
    },
  },
  watch: {
    currentScrollTop(newValue) {
      if (this.$refs.main_menu_header) {
        const mainMenuClientHeight = this.$refs.main_menu_header.clientHeight + this.$refs.main_menu_body.clientHeight;
        if (mainMenuClientHeight >0 && mainMenuClientHeight + 95 < newValue) {
          this.$dialogs.toggle('main-menu');
        }
      }
    }
  }
}
</script>
