<template>
  <el-dialog
    v-model="show"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :lock-scroll="false"
    width="100vw"
    :title="$t('auth.reset_password.header')"
    custom-class="login-dialog primary-header-dialog"
    center>

    <el-form :model="form" :rules="rules" ref="form" class="mt-3">

      <el-form-item prop="email">
        <el-input
            class="rounded-input"
            :placeholder="$t('auth.email')"
            :size="inputsSize"
            disabled
            v-model="form.email">
        </el-input>
      </el-form-item>

      <el-form-item prop="password">
        <el-input
            class="rounded-input"
            :placeholder="$t('auth.password')"
            :size="inputsSize"
            v-model="form.password"
            show-password>
        </el-input>
      </el-form-item>

      <el-form-item prop="password_confirmation">
        <el-input
            class="rounded-input"
            :placeholder="$t('auth.confirm_password')"
            :size="inputsSize"
            v-model="form.password_confirmation"
            show-password>
        </el-input>
      </el-form-item>

    </el-form>

    <el-button type="primary"
               class="w-100"
               @click="submitForm">
      <span v-t="'auth.reset_password.reset_button'"></span>
    </el-button>
  </el-dialog>
</template>

<script>
import {validator} from '@/mixins/validator';

export default {
  name: 'reset-password-dialog',
  mixins: [validator],
  emits: ['passwordReset', 'loggedIn'],
  data() {
    return {
      show: true,
      form: {
        email: '',
        token: '',
        password: '',
        password_confirmation: '',
      },
      rules: {
        email: [
          {required: true, validator: this.backendValidator}
        ],
        password: [
          {required: true, validator: this.backendValidator}
        ],
        password_confirmation: [
          {required: true, validator: this.backendValidator}
        ],
      }
    }
  },
  mounted() {
    this.form.email = this.$route.query.email;
    this.form.token = this.$route.query.token;
  },
  computed: {
    //
  },
  methods: {
    // Login with Email and Password
    submitForm() {
      this.$http.post('/password/reset', this.form).then((response) => {
        this.errors = {};
      }).catch((e) => {
        switch (_.get(e.response, 'status')) {
          case 422:
            this.errors = _.assign({}, e.response.data.errors);
            break;

          default:
            this.$notify.error({
              title: this.$t('notifications.default.title'),
              message: _.get(e.response, 'data.error', this.$t('notifications.default.message')),
              position: this.notificationPosition,
              duration: this.notificationDuration,
            });
            break;
        }
      }).then(() => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.$notify.success({
              title: this.$t('auth.reset_password.header'),
              message: this.$t('auth.reset_password.notifications.success_reset'),
              position: this.notificationPosition,
              duration: this.notificationDuration,
            });

            this.routerPush({name: 'home'});
          }
        });
      });
    },
  }
}
</script>
