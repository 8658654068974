<template>
  <div class="reactions-wrapper">
    <div class="reaction-info">
      <div class="d-flex align-items-center">
        <i @click="$emit('hit', !isLike)" :class="`me-2 icon-like-${isLike ? '4 active' : '3'}`"/>
        {{ rating > 0 ? rating : '' }}
      </div>
      {{
        commentsCount === 1 ? $t('comment.one_comment', {count: commentsCount}) : $t('comment.total_comments', {count: commentsCount})
      }}
    </div>
    <div class="reaction-actions">
      <el-button
        class="reaction-btn"
        :class="{'active': isLike, 'like-animation': likeAnimation}"
        @mousedown="!isLike && (likeAnimation = true)"
        @animationend="likeAnimation = false"
        @click="$emit('hit', !isLike)"
      >
        <i class="me-1 icon-like-3 font-weight-bold"/>
        {{ $t('post.like_it') }}
      </el-button>
      <el-button @click="$emit('react')" class="reaction-btn">
        <i class="me-1 icon-comment font-weight-bold"/>
        {{ $t('post.comment') }}
      </el-button>

      <el-dropdown class="reaction-dropdown" placement="bottom-end" trigger="click">
        <el-button class="reaction-dropdown-button w-100">
            <i class="me-1 icon-mail-forward font-weight-medium"/>{{ $t('post.share.header') }}
        </el-button>

        <template #dropdown>
          <el-dropdown-menu class="share-menu-dropdown">
            <el-dropdown-item @click="$emit('share', 'facebook')">
              <i class="icon-Facebook1 me-2"/>
              {{ $t('post.share.facebook') }}
            </el-dropdown-item>

<!--            <el-divider class="my-1"/>-->

<!--            <el-dropdown-item disabled @click="$emit('share', 'instagram')">-->
<!--              <i class="icon-Insta me-2"/>-->
<!--              {{ $t('post.share.instagram') }}-->
<!--            </el-dropdown-item>-->

            <el-divider class="my-1"/>

            <el-dropdown-item @click="$emit('share', 'messenger')">
              <i class="icon-Messenger me-2"/>
              {{ $t('post.share.messenger') }}
            </el-dropdown-item>

            <el-divider class="my-1"/>

            <el-dropdown-item @click="$emit('share', 'whats-app')">
              <i class="icon-WhatsApp me-2"/>
              {{ $t('post.share.whats_app') }}
            </el-dropdown-item>

            <el-divider class="my-1"/>

            <el-dropdown-item @click="$emit('share', 'email')">
              <i class="icon-envelope-o me-2"/>
              {{ $t('post.share.email') }}
            </el-dropdown-item>

            <el-divider class="my-1"/>

            <el-dropdown-item @click="copyLink">
              <i :class="`me-2 ${copiedLink ? 'icon-checkmark1' : 'icon-link'}`"/>
              {{ $t('post.share.copy_link') }}
            </el-dropdown-item>

            <template v-if="navigatorExists">
              <el-divider class="my-1"/>

              <el-dropdown-item @click="$emit('share', 'navigator')">
                <i class="icon-mail-forward me-2"/>
                {{ $t('post.share.more') }}
              </el-dropdown-item>
            </template>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'reactions',
  props: {
    rating: Number,
    isLike: Boolean,
    commentsCount: Number,
  },
  emits: ['hit', 'react', 'share'],
  data() {
    return {
      copiedLink: false,
      likeAnimation: false,
    }
  },
  computed: {
    navigatorExists() {
      return window.navigator && window.navigator.share;
    }
  },
  methods: {
    copyLink() {
      this.$emit('share', 'copy-link');
      this.copiedLink = true;
    }
  }
}
</script>
