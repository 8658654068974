import store from '@/store';

export default async (to, from, next) => {
    await store.dispatch('store/FETCH_CURRENT_STORE', to.params.storeSlug);

    if (_.isNil(store.getters['store/CURRENT_STORE'])) {
        next({name: 'folders'})
    }

    next();
};
