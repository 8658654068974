export default {
    namespaced: true,
    state: {
        loader: false,
        routeReloadKey: 0,
        routerActive: false,
    },

    getters: {
        IS_LOADING(state) {
            return state.loader;
        },
        ROUTE_RELOAD_KEY(state) {
            return state.routeReloadKey;
        },
        ROUTER_ACTIVE(state) {
            return state.routerActive;
        },
    },

    actions: {
        async SET_LOADER({commit}, loader = false) {
            await commit('UPDATE_LOADER', loader);
        },
        async FORCE_ROUTE_RELOAD({ state, commit, dispatch }) {
            await commit('INCREMENT_ROUTE_RELOAD_KEY');
        },
        async SET_ROUTER_ACTIVE({commit}, active = false) {
            await commit('UPDATE_ROUTER_ACTIVE', active);
        },
    },

    mutations: {
        UPDATE_LOADER(state, loader) {
            return (state.loader = loader);
        },
        INCREMENT_ROUTE_RELOAD_KEY(state) {
            return (state.routeReloadKey = state.routeReloadKey + 1);
        },
        UPDATE_ROUTER_ACTIVE(state, active) {
            return (state.routerActive = active);
        },
    }
}
