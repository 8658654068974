<template>
  <el-form
    ref="form"
    :model="form"
    class="post-step__container categories-step"
  >
    <el-input
      class="post-step__title"
      :placeholder="`${titleNumber}. ${$t('post.steps.select_category')}`"
      disabled
    />

    <el-form-item prop="categories" :rules="rules.categories">
      <div class="categories-step__grid">
        <el-tag
          v-for="category in categories"
          :key="category.id"
          class="category-tag"
          :class="{'hover:bg-secondary--dark': !categoriesState.find(c => c === category.id)}"
          :effect="categoriesState.find(c => c === category.id) ? 'dark' : 'plain'"
          :value="category.id"
          @click="categoryChanged(category)"
        >
          {{ category.translation.simplified_title }}
        </el-tag>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import {step} from '@/mixins/post/step';

export default {
  name: 'CategoriesStep',
  mixins: [step],
  computed: {
    ...mapGetters('category', ['CATEGORIES']),
    ...mapState('promo', {
      form: ({form}) => form,
      type: ({form}) => form.type,
      categoriesState: ({form}) => form.categories,
    }),
    /**
     * Filtered Categories
     * @returns {*}
     */
    categories() {
      return this.CATEGORIES.filter((c) => c.translation?.simplified_title)
          .sort((a, b) => {
            const isANumber = /^\d/.test(a.translation.simplified_title);
            const isBNumber = /^\d/.test(b.translation.simplified_title);

            if (isANumber && !isBNumber) return 1;  // Числовые категории идут после буквенных
            if (!isANumber && isBNumber) return -1; // Буквенные категории идут раньше
            return a.translation.simplified_title.localeCompare(b.translation.simplified_title);
          });
    }
  },
  data() {
    return {
      rules: {
        categories: [{validator: this.validator}]
      },
    }
  },
  mounted() {
    this.FETCH_CATEGORIES()
  },
  methods: {
    ...mapActions('category', ['FETCH_CATEGORIES']),
    /**
     * Categories validator
     * @param rule
     * @param value
     * @param cb
     */
    validator(rule, value, cb) {
      if (this.type.id === this.$consts.POST_TYPES.REPORT && !value) {
        cb(this.$t('post.errors.category'))
      }

      cb()
    },
    /**
     * Change category event
     * @param category
     */
    categoryChanged(category) {
      const categories = this.categoriesState

      if (categories.includes(category.id)) {
        this.updateForm({categories: categories.filter(id => id !== category.id)})
        return;
      }

      if (categories.length >= 2) {
        categories.shift();
      }
      categories.push(category.id);

      this.updateForm({categories})
    },
    /**
     * Validation
     * @returns {boolean}
     */
    async validate() {
      let result = false;

      await this.$refs.form.validate((isValid) => result = isValid)

      this.$emit('update', {titlesCount: 1})

      return result
    }
  }
}
</script>
