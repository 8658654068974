<template>
  <div class="filter-container folders-stores-filter">
    <div class="filter-header" @click="filterActive = !filterActive">
      <span class="filter-header__text" v-t="'folder.interested_stores'"/>
      <i class="trans-2" :class="filterActive ? 'icon-arrow-3 rotate-90' : 'icon-arrow-3'"/>
    </div>
    <div class="filter-options mt-3" v-if="filterActive" ref="stores_container">
      <div class="d-flex justify-content-around align-items-center flex-wrap">
        <template v-for="(store, i) in storesList" :key="i">
          <div class="store-image-item">
            <router-localized-link :to="{name: 'folders.store', params: {storeSlug: String(store.translation.route)}}">
              <img :src="store.image_url" alt="" :title="store.translation.title">
            </router-localized-link>
          </div>
        </template>
      </div>

      <div class="mt-2">
        <span class="color-primary cursor-pointer hover:text-underline"
              v-text="shortFilters ? $t('filters.show_more') : $t('filters.show_less')"
              @click="shortFilters = !shortFilters"/>
      </div>
    </div>
  </div>
  <el-divider class="bold-divider my-3"/>
</template>

<script>
import {mapGetters} from 'vuex';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';

export default {
  name: 'folders-stores',
  components: {RouterLocalizedLink},
  data() {
    return {
      shortFilters: true,
      shortLength: 9,
      filterActive: true,
    }
  },
  computed: {
    ...mapGetters('store', [
      'STORES',
    ]),
    storesList() {
      let result = _.filter(this.STORES, (need) => need.is_paid_client);
      result = _.sortBy(result, 'folders_count').reverse();

      return this.shortFilters ? _.take(result, this.shortLength) : result;
    },
  },
}
</script>
