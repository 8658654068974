import store from '@/store';

export default async (to, from, next) => {
    await store.dispatch('article/RESET_ARTICLES');
    await store.dispatch('article_category/FETCH_CURRENT_CATEGORY', to.params.categorySlug);

    if (_.isNil(store.getters['article_category/CURRENT_ARTICLE_CATEGORY'])) {
        next({name: 'news'});
    }

    next();
};
