<template>
  <input-form
    ref="form"
    class="mt-4"
    :image-action="imageAction"
    :placeholder="$t('comment.write_comment')"
    @submit="submit"
  >
    <template v-slot:submit-button>
      <el-button
        type="primary"
        class="comment-submit-btn"
        circle
        @click="submitComment"
      >
        <i class="icon-arrow-4"/>
      </el-button>
    </template>
  </input-form>
</template>

<script>
import InputForm from '@/components/feed/InputForm.vue';

export default {
  name: 'comment-form',
  components: {InputForm},
  emits: ['submit'],
  computed: {
    imageAction() {
      return process.env.VUE_APP_API_URL + 'comments/upload-image'
    }
  },
  methods: {
    focusTextarea() {
      this.$refs.form.$refs.content.focus()
    },
    submitComment() {
      this.$refs.form.submit()
    },
    submit(params) {
      const newParams = {content: params.content, image : params.images}

      this.$emit('submit', newParams)
    },
  }
}
</script>
