<template>
  <div class="folder-preview">
    <!--  Header  -->
    <div class="folder-header" ref="folder_header">
      <div class="folder-favourite">
        <i
          :class="{'active icon-heart-2': folder.is_favourite, 'icon-heart': !folder.is_favourite}"
          @click="processFavourite"
        />
      </div>
    </div>
    <!--  Content  -->
    <div class="folder-content">
      <div class="folder-image-container">
        <img
          :src="folder.image_url"
          class="folder-image"
          :style="{'max-width': imageWidth + `px`}"
          @click="goFolder" alt=""
        >
      </div>

      <div class="mt-1 text-center">
        <div class="store-title font-weight-bold w-100 mt-0">
          {{ folder.store.translation.title }}
        </div>

        <div class="expire-text w-100">
          <span>{{ folder.valid_text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {needAuth} from '@/mixins/auth/needAuth';

export default {
  name: 'folder-preview',
  mixins: [needAuth],
  props: {
    folder: Object,
    vuexModule: {
      type: String,
      default: 'folder',
    },
    isLinkedToStorePage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageWidth: 0,
    }
  },
  mounted() {
    new ResizeObserver(() => this.calculateHeight()).observe(this.$refs.folder_header);
  },
  methods: {
    calculateHeight() {
      if (!this.$refs.folder_header) {
        return 0;
      }

      this.imageWidth = this.$refs.folder_header.clientWidth - 20;
    },
    processFavourite() {
      if (this.triggerLoginDialog()) {
        return;
      }
      if (this.folder.is_favourite) {
        this.$store.dispatch(this.vuexModule + '/REMOVE_FAVORITE', this.folder.id);
      } else {
        this.$store.dispatch(this.vuexModule + '/SET_FAVORITE', this.folder.id)
      }
    },
    goFolder() {
      if (this.isLinkedToStorePage) {
        this.routerPush({
          name: 'folders.store',
          params: {
            storeSlug: this.folder.store.translation.route
          }
        });
        return;
      }
      this.routerPush({
        name: 'folder.view',
        params: {
          storeSlug: this.folder.store.translation.route,
          folderDate: this.folder.valid_from,
        },
        query: {
          id: this.folder.id
        }
      });
    },
  }
}
</script>
