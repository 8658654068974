import store from '@/store';

export const facebook = {
    mounted() {
        new Promise(resolve => {
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: process.env.VUE_APP_FACEBOOK_ID,
                    cookie: true,
                    xfbml: true, // parse social plugins on this page
                    version: 'v9.0' // use graph api version 2.8
                });
            };
            (function (d, s, id) {
                const fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                const js = d.createElement(s);
                js.id = id;
                js.src = '//connect.facebook.net/en_US/sdk.js';
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        });
    },
    methods: {
        // Login methods
        facebookLogin() {
            if (navigator.userAgent.match(/FBAV/i)) {
                window.open('/login/facebook');
                return;
            }
            if (this.webview && typeof window.ReactNativeWebView != 'undefined') {
                this.facebookWebviewEvent();
            } else {
                this.facebookDefaultLogin();
            }
        },
        facebookDefaultLogin() {
            store.dispatch('loader/SET_LOADER', true);
            const facebookToken = window.FB.getAccessToken();
            if (facebookToken) {
                this.socialLogin('facebook', {access_token: facebookToken}); // from socialLogin mixin
            } else {
                window.FB.login((response) => {
                    this.socialLogin('facebook', {access_token: response.authResponse.accessToken}); // from socialLogin mixin
                });
            }
        },
        facebookWebviewEvent() {
            store.dispatch('loader/SET_LOADER', true);
            window.ReactNativeWebView.postMessage(JSON.stringify({
                type: 'true',
                actionType: 'loginFB',
            }));
        },
        // Register methods
        facebookRegister() {
            if (this.webview && typeof window.ReactNativeWebView != 'undefined') {
                this.facebookWebviewEvent();
            } else {
                this.facebookDefaultRegister();
            }
        },
        facebookDefaultRegister() {
            store.dispatch('loader/SET_LOADER', true);
            return window.FB.login(response => {
                this.checkUser('facebook', {access_token: _.get(response, 'authResponse.accessToken')});
            });
        },
    }
}
