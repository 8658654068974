<template>
  <default-grid :show-right-container="false" :show-left-container="false">
    <template v-slot:left-container>
      <left-bar show-user-menu></left-bar>
    </template>
    <template v-slot:main-container>
      <char-sorted-entities header="store.all_stores"
                            :data="STORES"
                            route-name="store.show"
                            route-param-name="storeSlug">
      </char-sorted-entities>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from '@/views/DefaultGrid';
import CharSortedEntities from '@/components/feed/CharSortedEntities';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'StoresView',
  components: {CharSortedEntities, DefaultGrid},
  computed: {
    ...mapGetters('store', [
      'STORES',
    ]),
  },
  mounted() {
    this.FETCH_STORES();
  },
  methods: {
    ...mapActions('store', [
      'FETCH_STORES',
    ])
  }
}
</script>
