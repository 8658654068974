const getDefaultState = () => {
  return {
    pages: [],
    promise: null,
    currentPage: null
  }
};

export default {
  namespaced: true,
  state: () => (getDefaultState()),

  getters: {
    PAGES(state) {
      return state.pages;
    },

    PAGES_LOADED(state) {
      return Object.keys(state.pages).length > 0;
    },

    CURRENT_PAGE(state) {
      return state.currentPage;
    },
  },

  actions: {
    FETCH_PAGES({ state, commit }) {
      if (state.promise) {
        return state.promise;
      }

      const promise = axios.get('/pages').then((response) => {
        commit('SET_PAGES', response.data.data);
      }).catch((e) => {
        console.log(e);
      }).then(() => {
        commit('UPDATE_PROMISE_PAGES', null);
      });

      commit('UPDATE_PROMISE_PAGES', promise);
      return promise;
    },

    FETCH_CURRENT_PAGE({state, commit, dispatch}, route) {
      return axios.get('/pages/' + route).then((response) => {
        commit('UPDATE_CURRENT_PAGE', response.data.data);
      }).catch((e) => {
        console.log(e);
      });
    },
  },

  mutations: {
    SET_PAGES(state, data) {
      return _.set(state, 'pages', data);
    },

    UPDATE_PROMISE_PAGES(state, data) {
      return state.promise = data;
    },

    UPDATE_CURRENT_PAGE(state, data) {
      return state.currentPage = data;
    },
  },
};
