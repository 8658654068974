import store from '@/store';

export const apple = {
    mounted() {
        if (!window.AppleID) {
            console.log('"https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js" needs to be included as a <script>');
            return;
        }

        window.AppleID.auth.init({
            clientId: process.env.VUE_APP_APPLE_CLIENT_ID,
            scope: 'name email',
            redirectURI: 'https://www.promojagers.be',
            // state: '[STATE]',
            usePopup: true //or false defaults to false
        });
    },
    methods: {
        appleLogin() {
            if (!window.AppleID) {
                return;
            }
            try {
                window.AppleID.auth.signIn().then((data) => {
                    this.socialLogin('apple', data.authorization); // from socialLogin mixin
                });
            } catch (error) {
                this.wrongCredentialsNotify(); // from socialLogin mixin
            }
        },
        appleRegister() {
            store.dispatch('loader/SET_LOADER', true);
            if (!window.AppleID) {
                return;
            }

            try {
                window.AppleID.auth.signIn().then((data) => {
                    this.checkUser('apple', data.authorization);
                });
            } catch (error) {
                this.wrongCredentialsNotify(); // from socialLogin mixin
            }
        },
    }
}
