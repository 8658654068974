<template>
  <div  ref="home_filter">
    <div v-if="Object.keys(filterOptions).length">
      <el-divider v-if="showDivider" class="bold-divider my-3"/>
      <div class="filter-header-container ms-2">
        <div class="filter-header" @click="openFilter">
          <span class="cursor-pointer" v-text="filterLabel.sorting_promo"></span>
          <i :class="filterOpened ? 'icon-chevron-thin-right rotate-90' : 'icon-chevron-thin-right  pt-2'"></i>
        </div>
        <div class="filter-options" v-if="filterOpened">
          <el-checkbox-group v-model="checkedList" @change="filterEvent">
            <div v-for="(filterOption, i) in filtersOptionList" :key="i">
              <el-checkbox :key="i" :label="filterOption.key" class="big">{{ filterOption.label }}</el-checkbox>
            </div>
          </el-checkbox-group>

          <div class="mt-3">
            <span class="hover:text-decoration-underline cursor-pointer"
                  v-text="shortFilters ? $t('filters.show_more') : $t('filters.show_less')"
                  @click="shortFilters = !shortFilters"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="my-3">
        <div class="mt-2" v-for="(listOption, i) in filterList" :key="i">
            <el-divider class="my-3 bold-divider"></el-divider>
            <div class="left-bar-button"
                  :class="{'filters-home-color': filterList[i].key === feedFilterLists}"
                  @click="filterEventList(i)">
              <div :key="i">
                {{ listOption.label }}
              </div>
            </div>
        </div>
        <div v-if="xsOnly">
          <el-divider class="my-3 bold-divider"></el-divider>
          <div class="height-empty-block"></div>
        </div>
    </div>
  </div>
</template>

<script>
import {needAuth} from '@/mixins/auth/needAuth';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'home-filter',
  mixins: [needAuth],
  props: {
    defaultChecked: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      filterKey: 'home_filter',
      shortFilters: true,
      shortLength: 4,
      checkedList: this.defaultChecked,
      filterLabel: {
        'sorting_promo': this.$t('post.home_filter.sorting_promo'),
      },
      filterLists: {
        'popular': this.$t('post.home_filter.popular'),
        // 'saving_tips': this.$t('post.home_filter.saving_tips'),
        'to_ask': this.$t('post.home_filter.to_ask'),
        'giveaways': this.$t('post.home_filter.giveaways'),
      }
    }
  },
  computed: {
    ...mapGetters('promo', [
      'PROMOS_TYPES',
      'PROMOS_MORPHABLE_TYPES',
      'FEED_TAB_COLOR',
    ]),
    ...mapGetters('type', [
      'TYPES',
    ]),
    feedFilterLists() {
      const currentTab = _.get(this.$route.query, 'tab', '');
      return  _.has(this.filterLists, currentTab) ? currentTab : ''; // popular,saving_tips,to_ask,giveaways
    },
    filterOptions() {
      let resultOptions = [];

      _.forEach(this.TYPES, function (type) {
        if (type.home_order > 0) {
          resultOptions.push({
            key: type.id,
            label: type.translation.title,
            home_order: type.home_order,
          });
        } else {
          _.forEach(type.children, function (type) {
            if (type.home_order > 0) {
              resultOptions.push({
                key: type.id,
                label: type.translation.title,
                home_order: type.home_order,
              });
            }
          });
        }
      });

      resultOptions = _.sortBy(resultOptions, ['home_order']);

      if (this.shortFilters) {
        resultOptions = _.take(resultOptions, this.shortLength);
      }

      const currentTab = _.get(this.$route.query, 'home_filter[]', '');
      if (currentTab !== '') {
        this.setCheckedList(_.map(currentTab, Number));
      }

      return resultOptions;
    },

    ...mapGetters('filter', [
      'OPENED_FILTERS',
      'ACTIVE_FILTERS',
      'FILTERS_ANIMATION'
    ]),
    showDivider() {
      return !(this.filterKey === 'home_filter' && this.ACTIVE_FILTERS.home_filter);
    },
    filtersOptionList() {
      if (_.isArray(this.filterOptions)) {
        return this.filterOptions;
      }

      const thisElem = this;
      const keys = Object.keys(this.filterOptions);
      const resultArray = [];
      _.forEach(keys, function (key) {
        resultArray.push({
          key: key,
          label: thisElem.filterOptions[key],
        });
      });

      return resultArray;
    },
    filterList() {
      if (_.isArray(this.filterLists)) {
        return this.filterLists;
      }

      const thisElem = this;
      const keys = Object.keys(this.filterLists);
      const resultArray = [];
      _.forEach(keys, function (key) {
        resultArray.push({
          key: key,
          label: thisElem.filterLists[key],
        });
      });

      return resultArray;
    },
    filterOpened() {
      return _.get(this.OPENED_FILTERS, this.filterKey, true);
    },
  },
  methods: {
    ...mapActions('filter', [
      'RESET_FILTERS',
      'SET_FILTER_VALUE',
      'SET_FILTER_OPENED',
      'SET_ANIMATION_INACTIVE',
    ]),
    ...mapActions('promo', [
      'FETCH_PROMOS',
      'RESET_PROMOS',
    ]),
    filterEventList(list) {
      const listKey = this.filterList[list].key;
      this.emitter.emit('current-tab-change', listKey);

      if (this.FILTERS_ANIMATION.home_filter) {
        this.SET_ANIMATION_INACTIVE('home_filter');
      }
      this.setCheckedList([]);

      let query = _.clone(this.$router.currentRoute.value.query);
      query = _.omit(query, ['tab']);
      query['home_filter[]'] = [];
      query['morphable_types_filter[]'] = [];
      query['tab'] = listKey;
      this.$router.replace({query: query});

      this.RESET_FILTERS(true);
    },

    filterEvent() {
      if (this.FILTERS_ANIMATION.home_filter) {
        this.SET_ANIMATION_INACTIVE('home_filter');
      }

      const currentTab = _.get(this.$route.query, 'tab', '');
      if (_.has(this.filterLists, currentTab)) {
        let query = _.clone(this.$router.currentRoute.value.query);
        query = _.omit(query, ['tab']);
        query['home_filter[]'] = this.checkedList;
        query['morphable_types_filter[]'] = 'Post';
        query['tab[]'] = [];
        this.$router.replace({query: query});
        this.emitter.emit('current-tab-change', 'default');
      }

      setTimeout(() => {
        this.SET_FILTER_VALUE({morphable_types_filter: ['Post'], home_filter: _.map(this.checkedList, Number)});
      }, 1000);
    },
    setCheckedList(values) {
      this.checkedList = values;
    },
    openFilter() {
      const filter = {};
      _.set(filter, this.filterKey, !this.filterOpened);
      this.SET_FILTER_OPENED(filter);
    },
  },
  watch: {
    currentScrollTop(newValue) {
      if (!this.isScrollingUp && this.FILTERS_ANIMATION.home_filter && newValue > (this.$refs.home_filter.clientHeight + 170)) {
        this.SET_ANIMATION_INACTIVE('home_filter');
      }
    }
  }
}
</script>
