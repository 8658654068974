<template>
  <el-row justify="center">
    <el-col :span="span" :class="colClass">
      <div class="container" :class="containerClass">
        <slot/>
      </div>
    </el-col>
  </el-row>
</template>

<script>

export default {
  name: 'default-space',
  props: {
    span: {
      type: Number,
      default: 23
    },
    colClass: {
      type: String,
      default: ''
    },
    containerClass: {
      type: String,
      default: ''
    }
  }
}
</script>
