export const dates = {
  methods: {
    /* eslint-disable prefer-const */
    dateStringFromTimestamp(timestamp) {
      if (!timestamp) {
        return '-';
      }

      let d = new Date(timestamp * 1000),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }

      return [day, month, year].join('/');
    },
    dateStringFromISO(iso, format = 'Y-m-d') {
      const date = new Date(iso);

      let day = date.getDate();
      let month = date.getMonth() + 1;
      const year = date.getFullYear();

      day = (day < 10) ? '0' + day : day;
      month = (month < 10) ? '0' + month : month;

      format = format.replace('Y', year);
      format = format.replace('m', month);
      format = format.replace('d', day);
      return format;
    },
  },
};
