<template>
  <div class="promos-carousel-container w-100" :class="childClass">
    <!--
      Scroll header
    -->
    <div class="promos-carousel-header" :class="headerClass">
      <slot name="header">
        <h3 class="promos-carousel-header__title" v-text="title"/>
      </slot>
    </div>

    <!--
      Navigation + Scrollbar
    -->
    <div class="navigation-line" ref="navigation_line">
      <div class="nav-buttons">
        <button
          v-show="isLeftArrowActive"
          class="float-start"
          ref="leftArrow"
          @click="scrollPrev"
        >
          <i class="icon-triangle-arrow icon-flipped"/>
        </button>

        <el-scrollbar
          :native="false"
          ref="scrollbar_ref"
          :min-size="40"
          v-loading="isLoading"
          @touchstart="handleTouchStart"
          @touchmove="handleTouchMove"
          @touchend="handleTouchEnd"
        >
          <div class="scrollbar-flex-content">
            <div v-for="(promo, i) in promos" :key="i" class="scrollbar-item" :style="previewItemContainerStyle">
              <post-mini
                v-if="'Post' === promo.morphable_type"
                :post="promo.data"
                :is-bang-promo="!!bangPromoCondition(i)"
                :vuex-module="vuexModules.post"
              />
              <folder-promo-mini
                v-else-if="'FolderItem' === promo.morphable_type"
                :folder-promo="promo.data"
                :is-bang-promo="!!bangPromoCondition(i)"
                :vuex-module="vuexModules.folder_promo"
              />
              <online-promo-mini
                v-else-if="'OnlinePromo' === promo.morphable_type"
                :online-promo="promo.data"
                :is-bang-promo="!!bangPromoCondition(i)"
                :vuex-module="vuexModules.online_promo"
              />
            </div>
            <div v-if="showLoadMore && promos.length" class="scrollbar-item" :style="previewItemContainerStyle">
              <div
                class="load-more-card"
                @click="$emit('more')"
                v-t="'favorites.more'"
              >
              </div>
            </div>
          </div>
        </el-scrollbar>

        <button
          v-show="isRightArrowActive"
          class="float-end"
          ref="rightArrow"
          @click="scrollNext"
        >
          <i class="icon-triangle-arrow"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FolderPromoMini from '@/components/feed/promo/FolderPromoMini.vue';
import PostMini from '@/components/feed/post/PostMini.vue';
import OnlinePromoMini from '@/components/feed/promo/OnlinePromoMini.vue';

export default {
  name: 'promos-carousel',
  emits: ['load', 'next', 'prev'],
  components: {OnlinePromoMini, PostMini, FolderPromoMini},
  props: {
    promos: Array,
    childClass: String,
    headerClass: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    customWidth: {
      type: String,
      default: '230px'
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showLoadMore: {
      type: Boolean,
      default: false,
    },
    bangPromoCondition: {
      type: Function,
      default() {
        return (i) => false
      }
    },
    vuexModules: {
      type: Object,
      default() {
        return {
          'post': 'promo',
          'online_promo': 'promo',
          'folder_promo': 'promo',
        }
      }
    },
  },
  data() {
    return {
      containerWidth: 0,
      currentScroll: 0,
      currentScrollPx: 0,
      loadingEmitted: false,
    }
  },
  computed: {
    /**
     * @returns {boolean}
     */
    isLeftArrowActive() {
      return this.currentScrollPx > 0
    },
    /**
     * @returns {boolean}
     */
    isRightArrowActive() {
      return this.currentScrollPx < this.maxScrollPx
    },
    /**
     * Pixels after visible zone
     * @returns {number}
     */
    maxScrollPx() {
      const cardsCount = this.promos.length + (this.showLoadMore ? 1 : 0)

      return cardsCount * this.widthInPx - this.containerWidth;
    },
    previewItemContainerStyle() {
      return {
        'width': this.widthInPx + 'px'
      }
    },
    widthInPx() {
      if (!this.containerWidth) {
        return;
      }

      let width = this.customWidth

      if (width.indexOf('%') !== -1) {
        width = Number(width.replace(/%/g, ''))

        return this.containerWidth * width / 100
      }

      width = width.replace(/[a-zA-Z]/g, '')

      return Number(width)
    },
    arrowWidth() {
      return this.xsOnly ? 23 : 28
    }
  },
  mounted() {
    new ResizeObserver(() => this.calculateContainerWidth()).observe(this.$refs.navigation_line);
  },
  methods: {
    handleTouchStart(event) {
      this.startX = event.touches[0].clientX;
    },
    handleTouchMove(event) {
      this.currentX = event.touches[0].clientX;
    },
    handleTouchEnd() {
      if (this.startX - this.currentX > 20) {
        this.scrollNext()
      } else if (this.currentX - this.startX > 20) {
        this.scrollPrev()
      }
    },
    calculateContainerWidth() {
      if (this.$refs.navigation_line) {
        this.containerWidth = this.$refs.navigation_line.clientWidth - this.arrowWidth;

        if (this.currentScroll !== 0) {
          this.containerWidth -= this.arrowWidth
        }
      }
    },
    scrollNext() {
      this.currentScroll++;
      this.$emit('next')
    },
    scrollPrev() {
      if (this.currentScroll > 0) {
        this.currentScroll--;
        this.$emit('prev')
      }
    },
  },
  watch: {
    maxScrollPx(value) {
      if (value) {
        this.$emit('rendered', {name: 'promos', maxScrollPx: value})
      }
    },
    currentScroll(value) {
      this.calculateContainerWidth()
      this.currentScrollPx = this.widthInPx * value;
      this.$refs.scrollbar_ref.setScrollLeft(this.currentScrollPx);
    },
    currentScrollPx(value) {
      if (value > this.maxScrollPx && !this.loadingEmitted) {
        this.$emit('load');
        this.loadingEmitted = true;
        setTimeout(() => {
          this.loadingEmitted = false;
        }, 3000);
      }
    }
  }
}
</script>