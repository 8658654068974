<template>
  <div :class="{'d-none': !(breadcrumb.length > 0)}">
    <default-space>
      <el-breadcrumb class="breadcrumb-header" :class="additionalBreadcrumbClass" separator="&#9654;">
        <el-breadcrumb-item v-for="(breadcrumbItem, i) in breadcrumb" :key="i">
          <span v-if="breadcrumbItem.disabled" class="breadcrumb-item__disabled">
            {{ breadcrumbItem.text }}
          </span>

          <router-localized-link v-else :to="{path: breadcrumbItem.href}">
            <span class="breadcrumb-item__active">
              {{ breadcrumbItem.text }}
            </span>
          </router-localized-link>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </default-space>
  </div>
</template>

<script>
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';
import DefaultSpace from '@/views/DefaultSpace.vue';
import {slug} from '@/mixins/slug';

export default {
  name: 'breadcrumbs',
  mixins: [slug],
  components: {DefaultSpace, RouterLocalizedLink},
  data() {
    return {
      breadcrumb: []
    }
  },
  computed: {
    additionalBreadcrumbClass() {
      if (this.$route.name) {
        return 'breadcrumb-for-' + _.kebabCase(this.$route.name);
      }
      return '';
    }
  },
  methods: {
    goTo(href) {
      this.routerPush({path: href})
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      if (typeof to.meta.breadcrumb === 'function') {
        this.breadcrumb = to.meta.breadcrumb.call(this, this.$route);
      } else {
        this.breadcrumb = to.meta.breadcrumb;
      }
    }
  }
}
</script>
