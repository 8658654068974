<template>
  <div>
    <div class="w-100 login-label" v-t="'auth.register_step.almost_all'"></div>

    <el-form :model="form"
             ref="form"
             :rules="rules">

      <div class="d-flex align-items-start justify-content-center">
        <div class="me-2">
          <el-form-item prop="is_subscribed">
            <el-checkbox v-model="form.is_subscribed" label="" class="big checkbox-overall-arrangement"></el-checkbox>
          </el-form-item>
        </div>
        <div class="mt-1 word-break-normal">
          <span v-t="'auth.register_step.subscribe_text'"></span>
        </div>
      </div>
      <div class="d-flex align-items-start justify-content-center mb-3">
        <div class="me-2">
          <el-form-item prop="terms">
            <el-checkbox v-model="form.terms" class="big checkbox-overall-arrangement"></el-checkbox>

            <template v-slot:error="{ error }">
              <div class="checkbox-validation-message color-primary">
                <span v-html="error"></span>
              </div>
            </template>
          </el-form-item>
        </div>
        <div class="mt-1 word-break-normal">
          <span v-t="'auth.register_step.accept_text'" class="pe-1"></span>
          <span class="text-decoration-underline color-primary cursor-pointer pe-1"
                v-t="'auth.register_step.privacy_policy'"
                @click="showPolicy"></span>
          <span class="pe-1">&</span>
          <span class="text-decoration-underline color-primary cursor-pointer"
                v-t="'auth.register_step.term_of_use'"
                @click="showTerms"></span>
        </div>
      </div>

      <el-button
        type="primary"
        class="w-100"
        @click="validate"
      >
        <span v-t="'auth.register_step.next_step'"/>
      </el-button>
    </el-form>
  </div>
</template>

<script>
import {validator} from '@/mixins/validator';
import validateStep from '@/mixins/auth/validateStep';

export default {
  name: 'register-step-second',
  mixins: [validator, validateStep],
  data() {
    return {
      step: 2,
      form: {
        terms: false,
        policy: true,
        is_subscribed: false
      },
      rules: {
        terms: [
          {validator: this.backendValidator}
        ],
        policy: [
          {validator: this.backendValidator}
        ],
        is_subscribed: [
          {validator: this.backendValidator}
        ]
      },
    }
  },
  methods: {
    showPolicy() {
      window.open(this.$router.resolve({path: '/privacy-policy'}).href, '_blank');
    },
    showTerms() {
      window.open(this.$router.resolve({path: '/terms'}).href, '_blank');
    },
  }
}
</script>
