export const promo_carousel_container = {
    data() {
        return {
            carouselHeight: 0,
            carouselWidth: 0,
        }
    },
    mounted() {
        new ResizeObserver(() => this.calculateHeight()).observe(this.$refs.promo_carousel_container);
    },
    methods: {
        calculateHeight() {
            if (!this.$refs.promo_carousel_container) {
                return 0;
            }

            this.carouselWidth = this.$refs.promo_carousel_container.clientWidth - 20;
            this.carouselHeight = 0;

            const thisElem = this;

            _.forEach(thisElem.images, function (image) {
                const currentImage = new Image();

                currentImage.src = image.src;
                currentImage.onload = function () {
                    let currentHeight = 0;
                    if (thisElem.carouselWidth < currentImage.width) {
                        currentHeight = thisElem.carouselWidth / currentImage.width * currentImage.height;
                    } else {
                        currentHeight = currentImage.height;
                    }
                    if (thisElem.carouselHeight < currentHeight) {
                        thisElem.carouselHeight = _.min([currentHeight, 500]);
                    }
                };
            });
        },
    },
};
