<template>
  <div class="store-logo">
    <img :src="CURRENT_BRAND.image_url"
         :title="CURRENT_BRAND.translation.title"
         alt="">
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'brand-logo',
  computed: {
    ...mapGetters('brand', [
      'CURRENT_BRAND',
    ]),
  },
}
</script>
