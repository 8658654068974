export const share = {
    methods: {
        shareLink(shareLink, destination) {
            switch (destination) {
                case 'facebook':
                    window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(shareLink));
                    break;
                case 'messenger':
                    if (this.isMobileDevice) {
                        window.open('fb-messenger://share/?link=' + encodeURIComponent(shareLink), 'messenger-share-dialog');
                    } else {
                        window.open('http://www.facebook.com/dialog/send?app_id=' + process.env.VUE_APP_FACEBOOK_ID +
                            '&link=' + encodeURIComponent(shareLink) +
                            '&redirect_uri=' + process.env.VUE_APP_URL);
                    }

                    // if (window.MessengerExtensions) {
                    //     // Use the Messenger sharing API to share the link in the Messenger app
                    //     MessengerExtensions.beginShareFlow(
                    //         function (shareResponse) {
                    //             alert('Shared successfully:');
                    //             console.log('Shared successfully:', shareResponse);
                    //         },
                    //         function (errorCode, errorMessage) {
                    //             alert('Error sharing:');
                    //             console.log('Error sharing:', errorCode, errorMessage);
                    //         },
                    //         {
                    //             'image_url': 'https://www.example.com/image.jpg',
                    //             'source': 'website',
                    //             'href': url,
                    //         },
                    //         'overlay'
                    //     );
                    // }

                    break;
                case 'whats-app':
                    if (this.isMobileDevice) {
                        window.open('whatsapp://send?text=' + encodeURIComponent(shareLink), 'whatsapp-share-dialog');
                    } else {
                        window.open('https://api.whatsapp.com/send?text=' + shareLink);
                    }

                    break;
                case 'email':
                    window.open('mailto:' +
                        '?subject=I wanted you to see this site' +
                        '&body=Check out this site ' + shareLink);
                    break;
                case 'copy-link':
                    this.$copyText(shareLink, undefined, (error, event) => {
                        // copied = true;
                        this.$notify.success({
                            title: this.$t('notifications.url_copied.title'),
                            message: this.$t('notifications.url_copied.message'),
                            position: this.notificationPosition,
                            duration: this.notificationDuration
                        });
                    });
                    break;
                case 'navigator':
                    window.navigator.share({
                        url: shareLink,
                    });
                    break;
                default:
                    break;
            }
        }
    }
};
