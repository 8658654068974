<template>
  <div class="inset-filter-container" ref="inset_filter_container">
    <inset-tabs @change="tabChange" :default="defaultTab" :change-query="true"></inset-tabs>
  </div>
</template>

<script>
  import InsetTabs from '@/components/feed/InsetTabs';
  
  export default {
    name: 'inset-filter',
    emits: ['tab-changed'],
    components: {InsetTabs},
    props: {
      defaultTab: {
        type: String,
        default: 'default'
      },
    },
    computed: {
      tabs() {
        const tabs = {};
        tabs['default'] = this.$t('search.home-tabs.default');
        tabs['last-ones'] = this.$t('search.home-tabs.last-ones');
        tabs['for-you'] = this.$t('search.home-tabs.for-you');
        return tabs;
      }
    },
    methods: {
      tabChange(tabKey) {
        this.$emit('tab-changed', tabKey);
      },
    },
  }
</script>
  