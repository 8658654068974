<template>
  <localized-link :to="link" v-if="$store.getters['loader/ROUTER_ACTIVE'] && !$store.getters['auth/GUEST']">
    <slot></slot>
  </localized-link>
  <a :href="link" v-else>
    <slot></slot>
  </a>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'router-localized-link',
  props: {
    to: Object,
    params: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters('auth', [
      'LOCALE',
    ]),
    query() {
      if (!Object.keys(this.params).length) {
        return ''
      }

      const query = Object.keys(this.params)
        .map((key) => {
          const value = this.params[key]
          const add = Array.isArray(value) ? '[]' : ''

          return `${key + add}=${value}`
        })
        .join('&');

      return '?' + query
    },
    link() {
      return this.$localizePath(this.$router.resolve(this.to).href, this.LOCALE) + this.query;
    }
  }
}
</script>
