<template>
  <div>
    <search-filter v-if="ACTIVE_FILTERS.search_filter" ref="search_filter"/>
    <reset-filter v-if="ACTIVE_FILTERS.reset_filter" @reset="reset"/>
    <types-filter v-if="ACTIVE_FILTERS.types_filter" ref="types_filter"/>
    <home-filter v-if="ACTIVE_FILTERS.home_filter" ref="home_filter"/>
    <promos-filter v-if="ACTIVE_FILTERS.promos_filter" ref="promos_filter"/>
    <categories-filter v-if="ACTIVE_FILTERS.categories_filter" ref="categories_filter"/>
    <webshops-filter v-if="ACTIVE_FILTERS.webshops_filter" ref="webshops_filter"/>
    <stores-filter v-if="ACTIVE_FILTERS.stores_filter" ref="stores_filter"/>
    <brands-filter v-if="ACTIVE_FILTERS.brands_filter" ref="brands_filter"/>
    <price-filter v-if="ACTIVE_FILTERS.price_filter" ref="price_filter"/>
    <favorite-filter v-if="ACTIVE_FILTERS.favorite_filter" ref="favorite_filter"/>
    <notifications-filter v-if="ACTIVE_FILTERS.notifications_filter"></notifications-filter>
  </div>
</template>

<script>
import PriceFilter from '@/components/left_bar/filters/PriceFilter';
import BrandsFilter from '@/components/left_bar/filters/BrandsFilter';
import StoresFilter from '@/components/left_bar/filters/StoresFilter';
import WebshopsFilter from '@/components/left_bar/filters/WebshopsFilter';
import PromosFilter from '@/components/left_bar/filters/PromosFilter';
import TypesFilter from '@/components/left_bar/filters/TypesFilter';
import SearchFilter from '@/components/left_bar/filters/SearchFilter';
import FavoriteFilter from '@/components/left_bar/filters/FavoriteFilter.vue';
import HomeFilter from '@/components/left_bar/filters/HomeFilter.vue';
import {mapGetters} from 'vuex';
import ResetFilter from '@/components/left_bar/filters/ResetFilter.vue';
import NotificationsFilter from '@/components/left_bar/filters/NotificationsFilter.vue';
import CategoriesFilter from '@/components/left_bar/filters/CategoriesFilter.vue';

export default {
  name: 'filters-list',
  components: {
    NotificationsFilter,
    CategoriesFilter,
    ResetFilter,
    PriceFilter,
    BrandsFilter,
    StoresFilter,
    WebshopsFilter,
    PromosFilter,
    TypesFilter,
    SearchFilter,
    FavoriteFilter,
    HomeFilter
  },
  computed: {
    ...mapGetters('filter', [
      'ACTIVE_FILTERS',
    ])
  },
  methods: {
    reset() {
      const refs = ['types_filter', 'home_filter', 'promos_filter', 'categories_filter', 'webshops_filter', 'stores_filter', 'brands_filter', 'favorite_filter']
      for (const ref of refs) {
        if (this.$refs[ref]) {
          this.$refs[ref].$refs.filter.checkedList = []
        }
      }
      this.$refs.price_filter.reset()
    }
  }
}
</script>
