<template>
  <base-filter
    ref="filter"
    :filter-label="$t('menu.user.favourites')"
    :filter-options= "LOCALE === 'nl' ? filterOptionsNl : filterOptionsFr"
    :default-checked="defaultChecked"
    filter-key="favorite_filter"
    custom-order
    hide-divider
    @filter="filter"
  />
</template>

<script>
import BaseFilter from '@/components/left_bar/filters/BaseFilter';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'favorite-filter',
  components: {BaseFilter},
  data() {
    return {
      filterOptionsNl: {
          'folders': this.$t('favorites.morphable_types_filter.folders'),
          'promos': this.$t('favorites.morphable_types_filter.folder_promos'),
          'articles': this.$t('favorites.morphable_types_filter.articles')
      },
      filterOptionsFr: {
        'folders': this.$t('favorites.morphable_types_filter.folders'),
        'promos': this.$t('favorites.morphable_types_filter.folder_promos')
      },
      filterItems: true,
      defaultChecked: []
    };
  },
  computed: {
    ...mapGetters('filter', [
      'FILTERS_VALUES',
    ]),
    ...mapGetters('auth', [
      'LOCALE',
    ]),
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_VALUE',
      'SET_FILTER_OPENED'
    ]),
    hideShow(){
      this.filterItems = !this.filterItems;
      this.SET_FILTER_OPENED({favorite_filter: this.filterItems});
    },
    filter(checked) {
      this.SET_FILTER_VALUE({favorite_filter: checked});
    }
  },
  watch: {
    FILTERS_VALUES: {
      // the callback will be called immediately after the start of the observation
      deep: true,
      handler(newVal, oldVal) {
        this.defaultChecked = newVal.favorite_filter;
      }
    }
  }
}
</script>