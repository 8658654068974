import store from '@/store';

export const socialLogin = {
    methods: {
        // Login with Promojagers API
        socialLogin(provider, options) {
            store.dispatch('loader/SET_LOADER', true);
            return this.$http.post(`/token/${provider}`, options).then((response) => {
                this.getUserByToken(response.data.data.token);
            }).catch((e) => {
                store.dispatch('loader/SET_LOADER', false);

                switch (_.get(e, 'response.data.error')) {
                    case 'unregistered':
                        this.emitter.emit('try-register', {provider: provider, options: options});
                        break;
                    default:
                        this.$notify.error({
                            title: this.$t('notifications.default.title'),
                            message: this.$t('notifications.default.message'),
                            position: this.notificationPosition,
                            duration: this.notificationDuration,
                        });
                        break;
                }
            });
        },

        // Set token and user (final login step)
        getUserByToken(token) {
            store.dispatch('auth/UPDATE_TOKEN', token).then(() => {
                // Now page is reloading after login. Don't need to fetch user here

                // store.dispatch('auth/FETCH_USER').then(() => {
                //     store.dispatch('loader/SET_LOADER', false);
                //     this.$emit('loggedIn');
                // });
            });
        },
        /* eslint-disable prefer-const */
        checkUser(provider, bodyParams) {
            store.dispatch('loader/SET_LOADER', true);
            return this.$http.post(`/register/social/${provider}/check`, bodyParams).then((response) => {
                if (_.get(response, 'data.registered', false)) {
                    this.emitter.emit('try-login', {provider: provider, options: bodyParams});
                    return;
                }
                store.dispatch('loader/SET_LOADER', false);

                let email = _.get(response, 'data.user.email', ''),
                    name = _.get(response, 'data.user.name'),
                    fname = '',
                    lname = '';

                // if name exists then extract first name and last name
                if (!_.isEmpty(name)) {
                    fname = name.split(' ').slice(0, -1).join(' ');
                    lname = name.split(' ').slice(-1).join(' ');
                }

                // Parse email name to extract first name and last name
                if (_.isEmpty(fname)) {
                    const ename = _.get(email.split('@'), '0');
                    const enames = ename.split(new RegExp('[\\.\\-_]', 'u'));
                    fname = _.get(enames, '0');
                    lname = _.join(_.drop(enames, 1), ' ');
                }

                if (_.isEmpty(lname)) {
                    lname = fname;
                }

                const userData = {
                    provider,
                    email: email,
                    fname: fname,
                    lname: lname,
                    cache_id: _.get(response, 'data.cache_id'),
                };

                this.$emit('validated', _.assign(bodyParams, userData));

            }).catch((e) => {
                store.dispatch('loader/SET_LOADER', false);
                this.$notify.error({
                    title: this.$t('notifications.default.title'),
                    message: _.get(e, 'response.data.error', this.$t('notifications.default.message')),
                    position: this.notificationPosition,
                    duration: this.notificationDuration
                });
            });
        },
        wrongCredentialsNotify() {
            this.$notify.error({
                title: this.$t('notifications.credentials.title'),
                message: this.$t('notifications.credentials.message'),
                position: this.notificationPosition,
                duration: this.notificationDuration,
            });
        }
    }
}
