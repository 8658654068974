<template>
  <div class="votes">
    <i
        :class="{
          'icon-like-3': !isLike,
          'icon-like-4 active': isLike,
          'like-animation': likeAnimation
        }"
        @mousedown="!isLike && (likeAnimation = true)"
        @animationend="likeAnimation = false"
        @click="$emit('like')"
    />
    <el-divider direction="vertical"/>
    <span>{{ rating }}</span>
    <el-divider direction="vertical"/>
    <i
      :class="{
        'icon-like-3 rotate-180': !isDislike,
        'icon-like-4 rotate-180 active': isDislike,
      }"
      @click="$emit('dislike')"
    />
  </div>
</template>

<script>

export default {
  name: 'votes',
  emits: ['like', 'dislike'],
  props: {
    isLike: {
      type: Boolean,
      default: false
    },
    isDislike: {
      type: Boolean,
      default: false
    },
    rating: Number,
  },
  data() {
    return {
      copiedLink: false,
      likeAnimation: false,
    }
  },
}
</script>
