export default {
    namespaced: true,
    state: {
        types: {
            all: [],
            promise: null
        },
    },

    getters: {
        TYPES(state) {
            return state.types.all;
        }
    },

    actions: {
        async FETCH_TYPES({ state, commit }) {
            if (state.types.promise) {
                return state.types.promise;
            }

            const promise = axios.get('/types').then((response) => {
                commit('UPDATE_TYPES', response.data.data);
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_TYPES', null);
            });

            commit('UPDATE_PROMISE_TYPES', promise);
            return promise;
        },
    },

    mutations: {
        UPDATE_TYPES(state, data) {
            return state.types.all = data;
        },

        UPDATE_PROMISE_TYPES(state, data) {
            return state.types.promise = data;
        }
    }
}
