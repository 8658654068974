import {mapMutations} from 'vuex';
import {validator} from '@/mixins/validator';

export const step = {
    mixins: [validator],
    emits: ['update'],
    props: {
        titleNumber: Number
    },
    methods: {
        /**
         * mapMutations
         */
        ...mapMutations('promo', {updateForm: 'UPDATE_FORM'}),
        /**
         * Error message
         * @param message
         */
        notify(message) {
            this.$notify.error({
                title: 'Validation Error',
                message: message,
                position: this.notificationPosition,
                duration: 4000,
            });
        },
    }
};
