import store from '@/store';

/**
 * How to use => $dialogs.show('dialog-name', 'tab-name')
 * @type {{data(): {$dialogs: {hide: function(*): *, show: function(*): (*|*[]), toggle: function(*): (*|*[]), closeAll: function(): void, closeExcept: function(*): *}}, methods: {[p: string]: ActionMethod}}}
 */
export default {
    created() {
        this.$dialogs = {
            /**
             * Show specific dialog
             * @param dialogName
             * @param tab
             * @returns {*|*[]}
             */
            show: (dialogName, tab = null) => this.$store.dispatch('dialogs/SHOW', {name: dialogName, tab: tab}),
            /**
             * Hide specific dialog
             * @param dialogName
             * @returns {*}
             */
            hide: (dialogName) => this.$store.dispatch('dialogs/HIDE', dialogName),
            /**
             * Toggle specific dialog
             * @param dialogName
             * @returns {*|*[]}
             */
            toggle: (dialogName) => this.$store.dispatch('dialogs/TOGGLE', {name: dialogName}),
            /**
             * Close dialogs
             * @returns {*}
             */
            closeAll: () => this.$store.dispatch('dialogs/CLOSE_ALL'),
            /**
             * Close dialogs except
             * @param dialogName
             * @returns {*}
             */
            closeExcept: (dialogName) => this.$store.dispatch('dialogs/CLOSE_EXCEPT', dialogName),
            /**
             * Close dialogs except
             * @param dialogName
             * @param tab
             * @returns {*}
             */
            changeTab: (dialogName, tab) => this.$store.dispatch('dialogs/CHANGE_TAB', {name: dialogName, tab}),
            /**
             * Show is dialog visible
             * @param dialogName
             * @returns {*|*[]}
             */
            isVisible: (dialogName) => store.getters['dialogs/IS_VISIBLE'](dialogName),
        }
    },
}
