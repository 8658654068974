<template>
  <div class="banner-block position-relative" ref="banner_block">
    <img class="profile-user-banner"
         :style="{'width': bannerWidth + `px`, 'height': bannerHeight + `px`}"
         :src="PROFILE.banner_url"
         alt="">

    <div class="upload-new-banner-block" v-if="thisIsMyProfile">
      <el-dropdown placement="bottom-end" trigger="click">
        <i class="icon-camera-2"></i>

        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="uploadVisible = true;">
              <i class="icon-camera-2 me-2"/>
              {{ $t('profile.upload_banner_btn') }}
            </el-dropdown-item>

            <el-divider class="my-1"/>

            <el-dropdown-item :disabled="!PROFILE.banner_url" @click="resetBanner">
              <i class="icon-delete me-2"/>
              {{ $t('profile.remove_banner_btn') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <!--                            <el-image :src="PROFILE.banner_url" class="profile-user-banner"></el-image>-->

    <el-dialog
      :title="$t('profile.upload_banner_btn')"
      v-model="uploadVisible"
      :show-close="true"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      :destroy-on-close="true"
      :lock-scroll="false"
      @opened="dialogOpened"
      custom-class="upload-banner-dialog primary-header-dialog w-100"
      center>

      <div class="centered" ref="banner_uploader">
        <el-upload
          class="banner-uploader"
          :action="imageUploadUrl"
          :headers="{'Accept':'application/json','Authorization':'Bearer ' + AUTH_TOKEN}"
          :show-file-list="false"
          :on-remove="handleImageRemove"
          :on-error="handleImageError"
          :on-change="handleImageOnChange"
          :style="{'width': bannerUploaderWidth + `px`, 'height': bannerUploaderHeight + `px`}"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar"/>
          <i v-else class="icon-camera-2"></i>
        </el-upload>

      </div>

      <template v-slot:footer>
        <div class="centered w-100 py-2">
          <el-button type="primary" @click="submitBanner">
            <span v-t="'profile.submit_banner'"></span>
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'profile-banner',
  props: {
    thisIsMyProfile: {
      type: Boolean,
      default: false
    },
  },
  emits: ['banner-changed'],
  data() {
    return {
      uploadVisible: false,
      imageUploadUrl: process.env.VUE_APP_API_URL + 'profile/upload-banner',
      imageUrl: '',
      imagePath: '',
      bannerTemplateWidth: 843, // This number can be changed if default banner image size changed
      bannerTemplateHeight: 198,// This number can be changed if default banner image size changed
      bannerWidth: 0,
      bannerHeight: 0,
      bannerUploaderWidth: 0,
      bannerUploaderHeight: 0,
    }
  },
  computed: {
    ...mapGetters('profile', [
      'PROFILE',
    ]),
    ...mapGetters('auth', [
      'GUEST',
      'USER',
      'AUTH_TOKEN',
    ]),

    imageError: false,
  },
  mounted() {
    const thisElem = this;
    new ResizeObserver(function () {
      if(thisElem.$refs.banner_block){
        thisElem.bannerWidth = thisElem.$refs.banner_block.clientWidth;
        thisElem.bannerHeight = thisElem.bannerWidth / thisElem.bannerTemplateWidth * thisElem.bannerTemplateHeight;
      }
    }).observe(this.$refs.banner_block);
  },
  methods: {
    ...mapActions('auth', [
      'UPDATE_USER',
    ]),
    ...mapActions('loader', [
      'SET_LOADER',
    ]),

    handleImageError(err, file) {
      const e = JSON.parse(err.message);

      this.$notify.error({
        title: this.$t('notifications.default.title'),
        message: _.get(e, 'errors.file.0', this.$t('notifications.default.message')),
        position: this.notificationPosition,
        duration: this.notificationDuration,
      });
    },
    handleImageOnChange(file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.imagePath = _.get(file, 'response.file');

    },
    handleImageRemove(file) {
      this.resetImage();
    },
    submitBanner() {
      this.SET_LOADER(true);
      this.UPDATE_USER({profile_banner: this.imagePath}).then((response) => {
        this.errors = {};
      }).catch((e) => {
        this.errors = e.response.data.errors;
      }).then(() => {
        this.SET_LOADER(false);
        this.uploadVisible = false;
        this.resetImage();
        this.$emit('banner-changed');
      });
    },
    resetBanner(){
      this.resetImage();
      this.submitBanner();
    },
    resetImage(){
      this.imageUrl = '';
      this.imagePath = '';
    },
    dialogOpened() {
      const thisElem = this;
      new ResizeObserver(function () {
        if (thisElem.$refs.banner_uploader) {
          thisElem.bannerUploaderWidth = thisElem.$refs.banner_uploader.clientWidth;
          thisElem.bannerUploaderHeight = thisElem.bannerUploaderWidth / thisElem.bannerTemplateWidth * thisElem.bannerTemplateHeight;
        }
      }).observe(thisElem.$refs.banner_uploader);
    }
  }
}
</script>
