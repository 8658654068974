<template>
  <div ref="carousel" class="articles-carousel-container w-100 position-relative"
       :class="{[childClass]: listType === 'carousel'}">
    <!--
      Scroll header
    -->
    <div :class="{[headerClass]: true, [childClass]: listType !== 'carousel', 'flex-column align-items-start':xsOnly}" ref="top_articles_header">
      <h3 class="header-label" v-text="title"/>

      <div class="d-flex justify-content-around align-items-center">
        <div class="list-type-switcher color-primary" v-if="showListTypeSwitcher">
          <i class="icon-list-vector" :class="{'active': listType === 'list'}" @click="changeListType('list')"/>
          <i
            class="icon-carousel-vector"
            :class="{'active': listType === 'carousel'}"
            @click="changeListType('carousel')"
          />
          <el-select v-model="form.selectFilter" class="articles-rounded-select" @change="filteredArticles">
            <el-option
              :key="0"
              :label="$t('news.recommended')"
              :value="0"
            />
            <el-option
              :key="1"
              :label="$t('folder.pager.next_week')"
              :value="1"
            />
          </el-select>
        </div>
      </div>
    </div>

    <!--
      Navigation + Scrollbar
    -->
    <div v-if="listType === 'carousel'" class="navigation-line" ref="top_articles_header">
      <div class="nav-buttons">
        <button
          v-if="isLeftArrowActive"
          class="float-start"
          ref="leftArrow"
          @click="scrollPrev"
          @keyup.left="scrollPrev"
          @keyup.right="focusRightArrow"
        >
          <i class="icon-triangle-arrow icon-flipped"/>
        </button>

        <el-scrollbar
          class="w-100"
          :native="false"
          ref="scrollbar_ref"
          :min-size="40"
          v-loading="isLoading"
          @touchstart="handleTouchStart"
          @touchmove="handleTouchMove"
          @touchend="handleTouchEnd"
          @scroll="scrolling"
        >
          <div class="scrollbar-flex-content">
            <div v-for="(article, i) in filteredArticles" :key="i" class="scrollbar-item">
              <news-preview :article="article" :layout="{col_lg: 24, col_xl: 24}"/>
            </div>
          </div>
        </el-scrollbar>

        <button
          v-if="isRightArrowActive"
          class="float-end"
          ref="rightArrow"
          @click="scrollNext"
          @keyup.right="scrollNext"
          @keyup.left="focusLeftArrow"
        >
          <i class="icon-triangle-arrow"/>
        </button>
      </div>
    </div>

    <!--
     Scrollbar
   -->
    <template v-if="listType === 'list'">
      <div class="d-flex justify-content-center">
        <div class="articles-list-container">
          <template v-for="(article, i) in filteredArticles" :key="i">
            <div class="active-list-item">
              <news-preview :article="article" :layout="{col_lg: 24, col_xl: 24}"/>
            </div>
          </template>
          <div class="active-list-item" v-if="showLoadMoreBlock && filteredArticles.length">
            <div
              class="load-more-block"
              @click="$emit('load')"
              v-t="'folder.load_more_folders'"
            >
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {dates} from '@/mixins/dates';
import NewsPreview from '@/components/feed/news/NewsPreview';

export default {
  name: 'articles-carousel',
  emits: ['load'],
  components: {NewsPreview},
  props: {
    headerClass: {
      type: String,
      default: 'articles-carousel-header',
    },
    title: String,
    articles: Array,
    childClass: String,
    isLoading: {
      type: Boolean,
      default: false,
    },
    customWidth: {
      type: String,
      default: '230px'
    },
    isLinkedToStorePage: {
      type: Boolean,
      default: false,
    },
    defaultListType: {
      type: String,
      default: 'carousel',
    },
    showListTypeSwitcher: {
      type: Boolean,
      default: false,
    },
    hideArrowsIfEnded: {
      type: Boolean,
      default: false,
    },
    showLoadMoreBlock: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [dates],
  data() {
    return {
      listType: this.defaultListType,
      containerWidth: 0,
      currentScroll: 0,
      currentScrollPx: 0,
      loadingEmitted: false,
      form: {
        selectFilter: 0,
      },
    }
  },
  computed: {
    /**
     * @returns {boolean}
     */
    isLeftArrowActive() {
      return this.currentScrollPx > 0
    },
    /**
     * @returns {boolean}
     */
    isRightArrowActive() {
      return this.currentScrollPx + 50 < this.maxScrollPx
    },
    /**
     * Pixels after visible zone
     * @returns {number}
     */
    maxScrollPx() {
      return this.articles.length * this.widthInPx - this.containerWidth;
    },
    /**
     * Style for article container
     * @returns {{width: string}}
     */
    previewItemContainerStyle() {
      return {
        'width': this.widthInPx + 'px'
      }
    },
    /**
     * Width of one article in px
     * @returns {number}
     */
    widthInPx() {
      let width = this.customWidth

      if (!this.containerWidth) {
        return;
      }

      if (width.indexOf('%') !== -1) {
        width = width.replace(/%/g, '')

        return this.containerWidth * Number(width) / 100
      }

      width = width.replace(/[a-zA-Z]/g, '')

      return Number(width)
    },
    /**
     * Clipping of articles ending this week
     * @returns {Object}
     */
    filteredArticles() {
      let filterArticles = this.articles;
      let dateSunday = new Date();
      dateSunday.setDate(dateSunday.getDate() + (1 + 6 - dateSunday.getDay()) % 6);
      dateSunday = this.dateStringFromISO(dateSunday, 'Y-m-d');

      if (this.form.selectFilter === 1) {
        filterArticles = _.filter(this.articles, function(o) { return o.valid_to > dateSunday; });
      }

      return filterArticles;
    },
  },
  mounted() {
    new ResizeObserver(() => this.calculateContainerWidth()).observe(this.$refs.top_articles_header);
    document.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleTouchStart(event) {
      this.startX = event.touches[0].clientX;
    },
    handleTouchMove(event) {
      this.currentX = event.touches[0].clientX;
    },
    handleTouchEnd() {
      if (this.startX - this.currentX > 50) {
        this.scrollNext()
      } else if (this.currentX - this.startX > 50) {
        this.scrollPrev()
      }
    },
    handleScroll() {
      const carousel = this.$refs.carousel;
      if (!carousel) {
        return
      }
      const rect = carousel.getBoundingClientRect();

      if ((rect.top <= window.innerHeight / 2) && (rect.bottom >= window.innerHeight / 2)) {
        this.focusRightArrow(false)
      }
    },
    scrolling(value) {
      this.currentScrollPx = value.scrollLeft;
    },
    calculateContainerWidth() {
      if (this.$refs.top_articles_header) {
        this.containerWidth = this.$refs.top_articles_header.clientWidth;
      }
    },
    scrollNext() {
      this.currentScroll++;
    },
    scrollPrev() {
      if (this.currentScroll > 0) {
        this.currentScroll--;
      }
    },
    focusRightArrow(scroll = true) {
      if (this.$refs.rightArrow) {
        this.$refs.rightArrow.focus()
      }
      if (scroll) {
        this.scrollNext()
      }
    },
    focusLeftArrow(scroll = true) {
      if (this.$refs.leftArrow) {
        this.$refs.leftArrow.focus()
      }
      if (scroll) {
        this.scrollPrev()
      }
    },
    changeListType(listType) {
      this.listType = listType

      if (listType === 'carousel') {
        this.$nextTick(() => this.focusRightArrow(false))
      }
    }
  },
  watch: {
    maxScrollPx(value) {
      if (value) {
        this.$emit('rendered', {name: 'articles', maxScrollPx: value})
      }
    },
    currentScroll(value) {
      this.currentScrollPx = this.widthInPx * value;
      this.$refs.scrollbar_ref.setScrollLeft(this.widthInPx * value);
    },
    currentScrollPx(value) {
      if (value > this.maxScrollPx && !this.loadingEmitted) {
        this.$emit('load');
        this.loadingEmitted = true;
        setTimeout(() => {
          this.loadingEmitted = false;
        }, 3000);
      }
    }
  }
}
</script>
