export const breakpoint = {
  get(path) {
    const breakpoint = sessionStorage.getItem('breakpoint');

    if (! breakpoint) {
      return;
    }

    const obj = JSON.parse(breakpoint)

    if (obj.path !== path) {
      return;
    }

    return obj;
  },
  set(path, data) {
    sessionStorage.setItem('breakpoint', JSON.stringify({path, ...data}));
  },
  remove() {
    sessionStorage.removeItem('breakpoint');
  },
};
