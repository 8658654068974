import store from '@/store';

export const routing = {
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    currentPageSlug(){
      if (this.$route.name !== 'not_found') {
        return false;
      }
      const route = _.get(this.$route.params, 'pathMatch[0]', false);
      if (!route) {
        return false;
      }
      const pageObject = _.find(store.getters['page/PAGES'], function (need) {
        return need.route === route && need.locale.slug === store.getters['auth/LOCALE'];
      });
      if (!pageObject) {
        return false;
      }
      return pageObject.slug;
    }
  },
  methods: {
    routerPush(body) {
      const needForceReload = this.$router.resolve(body).href === this.$route.path;
      this.$router.push(body).then(() => {
        if (needForceReload) {
          store.dispatch('loader/FORCE_ROUTE_RELOAD');
        }
      });
    },
    getPagePathBySlug(slug) {
      const page = _.find(store.getters['page/PAGES'], function (page) {
        return page.slug === slug && store.getters['auth/LOCALE'] === page.locale.slug;
      });
      if (page === undefined) {
        return {path: '/'};
      }
      return {path:  '/' + page.route};
    },
    goPageBySlug(slug) {
      const page = _.find(store.getters['page/PAGES'], function (page) {
        return page.slug === slug && store.getters['auth/LOCALE'] === page.locale.slug;
      });
      if (page === undefined) {
        return;
      }
      this.routerPush({path: page.route});
    }
  },
};
