<template>
  <div>
    <div class="w-100 login-label" v-t="'auth.register_step.last_step_sso'"/>
    <el-form
      :model="form"
      label-position="top"
      ref="form"
      :rules="rules"
    >
      <birthday-picker :date="form.birthday" @input="birthdayChanged"/>

      <el-form-item prop="postal_code" :label="$t('auth.register_step.post_code')">
        <el-input
          class="rounded-input"
          :placeholder="$t('auth.register_step.post_code_placeholder')"
          v-model="form.postal_code"
        />
      </el-form-item>

      <el-button
        type="primary"
        class="w-100"
        @click="validate"
      >
        <span v-t="'auth.register_step.complete_registration_v2'"/>
      </el-button>
    </el-form>
  </div>
</template>

<script>
import {validator} from '@/mixins/validator';
import BirthdayPicker from '@/components/header/auth/Fields/BirthdayPicker.vue';
import validateStep from '@/mixins/auth/validateStep';

export default {
  name: 'register-step-third',
  components: {BirthdayPicker},
  mixins: [validator, validateStep],
  data() {
    return {
      step: 4,
      form: {
        birthday: '',
        postal_code: ''
      },
      rules: {
        birthday: [
          {validator: this.backendValidator}
        ],
        postal_code: [
          {validator: this.backendValidator}
        ]
      }
    }
  },
  methods: {
    birthdayChanged(value) {
      this.form.birthday = value;
    },
  }
}
</script>
