<template>
  <div>
    <div @click="show">
      <slot></slot>
    </div>
    <el-dialog
      custom-class="profile-preview-dialog"
      width="100vw"
      v-model="showProfilePreview"
      :show-close="false"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      :lock-scroll="false"
      center
    >
      <i
        class="icon-close-4 cursor-pointer hover:color-primary position-absolute"
        style="top: 15px; right: 15px; z-index: 10"
        @click="showProfilePreview = false"
      />

      <div class="mt-3 profile-page" v-if="PROFILE.id">
        <el-row type="flex">
          <el-col :span="24">
            <div class="main-inner-block">
              <div class="account-block">
                <div class="account-part">
                  <div class="upper-part">
                    <div class="account-header">
                      <div class="avatar-block">
                        <div class="inner-wrapper">
                          <el-avatar :src="PROFILE.avatar_url" shape="circle" class="profile-user-avatar"/>
                        </div>
                      </div>

                      <div class="social-block">
                        <a v-if="PROFILE.metadata.facebook" target="_blank"
                           :href="PROFILE.metadata.facebook" class="facebook-link">
                          <i class="icon-Facebook1 font-weight-bold"></i>
                        </a>

                        <a v-if="PROFILE.metadata.instagram" target="_blank"
                           :href="PROFILE.metadata.instagram" class="instagram-link">
                          <i class="icon-Insta font-weight-bold"></i>
                        </a>
                      </div>

                      <div class="side-bar-item-title text-black font-weight-medium">{{ PROFILE.full_name }}</div>
                    </div>
                    <profile-banner :this-is-my-profile="false"/>
                  </div>

                  <div class="profile-actions mt-2">
                    <el-button v-if="!PROFILE.is_following && thisIsMyProfile" :type="thisIsMyProfile ? 'danger' : 'primary'"
                               :plain="thisIsMyProfile"
                               :disabled="thisIsMyProfile"
                               class="d-block action-button action-button-my-profile"
                               @click="followUser">
                      <i class="icon-bell"/>&nbsp;
                      <span v-t="'profile.follow_btn'"/>
                    </el-button>

                    <el-button v-else-if="!PROFILE.is_following && !thisIsMyProfile" :type="thisIsMyProfile ? 'danger' : 'primary'"
                               :plain="thisIsMyProfile"
                               :disabled="thisIsMyProfile"
                               class="d-block action-button action-button-follow-btn"
                               @click="followUser">
                      <i class="icon-bell"/>&nbsp;
                      <span v-t="'profile.follow_btn'"/>
                    </el-button>

                    <el-button v-else type="primary-border"
                               plain
                               class="d-block action-button action-button-unfollow-btn"
                               @click="unfollowUser">
                      <i class="icon-bell-3"/>&nbsp;
                      <span v-t="'profile.unfollow_btn'"/>
                    </el-button>
                  </div>

                  <div class="account-footer">
                    <div class="achievements-list">
                      <div class="achievement-item">
                        <div class="image-wrapper">
                          <div class="achievement-icon">
                            <i class="icon-star"></i>
                          </div>
                        </div>
                        <div class="break"></div>
                        <div class="text-block">
                          <div class="title" v-t="'profile.achievements.rank'"></div>
                          <div class="number">
                             <span v-if="PROFILE.rank > 0">
                               #{{ PROFILE.rank }}
                             </span>
                            <span v-else>
                              /
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="achievement-item">
                        <div class="image-wrapper">
                          <div class="achievement-icon">
                            <i class="icon-coconut"></i>
                          </div>
                        </div>
                        <div class="break"></div>
                        <div class="text-block">
                          <div class="title" v-t="'profile.achievements.followers_count'"></div>
                          <div class="number">{{ PROFILE.followers_count }}</div>
                        </div>
                      </div>
                      <div class="achievement-item">
                        <div class="image-wrapper">
                          <div class="achievement-icon">
                            <i class="icon-island"></i>
                          </div>
                        </div>
                        <div class="break"></div>
                        <div class="text-block">
                          <div class="title" v-t="'profile.achievements.member_since'"></div>
                          <div class="number">{{ PROFILE.member_since }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row type="flex">
          <el-col :span="24">
            <div class="w-100 centered mb-2">
              <el-button
                type="primary"
                class="w-100 d-block action-button mx-4"
                @click="goProfilePage"
              >
                {{ $t('profile.open_profile') }}
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ProfileBanner from '@/components/feed/profile/ProfileBanner';

export default {
  name: 'profile-preview-dialog',
  components: {ProfileBanner},
  props: {
    profileId: {
      type: Number,
    },
    compact: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showProfilePreview: false,
    }
  },
  computed: {
    ...mapGetters('auth', [
      'USER',
      'GUEST'
    ]),
    ...mapGetters('profile', [
      'PROFILE',
      'FOLLOWERS',
    ]),

    profileLoaded() {
      return _.get(this.PROFILE, 'id', false);
    },

    thisIsMyProfile() {
      return _.get(this.PROFILE, 'id', -1) === _.get(this.USER, 'id', -2)
    }
  },
  methods: {
    ...mapActions('profile', [
      'FETCH_PROFILE',
      'FOLLOW_PROFILE',
      'UNFOLLOW_PROFILE',
    ]),
    show() {
      if (this.GUEST) {
        return;
      }

      this.showProfilePreview = !this.showProfilePreview
    },
    followUser() {
      this.FOLLOW_PROFILE(this.profileId);
    },
    unfollowUser() {
      this.UNFOLLOW_PROFILE(this.profileId);
    },
    loadProfile() {
      return this.FETCH_PROFILE({userId: this.profileId, isCompact: this.compact});
    },
    goProfilePage() {
      this.routerPush({name: 'profile', params: {userId: String(this.profileId)}});
    }
  },
  watch: {
    showProfilePreview(newValue, oldValue) {
      if (newValue) {
        this.loadProfile();
      }
    }
  }
}
</script>
