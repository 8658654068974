const getDefaultState = () => {
  return {
    bookmarks: {
      all: [],
      promise: null,
    },
  };
};

export default {
  namespaced: true,
  state: () => getDefaultState(),

  getters: {
    BOOKMARKS(state) {
      return state.bookmarks.all;
    },
    BOOKMARKS_PROMISE(state) {
      return state.bookmarks.promise;
    },
  },

  actions: {
    FETCH_BOOKMARKS({ state, commit, dispatch }) {
      axios.get('/bookmarks').then((response) => {
        commit('UPDATE_BOOKMARKS', response.data.data);
      });
    },

    CREATE_BOOKMARK({ state, commit }, form) {
      return axios.post('/bookmarks', form).then((response) => {
        commit('PUSH_BOOKMARK', response.data.data);
      });
    },

    DELETE_BOOKMARK({ state, commit }, id) {
      return axios.delete(`/bookmarks/${id}`).then((response) => {
        commit('REMOVE_BOOKMARK', id);
      });
    },

    RESET_BOOKMARKS({ commit }) {
      commit('SET_DEFAULT_DATA');
    },
  },

  mutations: {
    UPDATE_BOOKMARKS(state, bookmarks) {
      return (state.bookmarks.all = bookmarks);
    },

    PUSH_BOOKMARK(state, bookmark) {
      return state.bookmarks.all.push(bookmark);
    },

    REMOVE_BOOKMARK(state, id) {
      _.remove(state.bookmarks.all, function (item) {
        return item.id === id;
      });
    },

    SET_DEFAULT_DATA(state) {
      return _.assign(state, getDefaultState());
    },
  },
};
