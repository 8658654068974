import constants from '@/plugins/consts';

const getDefaultState = () => {
    return {
        stores: {
            all: [],
            promise: null
        },
        currentStore: null
    }
};

export default {
    namespaced: true,
    state: () => (getDefaultState()),

    getters: {
        STORES(state) {
            return state.stores.all;
        },
        STORES_WITH_FOLDERS(state) {
            return _.sortBy(_.filter(state.stores.all, function (need) {
                return need.folders_count > 0;
            }), 'folders_count').reverse();
        },
        STORES_LOADED(state) {
            return Object.keys(state.stores.all).length > 0;
        },
        CURRENT_STORE(state) {
            return state.currentStore;
        },
        CURRENT_STORE_IS_PAID_CLIENT(state) {
            return constants.FOLDER_CLIENT_TYPES.FOLDER_CLIENT_TYPE_PAID === state.currentStore.folder_client_type;
        },
        CURRENT_STORE_IS_SEMI_PAID_CLIENT(state) {
            return constants.FOLDER_CLIENT_TYPES.FOLDER_CLIENT_TYPE_SEMI_PAID === state.currentStore.folder_client_type;
        },
        CURRENT_STORE_IS_FREE_CLIENT(state) {
            return constants.FOLDER_CLIENT_TYPES.FOLDER_CLIENT_TYPE_FREE === state.currentStore.folder_client_type;
        },
    },

    actions: {
        async FETCH_STORES({state, commit}) {
            if (state.stores.promise) {
                return state.stores.promise;
            }

            const promise = axios.get('/stores').then((response) => {
                commit('UPDATE_STORES', response.data.data);
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_STORES', null);
            });

            commit('UPDATE_PROMISE_STORES', promise);
            return promise;
        },
        async FETCH_CURRENT_STORE({state, commit, dispatch}, route) {
            return axios.get('/store/' + route).then((response) => {
                commit('UPDATE_CURRENT_STORE', response.data.data);
            }).catch((e) => {
                console.log(e);
            });
        },
        RESET_STORES({commit}) {
            commit('SET_DEFAULT_DATA');
        },
        SET_SUBSCRIPTION({state, commit}, {id, ...data}) {
            return axios.put(`/stores/${id}/subscribe`, data).then((response) => {
                commit('UPDATE_CURRENT_STORE', response.data.data);
            });
        },
        REMOVE_SUBSCRIPTION({state, commit}, id) {
            return axios.put(`/stores/${id}/unsubscribe`).then((response) => {
                commit('UPDATE_CURRENT_STORE', response.data.data);
            });
        },
    },

    mutations: {
        UPDATE_STORES(state, data) {
            return state.stores.all = data;
        },

        UPDATE_PROMISE_STORES(state, data) {
            return state.stores.promise = data;
        },

        UPDATE_CURRENT_STORE(state, data) {
            return state.currentStore = data;
        },

        SET_DEFAULT_DATA(state) {
            return _.assign(state, getDefaultState());
        },
    }
}
