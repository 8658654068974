<script setup>
import {useRoute} from 'vue-router';
import {useHead} from '@vueuse/head';
import {computed} from 'vue';
import store from '@/store';

const route = useRoute();

const title = computed(() => {
  const result = _.get(route, 'meta.title', store.getters['auth/I18N'].t('general.default_meta.title'));
  if (typeof result === 'function') {
    return result.call(this);
  }
  return result;
});

const description = computed(() => {
  const result = _.get(route, 'meta.description', store.getters['auth/I18N'].t('general.default_meta.description'));
  if (typeof result === 'function') {
    return result.call(this);
  }
  return result;
});

const robotsContent = computed(() => {
  if (_.get(route, 'name') === 'home' && !_.isEmpty(route.query.post)) {
    return 'noindex, nofollow';
  }
  if (['online_promos.show', 'folder_promos.show', 'profile'].includes(_.get(route, 'name'))) {
    return 'noindex, nofollow';
  }
  return 'index';
});

useHead({
  title: title,
  meta: [
    {
      name: 'title',
      content: title,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      name: 'robots',
      content: robotsContent,
    }
  ],
})
// end block
</script>

<template>
  <slot/>
</template>
