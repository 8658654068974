<template>
  <div class="news-carousel">
    <!--
      Navigation + Scrollbar
    -->
    <div class="news-carousel__navigation-line">
      <div class="nav-buttons">
        <button
          class="float-start"
          :class="{'invisible': !isLeftArrowActive}"
          ref="leftArrow"
          @click="movePrev"
        >
          <i class="icon-triangle-arrow icon-flipped"/>
        </button>

        <el-scrollbar
          :native="false"
          ref="scrollbar_ref"
          :min-size="40"
          @scroll="scrolling"
          @touchstart="handleTouchStart"
          @touchmove="handleTouchMove"
          @touchend="handleTouchEnd"
        >
          <div class="scrollbar-flex-content" ref="navigation_line">
            <template v-if="xsOnly || isMobileDevice">
              <div v-for="(article, i) in news" :key="i" class="scrollbar-item" :style="previewItemContainerStyle">
                <article-block :article="article"/>
              </div>
            </template>
            <template v-else>
              <div
                v-for="(article, i) in chunkedNews"
                :key="i" class="scrollbar-item"
                :style="previewItemContainerStyle"
              >
                <div class="w-50 pe-1">
                  <article-block v-if="0 in article" :article="article[0]" text-class="py-4"/>
                </div>
                <div class="w-50 ps-1 d-flex flex-column gap-2">
                  <article-block v-if="1 in article" :article="article[1]" class="h-50" text-class="py-2.25"/>
                  <article-block v-if="2 in article" :article="article[2]" class="h-50" text-class="py-2.25"/>
                </div>
              </div>
            </template>
          </div>
        </el-scrollbar>

        <button
          class="float-end"
          :class="{'invisible': !isRightArrowActive}"
          ref="rightArrow"
          @click="moveNext"
        >
          <i class="icon-triangle-arrow"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleBlock from '@/components/feed/news/Article.vue';

export default {
  name: 'news-carousel',
  components: {ArticleBlock},
  props: {
    news: Array,
    customWidth: {
      type: String,
      default: '100%'
    },
  },
  data() {
    return {
      containerWidth: 0,
      currentScroll: 0,
      currentScrollPx: 0,
      loadingEmitted: false,
      notScrolled: true
    }
  },
  computed: {
    chunkedNews() {
      const result = []
      for (let i = 0; i < this.news.length; i += 3)
        result.push(this.news.slice(i, i + 3))

      return result
    },
    /**
     * @returns {boolean}
     */
    isLeftArrowActive() {
      return this.currentScrollPx > 0
    },
    /**
     * @returns {boolean}
     */
    isRightArrowActive() {
      return this.currentScrollPx < this.maxScrollPx
    },
    /**
     * Pixels after visible zone
     * @returns {number}
     */
    maxScrollPx() {
      return (this.xsOnly ? this.news.length : this.news.length / 3) * this.widthInPx - this.containerWidth;
    },
    /**
     * Style for folder container
     * @returns {{'min-width': string}}
     */
    previewItemContainerStyle() {
      return {
        'min-width': this.widthInPx + 'px'
      }
    },
    /**
     * Width of one folder in px
     */
    widthInPx() {
      if (!this.containerWidth) {
        return;
      }

      let width = this.customWidth;

      if (width.indexOf('%') !== -1) {
        width = width.replace(/%/g, '')

        return this.containerWidth * Number(width) / 100
      }

      width = width.replace(/[a-zA-Z]/g, '')

      return Number(width)
    },
  },
  mounted() {
    new ResizeObserver(() => this.calculateContainerWidth()).observe(this.$refs.navigation_line);

    setTimeout(() => this.autoScrolling(), 3000)
  },
  methods: {
    /**
     * Auto scrolling
     */
    autoScrolling() {
      setTimeout(() => {
        if (!this.notScrolled) {
          return
        }

        if (this.currentScrollPx >= this.maxScrollPx) {
          this.scrollPrev()
        } else {
          this.scrollNext()
        }

        this.autoScrolling()
      }, 5000)
    },
    handleTouchStart(event) {
      this.startX = event.touches[0].clientX;
    },
    handleTouchMove(event) {
      this.currentX = event.touches[0].clientX;
    },
    handleTouchEnd() {
      if (this.startX - this.currentX > 20) {
        this.moveNext()
      } else if (this.currentX - this.startX > 20) {
        this.movePrev()
      }
    },
    scrolling(value) {
      this.currentScrollPx = value.scrollLeft;
    },
    calculateContainerWidth() {
      if (this.$refs.navigation_line) {
        this.containerWidth = this.$refs.navigation_line.clientWidth;
      }
    },
    moveNext() {
      this.notScrolled = false
      this.scrollNext()
    },
    movePrev() {
      this.notScrolled = false
      this.scrollPrev()
    },
    scrollNext() {
      this.currentScroll++;
      this.$emit('next')
    },
    scrollPrev() {
      if (this.currentScroll > 0) {
        this.currentScroll--;
        this.$emit('prev')
      }
    },
  },
  watch: {
    currentScroll(value) {
      this.currentScrollPx = this.widthInPx * value;
      this.$refs.scrollbar_ref.setScrollLeft(this.currentScrollPx);
    },
    currentScrollPx(value) {
      if (value > this.maxScrollPx && !this.loadingEmitted) {
        this.$emit('load');
        this.loadingEmitted = true;
        setTimeout(() => {
          this.loadingEmitted = false;
        }, 3000);
      }
    }
  }
}
</script>
