import {breakpoint} from '@/mixins/public_spa_js/breakpoint';

/**
 * Global scripts for guest version
 */
export default {
  mounted() {
    this.$guest = {
      breakpoint: {
        set: (page) => breakpoint.set(page, {pos: window.scrollY}),
        remove: () => breakpoint.remove(),
      },
      filters: {
        open: () => document.querySelector('.filters-menu').style.display = 'block',
        hide: () => document.querySelector('.filters-menu').style.display = 'none',
      },
      query: {
        addQueryParameter: (name, value) => {
          const currentUrl = window.location.href;
          const url = new URL(currentUrl);
          url.searchParams.set(name, value);

          window.location.href = url.toString();
        }
      }
    }
  }
};
