export default {
    namespaced: true,
    state: {
        prizes: [],
        prize: {},
    },

    getters: {
        PRIZES(state) {
            return state.prizes;
        },
        PRIZE(state) {
            return state.prize;
        },
    },

    actions: {
        FETCH_PRIZES({state, commit}) {
            return axios.get('/prizes').then((response) => {
                commit('UPDATE_PRIZES', response.data.data);
            });
        },

        FETCH_PRIZE({state, commit}, prizeId = 0) {
            return axios.get('/prizes/' + prizeId,).then((response) => {
                commit('UPDATE_PRIZE', response.data.data);
            });
        },
    },

    mutations: {
        UPDATE_PRIZES(state, prizes) {
            return (state.prizes = prizes);
        },
        UPDATE_PRIZE(state, prize) {
            return (state.prize = prize);
        },
    }
}
