<template>
  <folder-pager></folder-pager>
</template>

<script>
import FolderPager from '@/components/feed/folder/FolderPager';
import {mapActions} from 'vuex';
import FolderRoute from '@/router/guards/FolderRoute';
import StoreRoute from '@/router/guards/StoreRoute';

export default {
  name: 'FolderView',
  components: {FolderPager},
  // need this because Guards doesnt work when the same route params changing
  async beforeRouteUpdate(to, from, next) {
    if (from.params.storeSlug !== to.params.storeSlug) {
      await StoreRoute(to, from, next);
    }

    if (from.query.id !== to.query.id) {
      await FolderRoute(to, from, next);
    }

    next();
  },
  beforeMount() {
    this.RESET_PROMOS();
  },
  methods: {
    ...mapActions('promo', [
      'RESET_PROMOS',
    ]),
  }
}
</script>
