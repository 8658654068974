<template>
  <default-grid :show-left-container="false" :show-right-container="false">
    <template v-slot:main-container>
      <div class="feed-container prize-pool-feed">
        <bonus-prizes></bonus-prizes>
      </div>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from '@/views/DefaultGrid'
import BonusPrizes from '@/components/feed/bonuses/BonusPrizes'

export default {
  name: 'PrizePoolView',
  components: {
    DefaultGrid,
    BonusPrizes
  }
}
</script>
