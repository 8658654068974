<template>
  <div class="d-flex align-items-center justify-content-between" v-if="total > 0">
    <div>
      <span class="text-secondary cursor-pointer" @click="showMore" v-if="hiddenTotal > 0">
        {{ (hiddenTotal === 1)? $t('comment.show_more_one_comment', {count: hiddenTotal}) : $t('comment.show_more', {count: hiddenTotal}) }}
      </span>
    </div>

    <span v-if="totalVisible" class="font-weight-bold">
      {{(total === 1)? $t('comment.one_comment', {count: total}) : $t('comment.total_comments', {count: total}) }}
    </span>
  </div>
</template>

<script>
import {needAuth} from '@/mixins/auth/needAuth';

export default {
  name: 'comments-header',
  mixins: [needAuth],
  emits: ['show-more'],
  props: {
    total: {
      type: Number,
      default: 0,
    },
    totalVisible: {
      type: Boolean,
      default: true,
    },
    hiddenTotal: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    showMore() {
      this.webshopLoginDialog();
      if (this.triggerLoginDialog()) {
        return;
      }
      this.$emit('show-more');
    }
  }
}
</script>
