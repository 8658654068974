export default {
    namespaced: true,
    state: {
        profile: {},
    },

    getters: {
        PROFILE(state) {
            return state.profile;
        },
        FOLLOWERS(state) {
            return _.get(state, 'profile.followers', []);
        },
        FOLLOWING(state) {
            return _.get(state, 'profile.following', []);
        },
    },

    actions: {
        async FETCH_PROFILE({state, commit}, {userId, isCompact}) {
            const url = `/profile/${userId}`;

            return await axios.get(url, {params: {isCompact}}).then((response) => {
                commit('SET_PROFILE', response.data.data);
            });
        },
        async FOLLOW_PROFILE({state, commit}, userId) {
            const url = '/profile/' + userId + '/follow'

            return await axios.get(url).then((response) => {
                commit('SET_PROFILE', response.data.data);
            });
        },
        async UNFOLLOW_PROFILE({state, commit}, userId) {
            const url = '/profile/' + userId + '/unfollow'

            return await axios.get(url).then((response) => {
                commit('SET_PROFILE', response.data.data);
            });
        },
    },

    mutations: {
        SET_PROFILE(state, profile) {
            return (state.profile = profile);
        },
    }
}
