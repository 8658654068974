<template>
  <div v-if="loading"
       class="comments-loading"
       v-loading="true"
       element-loading-background="rgba(0, 0, 0, 0)">
  </div>
</template>

<script>
export default {
  name: 'comments-loading',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.comments-loading {
  min-height: 50px;
}
</style>