<template>
  <el-carousel style="border-radius: 8px 8px 0 0;" :height="height + 'px'" :arrow="arrow">
    <el-carousel-item v-for="(image, i) in images" :data="image" :key="i">
      <div class="promo-image-carousel-item">
        <el-image
            :style="`max-width: ${width}px;height: ${height}px`"
            :src="image.src"
            :zoom-rate="1.2"
            :initial-index="9999"
            :preview-src-list="[image.src]"
            preview-teleported
            hide-on-click-modal
            fit="cover"
        />
      </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  name: 'promo-image-carousel',
  props: {
    images: {
      default: [],
    },
    height: Number,
    width: Number,
  },
  computed: {
    arrow() {
      return this.images.length === 1 ? 'never' : 'always';
    },
  }
}
</script>
