<template>
  <el-form
    ref="form"
    :model="form"
    class="post-step__container"
  >
    <el-input class="post-step__title" disabled :placeholder="titlePlaceholder"/>

    <el-form-item prop="images">
      <el-upload
        class="w-100"
        :action="imageUploadUrl"
        :headers="headers"
        :on-remove="handleImageRemove"
        :on-error="handleImageError"
        :on-change="handleImageOnChange"
        :limit="4"
        ref="upload"
        list-type="picture"
        @paste="pasteEvent"
        drag
      >
        <el-icon class="el-icon--upload">
          <i class="icon-picture text-black font-size-30"/>
        </el-icon>
        <div class="el-upload__text">
          {{ $t('post.add_photo') }}
        </div>
        <input class="paste-textarea" type="text">
      </el-upload>
    </el-form-item>
  </el-form>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {step} from '@/mixins/post/step';

export default {
  name: 'UploadImageStep',
  mixins: [step],
  computed: {
    ...mapGetters('auth', ['AUTH_TOKEN']),
    ...mapState('promo', {
      type: ({form}) => form.type,
      form: ({form}) => form,
    }),
    /**
     * Headers
     * @returns {{Authorization: string, Accept: string}}
     */
    headers() {
      return {'Accept': 'application/json', 'Authorization': 'Bearer ' + this.AUTH_TOKEN}
    },
    /**
     * Title placeholder
     * @returns {string}
     */
    titlePlaceholder() {
      return `${this.titleNumber}. ${this.$t('post.optional') + this.$t('post.steps.add_photo')}`
    }
  },
  data() {
    return {
      rules: {
        images: [
          {}
        ]
      },
      imageUploadUrl: process.env.VUE_APP_API_URL + 'posts/upload-image',
    }
  },
  mounted() {
    document.querySelector('.paste-textarea').focus()
  },
  methods: {
    /**
     * Paste event
     * @param event
     */
    pasteEvent(event) {
      if (this.form.images.length > 3) {
        return;
      }

      const items = event.clipboardData.items;
      for (const index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          const imageFromClipboard = item.getAsFile();
          this.$refs.upload.handleStart(imageFromClipboard);
          break;
        }
      }
    },
    /**
     * Handle error
     * @param err
     * @param file
     * @param files
     */
    handleImageError(err, file, files) {
      const e = JSON.parse(err.message);

      this.$notify.error({
        title: this.$t('notifications.default.title'),
        message: _.get(e, 'errors.file.0', this.$t('notifications.default.message')),
        position: this.notificationPosition,
        duration: this.notificationDuration,
      });
    },
    /**
     * On change
     * @param file
     * @param files
     */
    handleImageOnChange(file, files) {
      const newFiles = [];
      _.forEach(files, (file) => {
        if (_.get(file, 'response.file')) {
          newFiles.push({
            name: file.name,
            url: URL.createObjectURL(file.raw),
            path: _.get(file, 'response.file'),
          });
        } else {
          // When image is uploading with copy-paste feature
          const formData = new FormData();
          formData.append('file', file.raw);
          axios.post('/posts/upload-image', formData).then((response) => {
            newFiles.push({
              name: file.name,
              url: URL.createObjectURL(file.raw),
              path: _.get(response, 'data.file'),
            });
          });
        }
      });

      this.updateForm({images: newFiles});
    },
    /**
     * handleImageRemove
     * @param file
     * @param fileList
     */
    handleImageRemove(file, fileList) {
      this.updateForm({images: _.toArray(fileList)});
    },
    /**
     * Reset images
     */
    reset() {
      this.$refs.upload.clearFiles();
    },
    /**
     * Validation
     * @returns {boolean}
     */
    async validate() {
      let result = false;

      await this.$refs.form.validate((isValid) => result = isValid)

      this.$emit('update', {titlesCount: 1})

      return result
    }
  },
}
</script>
