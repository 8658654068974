<template>
  <div class="landing-view">
    <default-space>
      <hero-section/>
    </default-space>

    <promos-section/>

    <popular-folders-section/>

    <new-promos-section/>

    <banner-section/>

    <folder-announcement-section/>

    <final-chance-section/>

    <news-section/>

    <email-subscription-section/>

    <copied-discount-code-dialog></copied-discount-code-dialog>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {needAuth} from '@/mixins/auth/needAuth';
import HeroSection from '@/views/feed/sections/HeroSection.vue';
import PromosSection from '@/views/feed/sections/PromosSection.vue';
import PopularFoldersSection from '@/views/feed/sections/PopularFoldersSection.vue';
import NewPromosSection from '@/views/feed/sections/NewPromosSection.vue';
import BannerSection from '@/views/feed/sections/BannerSection.vue';
import FolderAnnouncementSection from '@/views/feed/sections/FolderAnnouncementSection.vue';
import FinalChanceSection from '@/views/feed/sections/FinalChanceSection.vue';
import NewsSection from '@/views/feed/sections/NewsSection.vue';
import DefaultSpace from '@/views/DefaultSpace.vue';
import EmailSubscriptionSection from '@/views/feed/sections/EmailSubscriptionSection.vue';
import CopiedDiscountCodeDialog from '@/components/feed/post/discount/CopiedDiscountCodeDialog.vue';

export default {
  name: 'LandingView',
  components: {
    CopiedDiscountCodeDialog,
    EmailSubscriptionSection,
    DefaultSpace,
    NewsSection,
    FinalChanceSection,
    FolderAnnouncementSection,
    BannerSection,
    NewPromosSection,
    PopularFoldersSection,
    PromosSection,
    HeroSection
  },
  mixins: [needAuth, CopiedDiscountCodeDialog],
  mounted() {
    this.SET_FILTER_ACTIVE('ch_filter');
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_ACTIVE',
      'SET_FILTER_INACTIVE',
      'SET_FILTER_OPENED',
      'RESET_FILTERS',
    ]),
  },
}
</script>
