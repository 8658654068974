<template>
  <default-grid :show-right-container="false" :show-left-container="false">
    <template v-slot:main-container>
      <div class="notifications-feed">
        <h1 class="notifications-header">
          {{ $t('notifications.title') }}
        </h1>

        <tabs ref="tab" :default="'all'" :tabs="tabs" @change="tabChange"/>

        <el-row :class="{'px-2': xsOnly}" type="flex" justify="start">
          <notifications-listing
            :class="{'mb-4': NEW_NOTIFICATIONS.length}"
            title="notifications.sections.new"
            :notificationFilter="currentTab"
            :notifications="NEW_NOTIFICATIONS"
            @delete="DELETE_NEW_NOTIFICATIONS"
            @deleteAll="deleteAllNewNotifications"
            @open="clickNewNotification"
            @load="loadNewNotifications"
          />
          <notifications-listing
            title="notifications.sections.old"
            :notificationFilter="currentTab"
            :notifications="OLD_NOTIFICATIONS"
            @delete="DELETE_OLD_NOTIFICATIONS"
            @deleteAll="deleteAllOldNotifications"
            @open="clickOldNotification"
            @load="loadOldNotifications"
          />
        </el-row>
      </div>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from '@/views/DefaultGrid';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import Tabs from '@/components/feed/Tabs';
import NotificationsListing from '@/views/feed/NotificationsListing.vue';

export default {
  name: 'NotificationsView',
  components: {
    NotificationsListing,
    Tabs,
    DefaultGrid,
  },
  data() {
    return {
      currentTab: 'all',
      fetchOptions: {
        read: 1,
        unread: 1,
      }
    }
  },
  computed: {
    ...mapGetters('auth', [
      'USER',
      'GUEST',
    ]),
    ...mapGetters('filter', [
      'FILTERS_VALUES',
    ]),
    ...mapGetters('new-notifications', {
      NEW_NOTIFICATIONS: 'NOTIFICATIONS',
      NEW_NOTIFICATION_PROMISE: 'NOTIFICATION_PROMISE',
      NEW_NOTIFICATIONS_IS_COMPLETE: 'NOTIFICATIONS_IS_COMPLETE'
    }),
    ...mapGetters('old-notifications', {
      OLD_NOTIFICATIONS: 'NOTIFICATIONS',
      OLD_NOTIFICATION_PROMISE: 'NOTIFICATION_PROMISE',
      OLD_NOTIFICATIONS_IS_COMPLETE: 'NOTIFICATIONS_IS_COMPLETE'
    }),

    tabs() {
      const tabs = {};
      tabs['all'] = this.$t('notifications.tabs.all');
      tabs['unread'] = this.$t('notifications.tabs.unread');
      tabs['read'] = this.$t('notifications.tabs.read');

      return tabs;
    },
  },
  mounted() {
    this.prepareFetchOptions()
    this.loadNotifications()
  },
  methods: {
    ...mapActions('new-notifications', {
      FETCH_NEW_NOTIFICATIONS: 'FETCH_NOTIFICATIONS',
      READ_NEW_NOTIFICATIONS: 'READ_NOTIFICATION',
      DELETE_NEW_NOTIFICATIONS: 'DELETE_NOTIFICATION',
      DELETE_ALL_NEW_NOTIFICATIONS: 'DELETE_ALL_NOTIFICATIONS',
      RESET_NEW_NOTIFICATIONS: 'RESET_NOTIFICATIONS'
    }),
    ...mapActions('old-notifications', {
      FETCH_OLD_NOTIFICATIONS: 'FETCH_NOTIFICATIONS',
      READ_OLD_NOTIFICATIONS: 'READ_NOTIFICATION',
      DELETE_OLD_NOTIFICATIONS: 'DELETE_NOTIFICATION',
      DELETE_ALL_OLD_NOTIFICATIONS: 'DELETE_ALL_NOTIFICATIONS',
      RESET_OLD_NOTIFICATIONS: 'RESET_NOTIFICATIONS'
    }),
    ...mapMutations('new-notifications', {
      REMOVE_NEW_NOTIFICATION: 'REMOVE_NOTIFICATION',
    }),
    ...mapMutations('old-notifications', {
      REMOVE_OLD_NOTIFICATION: 'REMOVE_NOTIFICATION',
    }),
    ...mapActions('filter', [
      'SET_FILTER_INACTIVE'
    ]),
    prepareFetchOptions() {
      this.SET_FILTER_INACTIVE('notifications_filter');

      switch (this.currentTab) {
        case 'all':
          this.fetchOptions.read = 1;
          this.fetchOptions.unread = 1;
          break;
        case 'read':
          this.fetchOptions.read = 1;
          this.fetchOptions.unread = 0;
          break;
        case 'unread':
          this.fetchOptions.read = 0;
          this.fetchOptions.unread = 1;
          break;
      }
    },
    loadNotifications() {
      this.loadNewNotifications()
      this.loadOldNotifications()
    },
    loadNewNotifications(state) {
      this.FETCH_NEW_NOTIFICATIONS({...this.fetchOptions, gt: 2})
        .then(() => {
          if (this.NEW_NOTIFICATIONS_IS_COMPLETE) {
            if (state) {
              state.complete();
            }
          } else {
            state.loaded();
          }
        });
    },
    loadOldNotifications(state) {
      this.FETCH_OLD_NOTIFICATIONS({...this.fetchOptions, lt: 2})
        .then(() => {
          if (this.OLD_NOTIFICATIONS_IS_COMPLETE) {
            if (state) {
              state.complete();
            }
          } else {
            state.loaded();
          }
        });
    },
    tabChange(tabKey) {
      this.currentTab = tabKey;
      this.RESET_OLD_NOTIFICATIONS()
      this.RESET_NEW_NOTIFICATIONS()
      this.prepareFetchOptions();
      this.loadNotifications();
    },
    deleteAllNewNotifications() {
      this.DELETE_ALL_NEW_NOTIFICATIONS(`?gt=2&status=${this.currentTab}`)
    },
    deleteAllOldNotifications() {
      this.DELETE_ALL_OLD_NOTIFICATIONS(`?lt=2&status=${this.currentTab}`)
    },
    /**
     * @param notification
     */
    clickNewNotification(notification) {
      if (!notification.read_at) {
        this.READ_NEW_NOTIFICATIONS(notification.id).then(() => {
          if (this.currentTab === 'unread') {
            this.REMOVE_NEW_NOTIFICATION(notification.id)
          }
        })
      }

      this.openNotification(notification)
    },
    /**
     * @param notification
     */
    clickOldNotification(notification) {
      if (!notification.read_at) {
        this.READ_OLD_NOTIFICATIONS(notification.id).then(() => {
          if (this.currentTab === 'unread') {
            this.REMOVE_OLD_NOTIFICATION(notification.id)
          }
        })
      }

      this.openNotification(notification)
    },
    /**
     * Open notification
     * @param notification
     */
    openNotification(notification) {
      if (notification.is_link && notification.url) {
        window.open(notification.url, '_self');
      }
    }
  },
  watch: {
    FILTERS_VALUES: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal.notifications_filter.length) {
          this.$refs.tab.changeTab(newVal.notifications_filter[0])
        }
      }
    }
  }
}
</script>
