<template>
  <default-grid>
    <template v-slot:main-container>
      <div class="feed-container">
        <inset-filter @tab-changed="tabChange" :default-tab="currentTab"/>

        <call-to-action @click="$dialogs.show('create-post')"/>

        <div class="home-feed">
          <el-row type="flex" justify="start">
            <template v-for="(promo, indexes) in promos" :key="indexes">
              <el-col :span="24">
                <post
                  v-if="'Post' === promo.morphable_type"
                  :post="promo.data"
                />
                <online-promo
                  v-if="'OnlinePromo' === promo.morphable_type"
                  :online-promo="promo.data"
                />
                <folder-promo
                    v-if="'FolderItem' === promo.morphable_type"
                    :folder-promo="promo.data"
                />
                <folder
                    v-if="'Folder' === promo.morphable_type"
                    :folder="promo.data"
                />
                <div v-if="indexesToShowTopFolders.includes(indexes)">
                  <top-folders
                      :title="$t('folder.top_folders')"
                      :folders="POPULAR_FOLDERS"
                      :is-loading="!!POPULAR_FOLDERS_PROMISE"
                  />
                </div>
              </el-col>
            </template>
          </el-row>

          <infinite-loading @infinite="load" :key="currentTab">
            <template #spinner>
              <div class="spinner-container" v-loading="true"/>
            </template>
            <template #complete>
              <div></div>
            </template>
            <template #error>
              <div></div>
            </template>
          </infinite-loading>
        </div>
      </div>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from '@/views/DefaultGrid';
import {mapActions, mapGetters} from 'vuex';
import InsetFilter from '@/components/feed/InsetFilter';
import Post from '@/components/feed/post/Post';
import FolderPromo from '@/components/feed/promo/FolderPromo';
import OnlinePromo from '@/components/feed/promo/OnlinePromo';
import InfiniteLoading from 'v3-infinite-loading';
import {useHead} from '@vueuse/head';
import {needAuth} from '@/mixins/auth/needAuth';
import CallToAction from '@/components/feed/CallToAction.vue';
import Folder from '@/components/feed/folder/Folder';
import TopFolders from '@/components/feed/carousels/TopFolders.vue';

export default {
  name: 'HomeView',
  components: {
    Folder,
    CallToAction,
    DefaultGrid,
    InsetFilter,
    Post,
    FolderPromo,
    OnlinePromo,
    InfiniteLoading,
    TopFolders
  },
  mixins: [needAuth],
  data() {
    return {
      currentTab: _.get(this.$route.query, 'tab', 'default'),
      fetchOptions: {
        post: _.get(this.$route.query, 'post', ''),
        online_promo: _.get(this.$route.query, 'online-promo', ''),
        folder_item: _.get(this.$route.query, 'folder-item', ''),
        include_filters: ['types', 'morphable_types', 'categories', 'stores'],
        morphable_types: [],
        types: [],
        categories: [],
        stores: [],

        order: '',
        folders_included: 0,
        draft: _.get(this.$route.query, 'draft', 0),
        only_favourites: '',
        published_not_later: '',
      },
      fetchTopOptions: {
        include_filters: ['types', 'morphable_types', 'categories', 'stores'],
        morphable_types: ['OnlinePromo', 'FolderItem'],
        categories: [],
        stores: [],
        page: 0,
        per_page: 5,
        order: 'rating_count',
        only_valid_promos: 1,
        published_not_later: 7,
      },
      topPromosInsertions: [
        {min: 2, max: 15},
        {min: 20, max: 30},
        {min: 35, max: 45},
        {min: 50, max: 60},
        {min: 65, max: 75}
      ],
      indexesToShowTopFolders: [],
      randomValueFirst: Math.floor(Math.random() * 9 + 2),
      randomValueFurther: Math.floor(Math.random() * 36)
    }
  },
  computed: {
    ...mapGetters('promo', [
      'PROMOS',
      'PROMOS_IS_COMPLETE',
    ]),
    ...mapGetters('promos-top', {
      TOP_PROMOS: 'PROMOS',
      TOP_PROMOS_IS_COMPLETE: 'PROMOS_IS_COMPLETE',
    }),
    ...mapGetters('filter', [
      'FILTERS_VALUES',
    ]),
    ...mapGetters('folder-popular', {
      POPULAR_FOLDERS: 'FOLDERS',
    }),
    /**
     *
     * @returns {{length}|*|*[]}
     */
    promos() {
      if (!this.TOP_PROMOS.length && !this.PROMOS.length) {
        return []
      }

      let items = this.PROMOS
      const thisElem = this

      _.forEach(this.topPromosInsertions, function (r, key) {
        if (thisElem.PROMOS.length > r.min
                && typeof thisElem.TOP_PROMOS[key] !== 'undefined'
                && thisElem.currentTab !== 'to_ask'
                && thisElem.currentTab !== 'giveaways') {
          r.rand = r.rand ?? _.random(r.min, r.max)
          items = thisElem.insert(items, r.rand, thisElem.TOP_PROMOS[key])
        }
      })
      return items
    },

    useDefaultFetchOptions() {
      return this.fetchOptions.post !== '' || this.fetchOptions.online_promo !== '' || this.fetchOptions.folder_item !== ''
    }
  },
  // need this because Guards doesn't work when the same route params changing
  async beforeRouteUpdate(to, from) {
    if (to.query.post !== from.query.post){
      this.fetchOptions.post = to.query.post;
      this.prepareFetchOptions();
      this.RESET_PROMOS();
      this.loadPromos();
    }
  },
  created() {
    useHead({
      // Can be static or computed
      link: [
        {
          rel: 'canonical',
          href: new URL(this.$route.path, window.location.origin).href
        },
      ],
    })
  },
  beforeMount() {
    this.RESET_PROMOS();
    this.RESET_TOP_PROMOS()
    this.RESET_FILTERS();

    this.prepareFetchOptions();
    this.loadPromos();
    this.loadTopPromos();
  },
  beforeUnmount() {
    this.RESET_FILTERS();
  },
  mounted() {
    this.loadFoldersData();
    this.generateIndexesToShowTopFolders();
    this.emitter.on('current-tab-change', (tabKey) => {
      this.currentTabChange(tabKey);
    });
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_ACTIVE',
      'SET_FILTER_INACTIVE',
      'SET_FILTER_OPENED',
      'RESET_FILTERS',
    ]),
    ...mapActions('promo', [
      'FETCH_PROMOS',
      'RESET_PROMOS',
    ]),
    ...mapActions('promos-top', {
      FETCH_TOP_PROMOS: 'FETCH_PROMOS',
      RESET_TOP_PROMOS: 'RESET_PROMOS',
    }),
    /**
     * Insert element in array
     * @param arr
     * @param index
     * @param newItem
     * @returns {*[]}
     */
    insert(arr, index, newItem) {
      return [
        ...arr.slice(0, index), newItem, ...arr.slice(index)
      ]
    },
    async loadFoldersData() {
      const module = 'folder-popular';

      const moduleParams = {
        'folder-popular': {
          folder_client_types: ['1'],
        },
      };
      const params = moduleParams[module];
      return this.$store.dispatch(module + '/FETCH_FOLDERS', params);
    },

    generateIndexesToShowTopFolders() {
      //Random display of a block of folders in the range 2-10 35-70 70-105 ... 1/35.

      this.indexesToShowTopFolders.push(Math.floor(Math.random() * 9 + 2));

      const randomIncrement = Math.floor(Math.random() * 36);
      for (let i = 1; i < 30; i++) {
        this.indexesToShowTopFolders.push(i * 35 + randomIncrement);
      }
    },

    prepareFetchOptions() {
      this.SET_FILTER_ACTIVE('home_filter');

      this.fetchOptions.order = '';
      this.fetchOptions.folders_included = 0;
      this.fetchOptions.only_favourites = '';
      this.fetchOptions.published_not_later = '';
      this.fetchOptions.type_kind = '';
      this.fetchOptions.q = '';

      if (this.useDefaultFetchOptions) {
        return;
      }

      this.fetchOptions.types = [];
      this.fetchOptions.types = this.FILTERS_VALUES.home_filter;
      this.fetchOptions.morphable_types = ['Post']

      switch (this.currentTab) {
        case 'last-created':
          this.fetchOptions.folders_included = 1;
          this.fetchOptions.order = 'published_at';
          break;
        case 'more-hits':
          this.fetchOptions.order = 'hits_count';
          this.fetchOptions.published_not_later = 7;
          break;
        case 'more-comments':
          this.fetchOptions.order = 'comments_count';
          this.fetchOptions.published_not_later = 7;
          break;
        case 'favourites':
          this.fetchOptions.order = 'favourite_at';
          this.fetchOptions.only_favourites = 1;
          this.fetchOptions.folders_included = 1;
          break;
        case 'last-ones':
          //Sort by least likes and comments in the last 7 days
          this.fetchOptions.order = 'published_at';
          this.fetchOptions.published_not_later = 7;
          break;
        case 'for-you':
          //Not in use yet.
          // console.log(this.currentTab);
          // this.fetchOptions.order = 'for_you';
          break;
        case 'popular':
          //Sort by most likes and comments in the last 3 days
          this.fetchOptions.order = 'hits_count';
          this.fetchOptions.published_not_later = 3;
          break;
        case 'to_ask':
          // this.fetchOptions.types.push(this.$consts.FILTER_OPTIONS.TO_ASK);
          this.fetchOptions.type_kind = 3;
          break;
        case 'saving_tips':
          //Not in use yet.
          // console.log('Home saving_tips');
          break;
        case 'giveaways':
          this.fetchOptions.order = 'published_at';
          this.fetchOptions.q = 'giveaway';
          break;
        case 'default':
        default:
          this.fetchOptions.folders_included = 1;
          this.fetchOptions.order = 'year_month_commented_at';
          break;
      }
    },

    tabChange(tabKey) {
      this.currentTab = tabKey;
      this.prepareFetchOptions();
      this.RESET_PROMOS();
      this.loadPromos();
    },

    currentTabChange(tabKey) {
      this.currentTab = tabKey;
    },

    loadPromos() {
      return this.FETCH_PROMOS(this.fetchOptions);
    },

    loadTopPromos() {
      return this.FETCH_TOP_PROMOS(this.fetchTopOptions);
    },

    load(state) {
      //Show login dialog modal instead of loading
      if (_.size(this.PROMOS) && this.triggerLoginDialog()) {
        state.complete();
        return;
      }

      this.loadPromos().then(() => {
        if (this.PROMOS_IS_COMPLETE) {
          state.complete();
        } else {
          state.loaded();
        }
      });
    }
  },

  watch: {
    FILTERS_VALUES: {
      // the callback will be called immediately after the start of the observation
      deep: true,
      handler(newVal, oldVal) {
        this.prepareFetchOptions();
        this.RESET_PROMOS();
        this.loadPromos();
      }
    }
  }
}
</script>
