import store from '@/store';

export const needAuth = {
  methods: {
    triggerLoginDialog() {
      if (store.getters['auth/GUEST']) {
        window.open(this.$router.resolve({name: 'login'}).href, '_self');
      }
      return store.getters['auth/GUEST'];
    },
    webshopLoginDialog(){
      const webshopRoute = _.get(this.$router.currentRoute.value, 'name', 'webshop.show')

      if (!_.isEmpty(webshopRoute) && this.$store.getters['auth/GUEST']) {
        this.emitter.emit('copy-discount-code', {
          data: {
            discount_url: _.get(this.$store.getters['webshop/CURRENT_WEBSHOP'], 'translation.affiliate_url'),
            discount_code: 'webshop'
          }
        })
      }
    }
  },
};
