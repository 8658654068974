<template>
  <el-avatar shape="circle"
             class="comment-author-avatar facebook-avatar-generated">
    {{ firstChar + secondChar }}
  </el-avatar>
</template>

<script>
export default {
  name: 'facebook-avatar',
  props: ['uniqueId'],
  data() {
    return {
      firstChar: 'A',
      secondChar: 'A',
      bgColor: '#1a4971',
    }
  },
  mounted() {
    const allString = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    this.firstChar = allString[(this.uniqueId.slice(-2) % 26)];
    this.secondChar = allString[(this.uniqueId.slice(-4, -2) % 26)];
    const colors = [
      '#1a4971',
      '#2368a2',
      '#3183c8',
      '#5f6b7a',
      '#8895a7',
      '#b8c4ce',
      '#1b655e',
      '#2a9187',
      '#3caea3',
      '#197741',
      '#259d58',
      '#38c172',
      '#8c6d1f',
      '#caa53d',
      '#f4ca64',
      '#891b1b',
      '#b82020',
      '#dc3030',
    ];
    this.bgColor = colors[(this.uniqueId.slice(-2) % 18)];
  }
}
</script>

<style scoped>
.facebook-avatar-generated {
  background-color: v-bind(bgColor);
}
</style>