const getDefaultState = () => {
    return {
        articles: {
            params: {
                page: 1,
                per_page: 14,
            },
            promise: null,
            is_complete: false,
            all: [],
            meta: {}
        },
        current_article: {
            data: {},
            promise: null,
        },
        top_articles: {
            most_recently: [],
            last_week: [],
            last_year: [],
        }
    }
};

export default {
    namespaced: true,
    state: () => (getDefaultState()),

    getters: {
        CURRENT_ARTICLE(state) {
            return state.current_article.data;
        },

        ARTICLES(state) {
            return state.articles.all;
        },

        ARTICLES_PROMISE(state) {
            return state.articles.promise;
        },

        ARTICLES_TOTAL(state) {
            return _.get(state, 'articles.meta.total');
        },

        ARTICLES_CURRENT_PAGE(state) {
            return _.get(state, 'articles.meta.current_page');
        },

        ARTICLES_PER_PAGE(state) {
            return state.articles.params.per_page;
        },

        ARTICLES_IS_COMPLETE(state) {
            return state.articles.is_complete;
        },

        MOST_RECENTLY(state) {
            return _.get(state, 'top_articles.most_recently', []);
        },

        LAST_WEEK(state) {
            return _.get(state, 'top_articles.last_week', []);
        },

        LAST_MONTH(state) {
            return _.get(state, 'top_articles.last_month', []);
        },

        LAST_YEAR(state) {
            return _.get(state, 'top_articles.last_year', []);
        },
    },

    actions: {
        FETCH_ARTICLES({state, commit}, fetchParams) {
            if (state.articles.promise) {
                return state.articles.promise;
            }
            const params = _.assign({}, state.articles.params, fetchParams);
            const promise = axios.get('/news?', {params}).then((response) => {
                commit('UPDATE_ARTICLES', _.get(response, 'data.data', []));
                commit('UPDATE_ARTICLES_META', _.get(response, 'data.meta', {}));

                if (_.get(fetchParams, 'load_top_articles', 0) == 1) {
                    commit('UPDATE_TOP_ARTICLES', _.get(response, 'data.top_articles', {}));
                }
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_ARTICLE', null);
            });

            commit('UPDATE_PROMISE_ARTICLE', promise);
            return promise;
        },

        FETCH_CURRENT_ARTICLE({state, commit}, fetchParams) {
            if (state.current_article.promise) {
                return state.current_article.promise;
            }

            const params = _.assign({}, fetchParams);
            const promise = axios.get('/news/show?', {params}).then((response) => {
                commit('UPDATE_CURRENT_ARTICLE', response.data.data);
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_CURRENT_ARTICLE', null);
            });
            commit('UPDATE_PROMISE_CURRENT_ARTICLE', promise);
            return promise;
        },

        RESET_ARTICLES({commit}) {
            commit('SET_DEFAULT_DATA');
        },

        SET_FAVORITE({state, commit}, article_id) {
            return axios.put(`/news/${article_id}/set-favorite`).then((response) => {
                commit('UPDATE_ARTICLE', response.data.data);
            });
        },

        REMOVE_FAVORITE({state, commit}, article_id) {
            return axios.put(`/news/${article_id}/remove-favorite`).then((response) => {
                commit('UPDATE_ARTICLE', response.data.data);
            });
        },
    },

    mutations: {
        UPDATE_ARTICLES(state, data) {
            return state.articles.all = data;
        },

        UPDATE_ARTICLE(state, data) {
            const index = _.findIndex(state.articles.all, {id: data.id,});
            let article = _.find(state.articles.all, {id: data.id,});
            if (index > -1) {
                article = _.merge(article, data);
                state.articles.all.splice(index, 1, article);
            }

            if (data.id === _.get(state, 'current_article.data.id')) {
                Object.assign(state.current_article.data, data);
            }
        },

        UPDATE_ARTICLES_META(state, meta) {
            return state.articles.meta = meta;
        },

        UPDATE_CURRENT_ARTICLE(state, data) {
            return state.current_article.data = data;
        },

        UPDATE_TOP_ARTICLES(state, data) {
            return state.top_articles = data;
        },

        UPDATE_PROMISE_ARTICLE(state, promise) {
            return state.articles.promise = promise;
        },

        UPDATE_PROMISE_CURRENT_ARTICLE(state, promise) {
            return state.current_article.promise = promise;
        },

        SET_DEFAULT_DATA(state) {
            const defaultState = getDefaultState();
            return _.assign(state, defaultState);
        }
    }
}
