<template>
  <div class="news-left-top-bar">
    <div class="most-recently-container" v-if="!xsOnly">
      <div class="most-recently-header">{{ $t('news.most_recently') }}</div>
      <div v-for="(article, i) in mostRecently" :key="i" class="py-1.5">
        <a
          v-if="article.is_external"
          v-bind:href="article.translation.external_link"
          target="_blank"
          rel="noopener noreferrer"
          v-html="article.additional_title"
        />
        <router-localized-link
          v-else
          :to="{name: 'news.article', params: {categorySlug: String(article.category.translation.slug), articleSlug: String(article.translation.slug)}}"
          v-html="article.additional_title"
        />
      </div>
    </div>

    <div class="most-recently-container pt-2.25">
      <div class="most-recently-header">{{ $t('news.most_read') }}</div>
      <div v-for="(article, i) in lastYear" :key="i" class="py-1.5">
        <a
          v-if="article.is_external"
          v-bind:href="article.translation.external_link"
          v-html="article.additional_title"
          target="_blank"
          rel="noopener noreferrer"
        />
        <router-localized-link
          v-else
          :to="{name: 'news.article', params: {categorySlug: String(article.category.translation.slug), articleSlug: String(article.translation.slug)}}"
          v-html="article.additional_title"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';

export default {
  name: 'news-left-top-bar',
  components: {RouterLocalizedLink},
  props: {
    mostRecently: Object,
    lastYear: Object
  },
}
</script>
