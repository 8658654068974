<template>
  <el-row type="flex" justify="start" v-if="'default' === currentTab">
    <template v-for="(promo, i) in items" :key="i">
      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
        <post-mini v-if="'Post' === promo.morphable_type"
                   :vuex-module="vuexModules.post"
                   :post="promo.data"/>
        <online-promo-mini v-if="'OnlinePromo' === promo.morphable_type"
                           :vuex-module="vuexModules.online_promo"
                           :online-promo="promo.data"/>
        <folder-promo-mini v-if="'FolderItem' === promo.morphable_type"
                           :vuex-module="vuexModules.folder_promo"
                           :folder-promo="promo.data"/>
      </el-col>
    </template>
  </el-row>

  <el-row type="flex" justify="start" v-if="'posts' === currentTab">
    <template v-for="(promo, i) in items" :key="i">
      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
        <post-mini v-if="'Post' === promo.morphable_type"
                   :vuex-module="vuexModules.post"
                   :post="promo.data"/>
      </el-col>
    </template>
  </el-row>

  <el-row type="flex" justify="start" v-if="'folder-promos' === currentTab">
    <template v-for="(promo, i) in items" :key="i">
      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
        <folder-promo-mini v-if="'FolderItem' === promo.morphable_type"
                           :vuex-module="vuexModules.folder_promo"
                           :folder-promo="promo.data"/>
      </el-col>
    </template>
  </el-row>

  <el-row type="flex" justify="start" v-if="'online-promos' === currentTab">
    <template v-for="(promo, i) in items" :key="i">
      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
        <online-promo-mini v-if="'OnlinePromo' === promo.morphable_type"
                           :vuex-module="vuexModules.online_promo"
                           :online-promo="promo.data"/>
      </el-col>
    </template>
  </el-row>

  <el-row type="flex" justify="start" v-if="'favourites' === currentTab">
    <el-col :xs="24" :sm="24" :md="20" :lg="24" :xl="20">
      <el-row type="flex" justify="start">
        <template v-for="(promo, i) in items" :key="i">
          <el-col :xs="24">
            <folder-promo v-if="'FolderItem' === promo.morphable_type"
                          :vuex-module="vuexModules.folder_promo"
                          :folder-promo="promo.data"/>
            <online-promo v-if="'OnlinePromo' === promo.morphable_type"
                          :vuex-module="vuexModules.online_promo"
                          :online-promo="promo.data"/>
            <post v-if="'Post' === promo.morphable_type"
                  :vuex-module="vuexModules.post"
                  :post="promo.data"/>
          </el-col>
        </template>
      </el-row>
    </el-col>
  </el-row>

  <el-row type="flex" justify="start" v-if="'discount-codes' === currentTab">
    <template v-for="(promo, i) in items" :key="i">
      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
        <post-mini v-if="'Post' === promo.morphable_type"
                   :vuex-module="vuexModules.post"
                   :post="promo.data"/>
      </el-col>
    </template>
  </el-row>
</template>

<script>
import PostMini from '@/components/feed/post/PostMini.vue';
import Post from '@/components/feed/post/Post';
import FolderPromoMini from '@/components/feed/promo/FolderPromoMini.vue';
import OnlinePromoMini from '@/components/feed/promo/OnlinePromoMini.vue';
import FolderPromo from '@/components/feed/promo/FolderPromo';
import OnlinePromo from '@/components/feed/promo/OnlinePromo';

export default {
  name: 'search-promos-list',
  components: {OnlinePromo, FolderPromo, OnlinePromoMini, FolderPromoMini, Post, PostMini},
  props: {
    items: Array,
    currentTab: String,
    vuexModules: {
      type: Object,
      default() {
        return {
          'post': 'promo',
          'online_promo': 'promo',
          'folder_promo': 'promo',
          'discount-codes': 'promo',
        }
      }
    },
  },
}
</script>
