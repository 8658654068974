<template>
  <div class="news-feed">
    <el-row type="flex" justify="start">
      <template v-for="(article, i) in ARTICLES" :key="i">
        <news-preview :article="article" :layout="dlayout[i]"></news-preview>
        <el-col v-if="i === 0" :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <news-left-top-bar :most-recently="mostRecentlyData" :last-year="lastYearData"/>
        </el-col>
      </template>
    </el-row>
    <ad-sense :slotter="'3900262111'" :timeout="200" :className="adClass"></ad-sense>
  </div>
  <el-row v-if="!ARTICLES_PROMISE">
    <el-col :span="24">
      <div class="centered my-4.5 news-pagination">
        <el-pagination
          layout="prev, pager, next"
          :default-current-page="defaultPage"
          :page-size="ARTICLES_PER_PAGE"
          :total="totalArticles"
          :current-page="currentPage"
          pager-count="6"
          @current-change="goPage"
        />
      </div>
    </el-col>
  </el-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import NewsPreview from '@/components/feed/news/NewsPreview';
import NewsLeftTopBar from '@/components/feed/news/NewsLeftTopBar';
import AdSense from '@/components/feed/news/AdSense';

export default {
  name: 'news-feed',
  components: {
    NewsPreview,
    NewsLeftTopBar,
    AdSense
  },
  data() {
    return {
      defaultPage: _.get(this.$route.query, 'page', 1),
      currentPage: _.get(this.$route.query, 'page', 1),
      totalArticles: 0,
      adClass: 'ad-sense-container',
      //TODO -- Need optimize
      dlayout: [
        {col_lg: 16, col_xl: 16},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 16, col_xl: 16, container_class: ''},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 16, col_xl: 16, container_class: ''},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
      ]
    }
  },
  computed: {
    ...mapGetters('article', [
      'ARTICLES',
      'ARTICLES_PROMISE',
      'ARTICLES_TOTAL',
      'ARTICLES_CURRENT_PAGE',
      'ARTICLES_PER_PAGE',
      'MOST_RECENTLY',
      'LAST_YEAR',
      'LAST_WEEK'
    ]),
    mostRecentlyData() {
      return this.formattedArticles(this.MOST_RECENTLY)
    },
    lastYearData() {
      return this.formattedArticles(this.LAST_YEAR)
    },
  },
  mounted() {
    this.FETCH_ARTICLES({load_top_articles: 1, page: this.defaultPage}).then(() => {
      this.currentPage = this.ARTICLES_CURRENT_PAGE;
      this.totalArticles = this.ARTICLES_TOTAL;
    });
  },
  methods: {
    ...mapActions('article', [
      'FETCH_ARTICLES',
    ]),
    formattedArticles(arr) {
      const thisElem = this
      return _.map(arr, function (article) {
        const date = new Date(article.published_at.iso);

        return _.merge({}, article, {additional_title: thisElem.formatDate(date) + ' ' + article.translation.title});
      });
    },
    formatDate(date) {
      return [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
      ].join('/');
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },
    goPage(page) {
      this.currentPage = page;
      const query = _.clone(this.$router.currentRoute.value.query);
      query['page'] = page;
      this.$router.replace({query: query});
      this.FETCH_ARTICLES({page: page});
      window.scrollTo(0, 0);
    }
  },
}
</script>
