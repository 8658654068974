<template>
  <div class="optional-info">
    <p v-if="post.new_price && post.old_price" class="optional-info__text color-primary font-weight-600">
      <span class="optional-info__old-price">{{ post.old_price }} &euro;</span>
      <span class="optional-info__separator">{{ ' |' }}</span>
      {{ post.new_price }} &euro;
    </p>
    <p v-else-if="post.discount" class="optional-info__text">
      {{ $t('post.discount') }}:
      <span class="color-primary">{{ post.discount }}</span>
    </p>
    <p v-else-if="post.discount_text" class="optional-info__text color-primary">{{ post.discount_text }}</p>
    <p v-if="post.free_shipping" class="optional-info__free-shipping">({{ $t('post.free_shipping') }})</p>
  </div>
</template>

<script>
export default {
  name: 'optional-info',
  props: ['post'],
}
</script>
