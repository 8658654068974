<template>
  <div class="filter-container search-filter">
    <div class="filter-header search-filter__header">
      <span class="filter-header__text" v-t="'filters.keyword'"/>
    </div>
    <div class="filter-options mt-2" v-if="filterActive">
      <div class="d-flex justify-content-between align-items-center">
        <el-autocomplete
          v-model="input"
          ref="autocomplete"
          class="w-100"
          popper-class="search-filter-popper"
          :fetch-suggestions="querySearch"
          :placeholder="$t('filters.search')"
          @select="onSelect"
          @keydown.enter="onKeyEnter"
        >
          <template #suffix>
            <button v-if="input" class="close-btn" @click="reset">
              <i class="icon-close-4"/>
            </button>
          </template>
          <template #default="{ item }">
            <span class="font-size-15 pe-2">{{ item.title }}</span>
          </template>
        </el-autocomplete>
        <el-button class="hover:bg-primary--dark hover:border-primary--dark ms-2" type="primary" @click="filterKeyword">
          <i class="icon-search-3 font-size-20"/>
        </el-button>
      </div>
    </div>
    <el-divider class="bold-divider my-3"/>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {needAuth} from '@/mixins/auth/needAuth';
import {autocompelete} from '@/mixins/autocompelete';

export default {
  name: 'search-filter',
  mixins: [needAuth, autocompelete],
  data() {
    return {
      filterActive: true,
      input: '',
    };
  },
  mounted() {
    const defaultFilterValue = this.$route.query['search_filter']
    if (defaultFilterValue !== undefined) {
      this.input = defaultFilterValue;
    }
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_VALUE',
    ]),
    filterKeyword() {
      // Search using search-filter instead of main search component
      if (this.$route.query.q) {
        this.$route.query.q = '';
      }

      this.SET_FILTER_VALUE({search_filter: this.input});
      this.emitter.emit('close-filter');
    },
    onSelect(item) {
      this.input = item.title
      this.filterKeyword()
    },
    onKeyEnter() {
      this.$refs.autocomplete.suggestions = []
      this.filterKeyword()
    },
    reset() {
      this.input = ''
      this.$refs.autocomplete.suggestions = []
    },
  },
  watch: {
    input(newVal) {
      if (newVal === '') {
        this.SET_FILTER_VALUE({search_filter: ''});
      }
    },
    $route(to, from) {
      if (!to.query.search_filter || to.query.search_filter === '') {
        this.input = '';
      }
    }
  }
}
</script>
