<template>
  <div>
    <el-scrollbar :native="false">
      <div class="feed-inset-filter">
        <div class="inset-tab-container">
          <el-tag class="inset-tab cursor-pointer d-block d-lg-none text-decoration-none filter-icon"
                  @click="activeFilter"
                  :class="{'color-primary': FILTERS_ANIMATION.home_filter, 'color-dark': !FILTERS_ANIMATION.home_filter}">
            <i class="icon-filter"></i>
          </el-tag>
          <div class="partition d-block d-lg-none"></div>
          <el-tag class="inset-tab cursor-pointer"
                  :class="{'inset-tab-color': 'default' !== feedTab}"
                  :effect="'plain'"
                  :size="tagSize"
                  @click="changeTab('default')">
            <span class="inset-tab-fon">{{ insetTabs['default'] }}</span>
          </el-tag>
          <div class="partition"></div>
          <el-tag class="inset-tab cursor-pointer"
                  :class="{'inset-tab-color': 'last-ones' !== feedTab}"
                  :effect="'plain'"
                  :size="tagSize"
                  @click="changeTab('last-ones')">
            <span class="inset-tab-fon">{{ insetTabs['last-ones'] }}</span>
          </el-tag>
          <div class="partition"></div>
          <el-tooltip :content="$t('profile.coming_soon_label')" placement="right" effect="customized">
            <el-tag class="inset-tab for-you"
                    :class="{'inset-tab-color': 'for-you' !== feedTab}"
                    :effect="'plain'"
                    :size="tagSize">
                  <span>{{ insetTabs['for-you'] }}</span>
            </el-tag>
          </el-tooltip>
        </div>
      </div>
    </el-scrollbar>

    <Transition name="filters-home-anime">
      <div class="filters-menu" v-if="FILTERS_ANIMATION.home_filter">
        <div class="icon-filters-close" @click="activeFilter">
          <i class="icon-chevron-thin-right rotate-180"></i>
        </div>
        <filters-list></filters-list>
      </div>
    </Transition>

    <div v-if="xsOnly && showIconGoUp">
      <div class="icon-go-up" @click="goUp">
        <i class="icon-arrow-2 rotate-270"></i>
      </div>
    </div>
  </div>
</template>
  
<script>
import FiltersList from '@/components/left_bar/filters/FiltersList';
import {needAuth} from '@/mixins/auth/needAuth';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'inset-tabs',
  mixins: [needAuth],
  components: {
    FiltersList,
  },
  emits: ['change'],
  props: {
    default: {
      type: String,
      default: 'default'
    },
    blockForGuest: {
      type: Boolean,
      default: true
    },
    changeQuery: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      filterMenuVisible: false,
      insetTabs: {
        'default': this.$t('search.home-tabs.default'),
        'last-ones': this.$t('search.home-tabs.last-ones'),
        'for-you': this.$t('search.home-tabs.for-you')
      },
      showIconGoUp: false,
    }
  },
  computed: {
    ...mapGetters('filter', [
      'ACTIVE_FILTERS',
      'FILTERS_ANIMATION'
    ]),
    feedTab() {
      return _.has(this.insetTabs, this.default) ? this.default : ''; // default,last-ones,for-you
    },
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_VALUE',
      'SET_FILTER_ACTIVE',
      'SET_FILTER_INACTIVE',
      'SET_ANIMATION_ACTIVE',
      'SET_ANIMATION_INACTIVE'
    ]),
    changeTab(newKey) {
      if (this.ACTIVE_FILTERS.home_filter) {
        this.SET_FILTER_INACTIVE('home_filter');
      }
      if (this.FILTERS_ANIMATION.home_filter) {
        this.SET_ANIMATION_INACTIVE('home_filter');
      }

      if (this.blockForGuest && this.triggerLoginDialog()) {
        return;
      }

      if (this.changeQuery) {
        let query = _.clone(this.$router.currentRoute.value.query);
        query = _.omit(query, ['tab']);
        if (newKey !== 'default') {
          query['tab'] = newKey;
        }
        this.$router.replace({query: query});
      }

      this.$emit('change', newKey);
    },
    goUp() {
      window.scrollTo(0, 0);
    },
    activeFilter() {
      if (this.FILTERS_ANIMATION.home_filter) {
        this.SET_ANIMATION_INACTIVE('home_filter');
        return;
      }
      this.SET_ANIMATION_ACTIVE('home_filter');
    }
  },
  watch: {
    currentScrollTop(newValue) {
      if (newValue > window.screen.height) {
        this.showIconGoUp = true;
        return;
      }
      this.showIconGoUp =  false;
    }
  }
}
</script>
