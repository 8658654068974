export const posts = {
  mounted(){
    document.addEventListener('DOMContentLoaded', function() {
      const showMore = document.getElementsByClassName('show-more');

      // Displaying the number of webshop content terms and cleaning up the show more button.
      const webshopContentContainer = document.getElementsByClassName('content-container-webshop');
      const webshopContent = document.getElementsByClassName('content-webshop');
      
      for (let i = 0; i < webshopContentContainer.length; i++) {
        if ((webshopContentContainer[i].clientHeight / 15) > 5) {
          webshopContent[i].classList.add('terms-number-webshop');
        } else {
          showMore[i].classList.add('d-none');
        }
      }

      // Displaying the number of post content terms and cleaning up the show more button.
      const postContentContainer = document.getElementsByClassName('content-container-post');
      const postContent = document.getElementsByClassName('content-post');
      
      for (let i = 0; i < postContentContainer.length; i++) {
        if ((postContentContainer[i].clientHeight / 15) > 6.5) {
          postContent[i].classList.add('terms-number-post');
        } else {
          showMore[i].classList.add('d-none');
        }
      }
    });
  }
};
