export const header = {
  mounted() {
    // STICKY FEATURE IS DISABLED FOR NOW
    // TODO REMOVE IF NOT NEEDED AGAIN
  //   document.addEventListener('scroll', function () {
  //     const menu = document.querySelector('.header-menu');
  //     const searchBar = document.querySelector('.header-area__search');
  //     const categoriesFilter = document.querySelector('.categories-filter');
  //
  //     categoriesFilter.style.display = document.documentElement.scrollTop > 300 ? 'none' : 'block';
  //
  //     if (window.innerWidth < 768) {
  //       if (document.documentElement.scrollTop > 300) {
  //         menu.style.display = 'none';
  //         searchBar.style.display = 'none';
  //       } else {
  //         menu.style.display = 'block';
  //         searchBar.style.display = 'flex';
  //       }
  //     } else {
  //       menu.style.display = 'block';
  //       searchBar.style.display = 'block';
  //     }
  //   });
  }
};
