const getDefaultState = () => {
    return {
        promos: [],
        form: {
            type: {},
            subtype: {},
            categories: [],
            title: '',
            content: '',
            origin_url: '',
            discount_code: '',
            discount_type: 'percent',
            not_started: false,
            only_price: false,
            old_price: '',
            new_price: '',
            only_discount: false,
            discount_value: '',
            only_another_type: false,
            another_type: '',
            webshop_id: '',
            webshops: [],
            images: [],
            started_at: '',
            expired_at: '',
            free_shipping: false,
            shipping_number: ''
        },
        queryParams: {
            page: 0,
            per_page: 9
        },
        is_complete: false,
        promise: null,
        total: 0,

        currentPromo: {
            data: {},
            promise: null,
        },

        filters: {
            morphable_types: [],
            types: [],
            categories: [],
            webshops: [],
            stores: [],
            brands: [],
            prices: [],
        }
    }
};

const getPromoNamespace = (morphable_type) => {
    let namespace = 'posts';
    switch (morphable_type) {
        case 'Post':
            namespace = 'posts';
            break;
        case 'OnlinePromo':
            namespace = 'online-promos';
            break;
        case 'FolderItem':
            namespace = 'folder-items';
            break;
        case 'Folder':
            namespace = 'folders';
            break;
    }
    return namespace;
};

export default {
    namespaced: true,
    state: () => (getDefaultState()),

    getters: {
        CURRENT_PROMO(state) {
            return state.currentPromo.data;
        },

        PROMOS(state) {
            return state.promos;
        },

        PROMOS_PROMISE(state) {
            return state.promise;
        },

        PROMOS_IS_COMPLETE(state) {
            return state.is_complete;
        },

        PROMOS_MORPHABLE_TYPES(state) {
            return state.filters.morphable_types;
        },

        PROMOS_TYPES(state) {
            return state.filters.types;
        },

        PROMOS_CATEGORIES(state) {
            return state.filters.categories;
        },

        PROMOS_WEBSHOPS(state) {
            return state.filters.webshops;
        },

        PROMOS_STORES(state) {
            return state.filters.stores;
        },

        PROMOS_BRANDS(state) {
            return state.filters.brands;
        },

        PROMOS_PRICES(state) {
            return state.filters.prices;
        },

        PROMOS_TOTAL(state) {
            return state.total;
        }
    },

    actions: {
        CREATE_PROMO({state, commit}, data) {
            return axios.post('/posts', data)
        },
        FETCH_CURRENT_PROMO({state, commit}, params) {
            if (state.currentPromo.promise) {
                return state.currentPromo.promise;
            }
            const promise = axios.get('/feed-items?', {params}).then((response) => {
                commit('UPDATE_CURRENT_PROMO', _.get(response, 'data.data[0]'));
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_CURRENT_PROMO', null);
            });
            commit('UPDATE_PROMISE_CURRENT_PROMO', promise);
            return promise;
        },
        FETCH_PROMOS({state, commit}, options) {
            if (state.promise) {
                return state.promise;
            }

            let params = _.assign(_.assign({}, state.queryParams), options);
            params.page += 1;
            const includeFilters = 1 === params.page;
            if (!includeFilters) {
                params.include_filters = [];
            }
            params = _.pickBy(params, function (elem) {
                if (undefined === elem) {
                    return false;
                }
                if (Array.isArray(elem)) {
                    return elem.length;
                }
                return elem.toString().length;
            });

            const promise = axios.get('/feed-items?', {params}).then((response) => {
                commit('UPDATE_PROMOS', response.data);
                commit('UPDATE_TOTAL', response.data.meta.total);

                if (includeFilters) {
                    commit('UPDATE_TYPES', _.get(response,'data.meta.types',[]));
                    commit('UPDATE_MORPHABLE_TYPES', _.get(response,'data.meta.morphable_types',[]));
                    commit('UPDATE_CATEGORIES', _.get(response,'data.meta.categories',[]));
                    commit('UPDATE_WEBSHOPS', _.get(response,'data.meta.webshops',[]));
                    commit('UPDATE_STORES', _.get(response,'data.meta.stores',[]));
                    commit('UPDATE_BRANDS', _.get(response,'data.meta.brands',[]));
                    commit('UPDATE_PRICES', _.get(response,'data.meta.prices',[]));
                }
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_PROMOS', null);
            });

            commit('UPDATE_PROMISE_PROMOS', promise);
            return promise;
        },

        RESET_PROMOS({commit}) {
            commit('SET_DEFAULT_DATA');
        },

        RESET_FORM({commit}) {
            commit('SET_DEFAULT_FORM');
        },

        UPDATE_CURRENT_PAGE({commit}, data) {
            commit('UPDATE_CURRENT_PAGE', data);
        },

        SET_HIT({state, commit}, data) {
            const namespace = getPromoNamespace(data.morphable_type);
            return axios.put(`/${namespace}/${data.morphable_id}/set-hit`).then((response) => {
                commit('UPDATE_PROMO', {
                    morphable_type: data.morphable_type,
                    morphable_id: data.morphable_id,
                    data: response.data.data
                });
            });
        },

        REMOVE_HIT({state, commit}, data) {
            const namespace = getPromoNamespace(data.morphable_type);
            return axios.put(`/${namespace}/${data.morphable_id}/remove-hit`).then((response) => {
                commit('UPDATE_PROMO', {
                    morphable_type: data.morphable_type,
                    morphable_id: data.morphable_id,
                    data: response.data.data
                });
            });
        },

        REMOVE_DISLIKE({state, commit}, data) {
            const namespace = getPromoNamespace(data.morphable_type);
            return axios.put(`/${namespace}/${data.morphable_id}/remove-dislike`).then((response) => {
                commit('UPDATE_PROMO', {
                    morphable_type: data.morphable_type,
                    morphable_id: data.morphable_id,
                    data: response.data.data
                });
            });
        },

        SET_DISLIKE({state, commit}, data) {
            const namespace = getPromoNamespace(data.morphable_type);
            return axios.put(`/${namespace}/${data.morphable_id}/set-dislike`).then((response) => {
                commit('UPDATE_PROMO', {
                    morphable_type: data.morphable_type,
                    morphable_id: data.morphable_id,
                    data: response.data.data
                });
            });
        },

        SET_FAVORITE({state, commit}, data) {
            const namespace = getPromoNamespace(data.morphable_type);
            return axios.put(`/${namespace}/${data.morphable_id}/set-favorite`).then((response) => {
                commit('UPDATE_PROMO', {
                    morphable_type: data.morphable_type,
                    morphable_id: data.morphable_id,
                    data: response.data.data
                });
            });
        },
        REMOVE_FAVORITE({state, commit}, data) {
            const namespace = getPromoNamespace(data.morphable_type);
            return axios.put(`/${namespace}/${data.morphable_id}/remove-favorite`).then((response) => {
                commit('UPDATE_PROMO', {
                    morphable_type: data.morphable_type,
                    morphable_id: data.morphable_id,
                    data: response.data.data
                });
            });
        },

        SET_SUBSCRIPTION({state, commit}, data) {
            const namespace = getPromoNamespace(data.morphable_type);
            return axios.put(`/${namespace}/${data.morphable_id}/subscribe`).then((response) => {
                commit('UPDATE_PROMO', {
                    morphable_type: data.morphable_type,
                    morphable_id: data.morphable_id,
                    data: response.data.data
                });
            });
        },

        REMOVE_SUBSCRIPTION({state, commit}, data) {
            const namespace = getPromoNamespace(data.morphable_type);
            return axios.put(`/${namespace}/${data.morphable_id}/unsubscribe`).then((response) => {
                commit('UPDATE_PROMO', {
                    morphable_type: data.morphable_type,
                    morphable_id: data.morphable_id,
                    data: response.data.data
                });
            });
        },

        HIDE({state, commit}, data) {
            const namespace = getPromoNamespace(data.morphable_type);
            return axios.put(`/${namespace}/${data.morphable_id}/hide`).then((response) => {
                commit('UPDATE_PROMO', {
                    morphable_type: data.morphable_type,
                    morphable_id: data.morphable_id,
                    data: response.data.data
                });
            });
        },

        RESTORE_HIDDEN({state, commit}, data) {
            const namespace = getPromoNamespace(data.morphable_type);
            return axios.put(`/${namespace}/${data.morphable_id}/restore-hidden`).then((response) => {
                commit('UPDATE_PROMO', {
                    morphable_type: data.morphable_type,
                    morphable_id: data.morphable_id,
                    data: response.data.data
                });
            });
        },
    },

    mutations: {
        UPDATE_CURRENT_PROMO(state, data) {
            return Object.assign(state.currentPromo, data);
        },

        UPDATE_CURRENT_PAGE(state, data) {
            return state.queryParams.page = data
        },

        UPDATE_PROMISE_CURRENT_PROMO(state, promise) {
            return state.currentPromo.promise = promise;
        },

        UPDATE_PROMOS(state, data) {
            const promos = data.data;
            state.queryParams.page += _.isEmpty(promos) ? 0 : 1;
            state.is_complete = _.isEmpty(promos) || _.get(data, 'meta.current_page', 0) === _.get(data, 'meta.last_page', 1);
            return state.promos = _.union(state.promos, promos);
        },

        UPDATE_PROMO(state, instance) {
            _.set(instance, 'data', _.pickBy(instance.data, function (value, key) {
                return [
                    'is_hit',
                    'is_dislike',
                    'is_favourite',
                    'favourite_at',
                    'subscriptions',
                    'is_hidden',
                    'rating_calculated'
                ].includes(key);
            }));

            // Find index and instance
            const index = _.findIndex(state.promos, {
                morphable_type: instance.morphable_type,
                morphable_id: instance.morphable_id
            });
            const promo = _.find(state.promos, {
                morphable_type: instance.morphable_type,
                morphable_id: instance.morphable_id
            });

            if (promo) {
                Object.assign(promo.data, instance.data);
                // Replace item in collection
                state.promos.splice(index, 1, promo);
            }

            if (instance.morphable_id === _.get(state, 'currentPromo.morphable_id') &&
                instance.morphable_type === _.get(state, 'currentPromo.morphable_type')) {
                Object.assign(state.currentPromo.data, instance.data);
            }
        },

        UPDATE_PROMISE_PROMOS(state, data) {
            return state.promise = data;
        },

        UPDATE_TYPES(state, types) {
            return state.filters.types = types;
        },

        UPDATE_MORPHABLE_TYPES(state, morphable_types) {
            return state.filters.morphable_types = morphable_types;
        },

        UPDATE_CATEGORIES(state, categories) {
            return state.filters.categories = categories;
        },

        UPDATE_WEBSHOPS(state, webshops) {
            return state.filters.webshops = webshops;
        },

        UPDATE_STORES(state, stores) {
            return state.filters.stores = stores;
        },

        UPDATE_BRANDS(state, brands) {
            return state.filters.brands = brands;
        },

        UPDATE_PRICES(state, prices) {
            return state.filters.prices = prices;
        },

        UPDATE_FORM(state, form) {
            Object.keys(form).forEach(key => {
                state.form[key] = form[key];
            })

            return state.form
        },

        UPDATE_TOTAL(state, total) {
            if (total === undefined) {
                total = 0;
            }
            return state.total = total;
        },

        SET_DEFAULT_DATA(state) {
            const defaultState = getDefaultState();
            _.unset(defaultState, 'filters');
            _.unset(defaultState, 'form');
            return _.assign(state, defaultState);
        },

        SET_DEFAULT_FORM(state) {
            const defaultState = getDefaultState();

            return _.assign(state.form, defaultState.form);
        }
    }
}
