export const carousels = {
    mounted() {
        document.addEventListener('DOMContentLoaded', function () {
            const carousels = document.querySelectorAll('[data-scroll-container]');

            carousels.forEach(carousel => {
                const scrollLeftBtn = carousel.closest('.nav-buttons').querySelector('.float-start');
                const scrollRightBtn = carousel.closest('.nav-buttons').querySelector('.float-end');
                const itemWidth = carousel.querySelector('.scrollbar-item').offsetWidth;

                let startX = 0;
                let currentX = 0;

                carousel.addEventListener('touchstart', function (event) {
                    startX = event.touches[0].clientX;
                });

                carousel.addEventListener('touchmove', function (event) {
                    currentX = event.touches[0].clientX;
                });

                carousel.addEventListener('touchend', function () {
                    if (startX - currentX > 20) {
                        scrollRight(itemWidth);
                    } else if (currentX - startX > 20) {
                        scrollLeft(itemWidth);
                    }
                });

                scrollLeftBtn.addEventListener('click', function () {
                    const w = carousel.querySelector('.scrollbar-item').offsetWidth;
                    scrollLeft(w);
                });

                scrollRightBtn.addEventListener('click', function () {
                    const w = carousel.querySelector('.scrollbar-item').offsetWidth;
                    scrollRight(w);
                });

                function scrollLeft(width) {
                    carousel.scrollBy({
                        left: -width,
                        behavior: 'smooth'
                    });
                }

                function scrollRight(width) {
                    carousel.scrollBy({
                        left: width,
                        behavior: 'smooth'
                    });
                }
            });
        });
    },
};
