export const folders = {
    mounted(){
        document.addEventListener('DOMContentLoaded', function() {
            // Folders carousel scroll feature
            const foldersNextArrows = document.getElementsByClassName('folders-carousel-arrow');
            const foldersArrowNextClicked = function(event) {
                const target = event.target;
                const scrollBlock = target.closest('.folders-carousel-container').querySelector('.el-scrollbar__wrap');
                scrollBlock.scrollLeft += 230;
                const arrowPrev = target.closest('.folders-carousel-container').querySelector('.folders-carousel-arrow-back');
                arrowPrev.classList.remove('d-none');
            };
            for (let i = 0; i < foldersNextArrows.length; i++) {
                foldersNextArrows[i].addEventListener('click', foldersArrowNextClicked, false);
            }

            const foldersPrevArrows = document.getElementsByClassName('folders-carousel-arrow-back');
            const foldersArrowPrevClicked = function(event) {
                const target = event.target;
                const scrollBlock = target.closest('.folders-carousel-container').querySelector('.el-scrollbar__wrap');
                scrollBlock.scrollLeft -= 230;
            };
            for (let i = 0; i < foldersPrevArrows.length; i++) {
                foldersPrevArrows[i].addEventListener('click', foldersArrowPrevClicked, false);
            }

            // Folder promos carousel scroll feature
            const foldersPromosNextArrows = document.getElementsByClassName('folder-promos-carousel-arrow');
            const folderPromosArrowNextClicked = function(event) {
                const target = event.target;
                const scrollBlock = target.closest('.promos-carousel-container').querySelector('.el-scrollbar__wrap');
                scrollBlock.scrollLeft += 230;
                const arrowPrev = target.closest('.promos-carousel-container').querySelector('.folder-promos-carousel-arrow-back');
                arrowPrev.classList.remove('d-none');
            };
            for (let i = 0; i < foldersPromosNextArrows.length; i++) {
                foldersPromosNextArrows[i].addEventListener('click', folderPromosArrowNextClicked, false);
            }

            const foldersPromosPrevArrows = document.getElementsByClassName('folder-promos-carousel-arrow-back');
            const folderPromosArrowPrevClicked = function(event) {
                const target = event.target;
                const scrollBlock = target.closest('.promos-carousel-container').querySelector('.el-scrollbar__wrap');
                scrollBlock.scrollLeft -= 230;
            };
            for (let i = 0; i < foldersPromosPrevArrows.length; i++) {
                foldersPromosPrevArrows[i].addEventListener('click', folderPromosArrowPrevClicked, false);
            }
        });
    }
};
