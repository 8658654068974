<template>
  <div class="folders-section">
    <default-space>
      <div class="folders-section__header">
        <h3 class="folders-section__title" v-text="$t('landing.popular_folders.title')"/>

        <router-localized-link :to="{name: 'folders'}" class="folders-section__link">
          <span v-t="'landing.popular_folders.subtitle'"/>
          <i class="icon-triangle-arrow ms-2"/>
        </router-localized-link>
      </div>

      <top-folders
        class="mb-3.5"
        :folders="FOLDERS"
        :custom-width="customWidth"
        :is-loading="!!FOLDERS_PROMISE"
        vuex-module="folder-popular"
      />
    </default-space>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import TopFolders from '@/components/feed/carousels/TopFolders.vue';
import DefaultSpace from '@/views/DefaultSpace.vue';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink.vue';

export default {
  name: 'PopularFoldersSection',
  components: {RouterLocalizedLink, DefaultSpace, TopFolders},
  computed: {
    ...mapGetters('folder-popular', [
      'FOLDERS',
      'FOLDERS_PROMISE',
    ]),
    /**
     * Custom Promo/Folder width in scrollbar
     * @returns {string}
     */
    customWidth() {
      if (this.xsOnly && this.clientWidth <= 430) {
        return '200px'
      } else if (this.xsOnly && this.clientWidth > 430) {
        return '50%'
      } else if (this.lgAndUp) {
        return '25%'
      } else {
        return '33.3%'
      }
    }
  },
  mounted() {
    this.FETCH_FOLDERS({
      folder_client_types: ['1'],
    })
  },
  methods: {
    ...mapActions('folder-popular', [
      'FETCH_FOLDERS',
    ]),
  }
}
</script>
