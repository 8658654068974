<template>
  <div class="left-bar-user-menu">
    <div class="user-menu-item mt-2">
      <router-localized-link :to="{name: 'favourites'}">
        <i class="icon-heart-2"/> <span class="hover:text-underline" v-t="'menu.user.favourites'"/>
      </router-localized-link>
    </div>

    <el-divider class="my-3 bold-divider" :class="dividersClasses[1]"/>

    <div class="user-menu-item">
      <router-localized-link :to="{name: 'prizes-pool'}">
        <i class="icon-cup"/> <span class="hover:text-underline" v-t="'menu.main.prizes'"/>
      </router-localized-link>
    </div>

    <el-divider class="my-3 bold-divider" :class="dividersClasses[2]"/>

    <div class="left-bar-button">
      <router-localized-link v-t="'menu.main.invite_friend'" :to="{name: 'referral_page'}"/>
    </div>

    <el-divider class="my-3 bold-divider" :class="dividersClasses[3]"/>

    <div class="left-bar-button">
      <router-localized-link v-t="'menu.user.about_points'" :to="getPagePathBySlug('bonus-points')"/>
    </div>

    <el-divider class="my-3 bold-divider" :class="dividersClasses[4]"/>

    <div class="left-bar-button">
      <router-localized-link v-t="'menu.user.settings'" :to="{name: 'settings'}"/>
    </div>

    <el-divider class="my-3 bold-divider"/>

    <div class="left-bar-button">
      <div v-t="'menu.main.logout'" @click="logout"/>
    </div>

    <el-divider class="my-3 bold-divider"/>

    <locale-switcher/>
  </div>
</template>

<script>
import LocaleSwitcher from '@/components/header/LocaleSwitcher';
import {mapActions} from 'vuex';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';

export default {
  name: 'left-bar-user-menu',
  components: {RouterLocalizedLink, LocaleSwitcher},
  data() {
    return {
      langSwitch: false
    }
  },
  computed: {
    dividersClasses() {
      return {
        0: {
          'danger-divider': ['favourites'].includes(this.currentRouteName)
        },
        1: {
          'danger-divider': ['favourites', 'prizes-pool'].includes(this.currentRouteName)
        },
        2: {
          'danger-divider': ['prizes-pool', 'referral_page'].includes(this.currentRouteName)
        },
        3: {
          'danger-divider': ['referral_page'].includes(this.currentRouteName) || ['bonus-points'].includes(this.currentPageSlug)
        },
        4: {
          'danger-divider': ['bonus-points'].includes(this.currentPageSlug)
        }
      }
    }
  },
  methods: {
    ...mapActions('auth', [
      'LOGOUT',
    ]),

    logout() {
      this.LOGOUT();
    }
  }
}
</script>
