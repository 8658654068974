export default {
    namespaced: true,
    state: {
        transactions: [],
        pages_total: 0,
    },

    getters: {
        TRANSACTIONS(state) {
            return state.transactions;
        },
        TRANSACTIONS_PAGES_TOTAL(state) {
            return state.pages_total;
        },
    },

    actions: {
        FETCH_TRANSACTIONS({state, commit}, params) {
            return axios.get('/transactions', {params: params}).then((response) => {
                commit('UPDATE_TRANSACTIONS', response.data.data);
                commit('UPDATE_TRANSACTIONS_PAGES_TOTAL', response.data.meta.last_page);
            });
        },
    },

    mutations: {
        UPDATE_TRANSACTIONS(state, transactions) {
            return (state.transactions = transactions);
        },
        UPDATE_TRANSACTIONS_PAGES_TOTAL(state, pages_total) {
            return (state.pages_total = pages_total);
        },
    }
}
