import store from '@/store';

export default async (to, from, next) => {
    await store.dispatch('promo/FETCH_CURRENT_PROMO', {post: to.params.id, draft: to.query.draft});

    if (_.isNil(store.getters['promo/CURRENT_PROMO'])) {
        next({name: 'promos'})
    }

    next();
};
