<template>
  <div class="store-logo" v-if="CURRENT_WEBSHOP">
    <img :src="CURRENT_WEBSHOP.image_url"
         :title="CURRENT_WEBSHOP.translation.title"
         alt="">
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'webshop-logo',
  computed: {
    ...mapGetters('webshop', [
      'CURRENT_WEBSHOP',
    ]),
  },
}
</script>
