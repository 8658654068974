<template>
  <div class="news-category-feed pb-5">
    <div class="category-container">
      <div class="d-flex">
        <div class="category-image-container pb-1" v-if="CURRENT_ARTICLE_CATEGORY.image_url">
          <img :src="CURRENT_ARTICLE_CATEGORY.image_url"
               :alt="CURRENT_ARTICLE_CATEGORY.translation.title"
               :title="CURRENT_ARTICLE_CATEGORY.translation.title"
               class="category-image"
          >
        </div>
        <div class="category-info-container">
          <div class="category-title pt-2 pb-1" v-html="CURRENT_ARTICLE_CATEGORY.translation.title"></div>
          <div class="category-headline font-weight-bold" v-html="CURRENT_ARTICLE_CATEGORY.translation.headline"></div>
        </div>
      </div>
    </div>
    <el-row type="flex" justify="start">
      <template v-for="(article, i) in ARTICLES" :key="i">
        <news-preview :article="article" :layout="dlayout[i]"></news-preview>
      </template>
    </el-row>
    <div class="centered flex-column my-5" v-if="showChangeLocale">
      <div class="my-2 font-weight-450" v-t="'news.no_news_available_from_this_locale'"></div>
      <div class="my-2">
        <locale-switcher></locale-switcher>
      </div>
    </div>
    <el-row>
      <el-col :span="24">
        <div class="centered mb-5 news-pagination">
          <el-pagination
            layout="prev, pager, next"
            :default-current-page="defaultPage"
            :page-size="ARTICLES_PER_PAGE"
            :total="totalArticles"
            @current-change="goPage"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import NewsPreview from '@/components/feed/news/NewsPreview';
import LocaleSwitcher from '@/components/header/LocaleSwitcher';

export default {
  name: 'NewsByCategoryFeed',
  components: {
    LocaleSwitcher,
    NewsPreview
  },
  data() {
    return {
      defaultPage: 1,
      totalArticles: 0,
      //TODO -- Need optimize
      dlayout: [
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 16, col_xl: 16},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 16, col_xl: 16},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 16, col_xl: 16},
      ]
    }
  },
  computed: {
    ...mapGetters('article_category', [
      'CURRENT_ARTICLE_CATEGORY',
    ]),
    ...mapGetters('article', [
      'ARTICLES',
      'ARTICLES_TOTAL',
      'ARTICLES_CURRENT_PAGE',
      'ARTICLES_PER_PAGE',
    ]),
    ...mapGetters('auth', [
      'LOCALE',
    ]),

    showChangeLocale(){
      return this.ARTICLES.length === 0 && this.LOCALE === 'fr' && this.CURRENT_ARTICLE_CATEGORY.id === 9;
    }
  },
  mounted() {
    this.FETCH_ARTICLES({category_id: this.CURRENT_ARTICLE_CATEGORY.id}).then(() => {
      this.defaultPage = this.ARTICLES_CURRENT_PAGE;
      this.totalArticles = this.ARTICLES_TOTAL;
    });
  },
  methods: {
    ...mapActions('article', [
      'FETCH_ARTICLES',
    ]),

    goPage(page) {
      this.FETCH_ARTICLES({category_id: this.CURRENT_ARTICLE_CATEGORY.id, page: page});
      window.scrollTo(0, 0);
    }
  },
}
</script>
