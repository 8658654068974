import store from '@/store';

export default async (to) => {
    const currentStore = store.getters['store/CURRENT_STORE'];

    const folderId = to.query.id;
    if (undefined === folderId) {
        return {name: 'folders.store', params: {storeSlug: currentStore.translation.route}};
    }

    await store.dispatch('folder/FETCH_CURRENT_FOLDER', folderId);
    const currentFolder = store.getters['folder/CURRENT_FOLDER'];

    if (currentStore.id !== currentFolder.store.id) {
        return {name: 'folders.store', params: {storeSlug: currentStore.translation.route}};
    }
};
