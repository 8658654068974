<template>
  <el-popover
      :placement="placement"
      trigger="click"
      popper-class="p-0 emoji-popover"
  >
    <template #default>
      <picker v-if="showPicker" :per-line="xsOnly ? 8 : 9" :data="emojiIndex" @select="selectEmoji"/>
    </template>
    <template #reference>
      <div class="emoji-picker" @mouseover="showPicker = true">
        <slot name="click-to-show">
          <i class="emoji-btn icon-emoji font-size-20 cursor-pointer"/>
        </slot>
      </div>
    </template>
  </el-popover>
</template>

<script>
import data from 'emoji-mart-vue-fast/data/all.json';
import 'emoji-mart-vue-fast/css/emoji-mart.css';
import {Picker, EmojiIndex} from 'emoji-mart-vue-fast/src';

export default {
  name: 'emoji-picker',
  components: {Picker},
  emits: ['select'],
  props: {
    placement: {
      type: String,
      default() {
        return 'bottom'
      }
    }
  },
  data() {
    return {
      showPicker: false,
      emojiIndex: new EmojiIndex(data)
    };
  },
  methods: {
    selectEmoji(emoji) {
      this.$emit('select', emoji);
    },
  }
}
</script>
