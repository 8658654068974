<template>
  <div class="store-logo">
    <img :src="CURRENT_PRODUCT.image_url"
         :title="CURRENT_PRODUCT.translation.title"
         alt="">
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'product-logo',
  computed: {
    ...mapGetters('product', [
      'CURRENT_PRODUCT',
    ]),
  },
}
</script>
