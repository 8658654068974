<template>
  <video v-if="width && animationType === 'video'"
         :style="style" autoplay loop muted>
    <source :src="url" type="video/mp4" />
  </video>

  <div v-if="width && width < containerWidth && animationType === 'interactive' && position.params"
    class="folder-animation-container"
    :style="style">
    <img class="folder-animation-image" :src="url" :style="croppedStyle" alt="" ref="image_animation">
  </div>
</template>

<script>

import {display} from '@/mixins/display';
import {noop} from '@vueuse/core';

export default {
  name: 'folder-animation',
  props: ['url', 'animationType', 'position', 'containerWidth', 'containerHeight'],
  computed: {
    x1() {
      return this.containerWidth * this.position.x;
    },
    y1() {
      return this.containerHeight * this.position.y;
    },
    width() {
      return this.containerWidth * this.position.width;
    },
    height() {
      return this.containerHeight * this.position.height;
    },
    croppedX1() {
      return - this.position.params.originalWidth * this.position.params.cropX / (this.croppedWidth / this.width);
    },
    croppedY1() {
      return - this.position.params.originalHeight * this.position.params.cropY / (this.croppedHeight / this.height);
    },
    croppedWidth() {
      return this.position.params.originalWidth * this.position.params.cropWidth;
    },
    croppedHeight() {
      return this.position.params.originalHeight * this.position.params.cropHeight;
    },
    croppedWidthCalculated() {
      return this.position.params.originalWidth / (this.croppedWidth / this.width);
    },
    croppedHeightCalculated() {
      return this.position.params.originalHeight / (this.croppedHeight / this.height);
    },
    style() {
      return {
        position: 'absolute',
        'z-index': 104,
        left: this.x1 + 'px',
        top: this.y1 + 'px',
        width: this.width + 'px',
        height: this.height + 'px',
      }
    },
    croppedStyle() {
      if (_.get(this.position, 'params.originalHeight', 0) === 0 &&
          _.get(this.position, 'params.originalWidth', 0) === 0) {
        return {width: '100%'};
      }
      return {
        position: 'absolute',
        'z-index': 104,
        left: this.croppedX1 + 'px',
        top: this.croppedY1 + 'px',
        width: this.croppedWidthCalculated + 'px',
        height: this.croppedHeightCalculated + 'px',
      }
    }
  },
  methods: {
    //
  }
}
</script>

<style lang="scss" scoped>
//.folder-animation {
//  position: absolute;
//}
.folder-animation-container{
  //overflow: hidden;
}
</style>

