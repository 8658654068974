const getDefaultState = () => {
    return {
        webshops: {
            all: [],
        },
        per_page: 250,
        current_page: 0,
        last_page: 1,

        currentWebshop: null,
    }
};

export default {
    namespaced: true,
    state: () => (getDefaultState()),

    getters: {
        WEBSHOPS(state) {
            return state.webshops.all;
        },
        CURRENT_WEBSHOP(state) {
            return state.currentWebshop;
        }
    },

    actions: {
        FETCH_WEBSHOPS({state, commit, dispatch}, filter) {
            let params = _.assign(_.assign({}, {per_page: state.per_page, page: state.current_page}), filter);
            params.page += 1;

            // Clean empty values
            params = _.omitBy(params, (i) => {
                return _.isNumber(i) ? i === 0 : _.isEmpty(i);
            });

            return axios.get('/webshops', {params}).then((response) => {
                commit('PUSH_WEBSHOPS', response.data.data);

                const last_page = _.get(response, 'data.meta.last_page', 1);
                const current_page = _.get(response, 'data.meta.current_page', 1);
                commit('UPDATE_LAST_PAGE', last_page);
                commit('UPDATE_CURRENT_PAGE', current_page);

                if (last_page > current_page) {
                    dispatch('FETCH_WEBSHOPS', filter)
                }
            });
        },
        FETCH_CURRENT_WEBSHOP({state, commit, dispatch}, route) {
            return axios.get('/webshop/' + route).then((response) => {
                commit('UPDATE_CURRENT_WEBSHOP', response.data.data);
            });
        },

        RESET_WEBSHOPS({commit}) {
            commit('SET_DEFAULT_DATA');
        },

        SET_SUBSCRIPTION({state, commit}, {id, ...data}) {
            return axios.put(`/webshops/${id}/subscribe`, data).then((response) => {
                commit('UPDATE_CURRENT_WEBSHOP', response.data.data);
            });
        },
        REMOVE_SUBSCRIPTION({state, commit}, id) {
            return axios.put(`/webshops/${id}/unsubscribe`).then((response) => {
                commit('UPDATE_CURRENT_WEBSHOP', response.data.data);
            });
        },
    },

    mutations: {
        PUSH_WEBSHOPS(state, data) {
            state.webshops.all = _.union(state.webshops.all, data);
        },

        UPDATE_LAST_PAGE(state, page) {
            state.last_page = page;
        },

        UPDATE_CURRENT_PAGE(state, page) {
            state.current_page = page;
        },

        SET_DEFAULT_DATA(state) {
            return _.assign(state, getDefaultState());
        },

        UPDATE_CURRENT_WEBSHOP(state, data) {
            return state.currentWebshop = data;
        }
    }
}
