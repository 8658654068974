<template>
  <div class="notification-listing" v-if="items.length">
    <div class="d-flex align-items-center justify-content-between">
      <h2 v-if="title" class="notification-listing__header">
        {{ $t(title) }}:
      </h2>
      <button class="notification-listing__delete-all" @click="$emit('deleteAll')">
        {{ $t('notifications.delete_' + notificationFilter) }}<i class="icon-delete"/>
      </button>
    </div>
    <template v-for="(notification, i) in items" :key="i">
      <div class="notification mb-3">
        <div class="notification__inner px-2 py-3 pe-4" :class="{'read': notification.read_at}">
          <div class="d-flex justify-content-start align-items-center" @click="$emit('open', notification)">
            <div class="notification__avatar">
              <el-avatar :src="notification.author_avatar" shape="circle"/>
            </div>
            <div>
              <div class="notification__content" v-text="notification.content"/>
              <div class="notification__time mt-2" v-text="notification.time_ago"/>
            </div>
          </div>
          <i class="icon-close-4 notification-close" @click="$emit('delete', notification.id)"/>
        </div>
      </div>
      <el-divider class="notification-listing__divider"/>
    </template>
    <div
      v-if="showFullPreviewBtn"
      class="my-3"
      @click="allNotificationsVisible = true"
    >
      <span class="notification__show-more me-2" v-t="'notifications.show_more'"/>
      <i class="icon-arrow-3 color-primary font-size-11"/>
    </div>
    <infinite-loading v-else @infinite="load" :key="title">
      <template #spinner>
        <div class="spinner-container" v-loading="true"/>
      </template>
      <template #complete>
        <div></div>
      </template>
      <template #error>
        <div></div>
      </template>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'v3-infinite-loading';

export default {
  name: 'NotificationsListing',
  components: {InfiniteLoading},
  props: {
    title: {
      type: String,
      default: ''
    },
    notificationFilter: {
      type: String,
      default: 'all'
    },
    notifications: {
      type: Object,
    },
  },
  data() {
    return {
      previewLimit: 4,
      allNotificationsVisible: false,
    }
  },
  computed: {
    showFullPreviewBtn() {
      return !this.allNotificationsVisible && this.notifications.length > this.previewLimit
    },
    items() {
      return this.allNotificationsVisible ? this.notifications : this.notifications.slice(0, this.previewLimit)
    }
  },
  methods: {
    load(state) {
      this.$emit('load', state)
    }
  },
}
</script>
