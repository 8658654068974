export const validator = {
    data() {
        return {
            errors: []
        }
    },
    methods: {
        backendValidator(rule, value, cb) {
            if (_.has(this.errors, rule.field)) {
                return cb(new Error(_.get(this.errors, `${rule.field}.0`)));
            } else {
                return cb();
            }
        }
    }
}
