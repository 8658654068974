<template>
  <div class="promos-section">
    <default-space>
      <div class="promos-section__container">
        <promos-carousel
          :promos="PROMOS"
          :is-loading="!!PROMOS_PROMISE"
          :custom-width="customWidth"
          :bang-promo-condition="(i) => i < 5"
          show-load-more
          @more="$router.push({name: 'promos'})"
        >
          <template #header>
            <h3 class="promos-carousel-header__title" :class="{'font-size-21': xsOnly}"
                v-html="$t('landing.promos.title')"/>
            <router-localized-link :to="{name: 'promos'}" class="promos-section__link">
              <span v-t="'landing.promos.subtitle'"/>
              <i class="icon-triangle-arrow ms-2"/>
            </router-localized-link>
          </template>
        </promos-carousel>
      </div>
    </default-space>
  </div>
</template>

<script>
import PromosCarousel from '@/components/feed/carousels/PromosCarousel.vue';
import {mapActions, mapGetters} from 'vuex';
import DefaultSpace from '@/views/DefaultSpace.vue';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink.vue';

export default {
  name: 'PromosSection',
  components: {RouterLocalizedLink, DefaultSpace, PromosCarousel},
  computed: {
    ...mapGetters('promo', [
      'PROMOS',
      'PROMOS_PROMISE',
      'PROMOS_TOTAL',
      'PROMOS_IS_COMPLETE',
    ]),
    customWidth() {
      if (this.xsOnly) {
        return '200px'
      } else if (this.lgAndUp) {
        return '25%'
      } else {
        return '33.3%'
      }
    }
  },
  data() {
    return {
      fetchOptions: {
        per_page: 5,
        only_valid_promos: 1,
        only_paid_client: 1,
        vanishes_at: 90,
        folders_included: 0,
        order: 'rating_count',
      },
    }
  },
  mounted() {
    this.RESET_PROMOS();
    this.load()
  },
  methods: {
    ...mapActions('promo', [
      'RESET_PROMOS',
      'FETCH_PROMOS',
    ]),
    ...mapActions('filter', [
      'RESET_FILTERS',
    ]),
    load() {
      return this.FETCH_PROMOS(this.fetchOptions);
    }
  }
}
</script>
