<template>
  <div class="folders-feed">
    <h1 class="folders-feed-header-title color-text-primary">
      <span v-t="'folder.folders_header.1'"></span>
      <span class="color-primary" v-t="'folder.folders_header.2'"></span>
      <span v-t="'folder.folders_header.3'"></span>
    </h1>

    <div class="mb-3" v-if="POPULAR_FOLDERS.length || POPULAR_FOLDERS_PROMISE">
      <folders-carousel vuex-module="folder-popular"
                        is-linked-to-store-page
                        default-list-type="list"
                        show-list-type-switcher
                        :title="$t('folder.popular_folders')"
                        :folders="POPULAR_FOLDERS"
                        :is-loading="!!POPULAR_FOLDERS_PROMISE"
                        :show-load-more-block="!POPULAR_FOLDERS_IS_COMPLETE"
                        @load="loadMore('folder-popular')"></folders-carousel>
    </div>

    <div class="mb-3" v-if="FOLDERS.length || FOLDERS_PROMISE">
      <folders-carousel child-class="bg-secondary"
                        is-linked-to-store-page
                        :title="titleForFilteredFolders"
                        :folders="FOLDERS"
                        :is-loading="!!FOLDERS_PROMISE"
                        @load="loadMore('folder')"></folders-carousel>
    </div>

    <div class="stores-links-header color-text-primary font-weight-medium my-3" v-t="'folder.view_folders_for_stores'"></div>

    <div class="store-image-container">
      <div class="store-image-item" v-for="(store,i) in storesWithFolders" :key="i">
        <router-localized-link :to="{name: 'folders.store', params: {storeSlug: String(store.translation.route)}}">
          <img :src="store.image_url" alt="" :title="store.translation.title">
        </router-localized-link>
      </div>
    </div>

    <div class="py-2" v-if="FOOD_FOLDERS.length || FOOD_FOLDERS_PROMISE">
      <folders-carousel child-class="bg-neutral"
                        vuex-module="folder-food"
                        is-linked-to-store-page
                        :title="$t('folder.food_folders')"
                        :folders="FOOD_FOLDERS"
                        :is-loading="!!FOOD_FOLDERS_PROMISE"
                        @load="loadMore('folder-food')"></folders-carousel>
    </div>
    <div class="py-2" v-if="GARDEN_FOLDERS.length || GARDEN_FOLDERS_PROMISE">
      <folders-carousel child-class="bg-neutral"
                        vuex-module="folder-garden"
                        is-linked-to-store-page
                        :title="$t('folder.garden_folders')"
                        :folders="GARDEN_FOLDERS"
                        :is-loading="!!GARDEN_FOLDERS_PROMISE"
                        @load="loadMore('folder-garden')"></folders-carousel>
    </div>
    <div class="py-2" v-if="MEDIA_FOLDERS.length || MEDIA_FOLDERS_PROMISE">
      <folders-carousel child-class="bg-neutral"
                        vuex-module="folder-media"
                        is-linked-to-store-page
                        :title="$t('folder.media_folders')"
                        :folders="MEDIA_FOLDERS"
                        :is-loading="!!MEDIA_FOLDERS_PROMISE"
                        @load="loadMore('folder-media')"></folders-carousel>
    </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import FoldersCarousel from '@/components/feed/carousels/FoldersCarousel.vue';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';
import {dropper} from '@/mixins/vuex/dropper';

export default {
  name: 'folders-feed',
  components: {RouterLocalizedLink, FoldersCarousel},
  mixins: [dropper],
  data() {
    return {
      fetchOptions: {
        categories: [],
        stores: [],
      }
    }
  },
  computed: {
    ...mapGetters('folder', {
      'FOLDERS': 'FOLDERS',
      'FOLDERS_PROMISE': 'FOLDERS_PROMISE',
    }),
    ...mapGetters('folder-popular', {
      'POPULAR_FOLDERS': 'FOLDERS',
      'POPULAR_FOLDERS_PROMISE': 'FOLDERS_PROMISE',
      'POPULAR_FOLDERS_IS_COMPLETE': 'FOLDERS_IS_COMPLETE',
    }),
    ...mapGetters('folder-food', {
      'FOOD_FOLDERS': 'FOLDERS',
      'FOOD_FOLDERS_PROMISE': 'FOLDERS_PROMISE',
    }),
    ...mapGetters('folder-garden', {
      'GARDEN_FOLDERS': 'FOLDERS',
      'GARDEN_FOLDERS_PROMISE': 'FOLDERS_PROMISE',
    }),
    ...mapGetters('folder-media', {
      'MEDIA_FOLDERS': 'FOLDERS',
      'MEDIA_FOLDERS_PROMISE': 'FOLDERS_PROMISE',
    }),
    ...mapGetters('filter', [
      'FILTERS_VALUES',
    ]),
    ...mapGetters('store', [
      'STORES_WITH_FOLDERS',
    ]),
    ...mapGetters('category', [
      'CATEGORIES',
    ]),

    storesWithFolders() {
      return _.sortBy(this.STORES_WITH_FOLDERS, 'translation.title');
    },

    titleForFilteredFolders() {
      const thisElem = this;
      const filteredStores = _.filter(this.STORES_WITH_FOLDERS, function (o) {
        return thisElem.FILTERS_VALUES.stores_filter.includes(o.id.toString())
      });

      const filteredCategories = _.filter(this.CATEGORIES, function (o) {
        return thisElem.FILTERS_VALUES.categories_filter.includes(o.id.toString())
      });

      if(filteredCategories.length === 1 && filteredStores.length === 0){
        return this.$t('folder.filtered_folders_header_options.category_filtered', {
          category: _.get(filteredCategories, '[0].translation.title')
        });
      }

      if(filteredCategories.length === 0 && filteredStores.length === 1){
        return this.$t('folder.filtered_folders_header_options.store_filtered', {
          store: _.get(filteredStores, '[0].translation.title')
        });
      }

      if(filteredCategories.length === 1 && filteredStores.length === 1){
        return this.$t('folder.filtered_folders_header_options.category_and_store_filtered', {
          category: _.get(filteredCategories, '[0].translation.title'),
          store: _.get(filteredStores, '[0].translation.title'),
        });
      }

      if(filteredCategories.length === 1 && filteredStores.length > 1){
        return this.$t('folder.filtered_folders_header_options.category_and_stores_filtered', {
          category: _.get(filteredCategories, '[0].translation.title'),
        });
      }

      if(filteredCategories.length > 1 && filteredStores.length === 1){
        return this.$t('folder.filtered_folders_header_options.categories_and_store_filtered', {
          store: _.get(filteredStores, '[0].translation.title'),
        });
      }

      if (filteredCategories.length > 1 && filteredStores.length > 1) {
        return this.$t('folder.filtered_folders_header_options.stores_and_categories_filtered');
      }

      if (filteredCategories.length > 1) {
        return this.$t('folder.filtered_folders_header_options.categories_filtered');
      }

      if (filteredStores.length > 1) {
        return this.$t('folder.filtered_folders_header_options.stores_filtered');
      }

      return this.$t('folder.filtered_folders');
    }
  },
  mounted() {
    this.prepareFetchOptions();
    this.loadFoldersData();
  },
  methods: {
    prepareFetchOptions() {
      this.fetchOptions.categories = this.FILTERS_VALUES.categories_filter;
      this.fetchOptions.stores = this.FILTERS_VALUES.stores_filter;
    },
    async loadFoldersData() {
      if (this.fetchOptions.categories.length || this.fetchOptions.stores.length) {
        await this.loadFoldersDataItem('folder');
      } else {
        await this.loadFoldersDataItem('folder-popular');
        await this.loadFoldersDataItem('folder-food');
        await this.loadFoldersDataItem('folder-garden');
        await this.loadFoldersDataItem('folder-media');
      }
    },
    loadFoldersDataItem(module) {
      const fetchOptions = _.assign({}, this.fetchOptions);
      const moduleParams = {
        'folder': {
          include_filters: 1,
        },
        'folder-popular': {
          // folder_client_types: [this.$consts.FOLDER_CLIENT_TYPES.FOLDER_CLIENT_TYPE_PAID],
          include_filters: 1,
          per_page: 10,
          order: 'primary'
        },
        'folder-food': {
          categories: ['3', '15'],
        },
        'folder-garden': {
          categories: ['8'],
        },
        'folder-media': {
          categories: ['4'],
        },
      };
      let params = moduleParams[module];
      if (params.categories !== undefined) {
        if (fetchOptions.categories.length && _.difference(fetchOptions.categories, params.categories).length !== 0) {
          return Promise.resolve();
        }
        _.unset(fetchOptions, 'categories');
      }
      params = _.assign(params, fetchOptions);

      return this.$store.dispatch(module + '/FETCH_FOLDERS', params);
    },
    loadMore(module) {
      this.loadFoldersDataItem(module);
    }
  },
  watch: {
    FILTERS_VALUES: {
      // the callback will be called immediately after the start of the observation
      deep: true,
      handler(newVal, oldVal) {
        this.resetAllFoldersModules();
        this.prepareFetchOptions();
        this.loadFoldersData();
      }
    }
  }
}
</script>
