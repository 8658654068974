<template>
  <div class="bonus-prizes">
    <div class="prizes-header-container d-flex justify-content-between align-items-center">
      <div class="prizes-header color-text-primary">
        {{ $t('prizes.prizes_pool') }}
      </div>
      <div class="all-about-promocoins">
        <router-localized-link class="text-dark" v-t="!xsOnly? 'prizes.all_about_promocoins': 'prizes.all_about_promocoins_mobile'" :to="getPagePathBySlug('bonus-points')"></router-localized-link>
      </div>
    </div>

    <div class="promocoins-container text-center">
      <div class="promocoins-card-container text-center">
        <div class="prizes-card-header2" v-t="'prizes.your_balance_is_currently'"></div>

        <div class="prizes-balance">
          {{ USER.account.available }}
        </div>

        <div class="prizes-balance-coins" v-t="'prizes.points'"></div>

        <div class="collected-promocoins cursor-pointer my-3">
          <u @click="showTransactionsDialog = true" v-t="'prizes.overview_of_your_collected_promocoins'"></u>
        </div>
      </div>
    </div>

    <bonus-transactions-dialog v-model:show-dialog="showTransactionsDialog"/>

    <div class="prizes-header2 color-text-primary my-3" v-t="'prizes.redeem_great_prizes'"></div>

    <div class="prizes-text color-text-primary my-3" v-t="'prizes.more_coins_text'"></div>

    <div class="bonus-prizes-container">
      <el-row type="flex" justify="start">
        <el-col
          v-for="(prize,i) in PRIZES"
          :key="i"
          :span="xsOnly ? 12 : 8"
          :xl="8"
        >
          <bonus-prize :prize="prize"/>
        </el-col>
      </el-row>
    </div>

    <div class="prizes-header2 color-text-primary my-3" v-t="'prizes.how_doest_it_work'"></div>

    <div class="prizes-text my-3" v-html="$t('prizes.how_doest_it_work_text')"></div>

<!--    <el-divider></el-divider>-->

<!--    <div class="bonus-prizes-question mt-2">-->
<!--      <i class="icon-plus-11"></i>&nbsp; <span v-t="'prizes.how_doest_it_work_options.option1'"></span>-->
<!--    </div>-->

<!--    <el-divider class="mt-3"></el-divider>-->

<!--    <div class="bonus-prizes-question mt-2">-->
<!--      <i class="icon-plus-11"></i>&nbsp; <span v-t="'prizes.how_doest_it_work_options.option2'"></span>-->
<!--    </div>-->

<!--    <el-divider class="mt-3"></el-divider>-->

<!--    <div class="bonus-prizes-question mt-2">-->
<!--      <i class="icon-plus-11"></i>&nbsp; <span v-t="'prizes.how_doest_it_work_options.option3'"></span>-->
<!--    </div>-->

<!--    <el-divider class="mt-3"></el-divider>-->

<!--    <div class="bonus-prizes-question mt-2">-->
<!--      <i class="icon-plus-11"></i>&nbsp; <span v-t="'prizes.how_doest_it_work_options.option4'"></span>-->
<!--    </div>-->

<!--    <el-divider class="mt-3"></el-divider>-->

<!--    <div class="bonus-prizes-question mt-2">-->
<!--      <i class="icon-plus-11"></i>&nbsp; <span v-t="'prizes.how_doest_it_work_options.option5'"></span>-->
<!--    </div>-->

<!--    <el-divider class="mt-3"></el-divider>-->

<!--    <div class="bonus-prizes-question mt-2">-->
<!--      <i class="icon-plus-11"></i>&nbsp; <span v-t="'prizes.how_doest_it_work_options.option6'"></span>-->
<!--    </div>-->

    <div class="prizes-header2 color-text-primary my-3" v-t="'prizes.gift_system'"></div>

    <div class="prizes-text my-3" v-html="$t('prizes.gift_system_text')"></div>

    <table class="my-3 bonus-prizes-table">
      <thead>
      <tr>
        <th>{{ $t('prizes.gift_system_table.headers.1') }}</th>
        <th>{{ $t('prizes.gift_system_table.headers.2') }}</th>
        <th>{{ $t('prizes.gift_system_table.headers.3') }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{ $t('prizes.gift_system_table.raws.1', {amount: 500}) }}</td>
        <td>{{ $t('prizes.gift_system_table.raws.2', {amount: 5}) }}</td>
        <td>{{ $t('prizes.gift_system_table.raws.3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('prizes.gift_system_table.raws.1', {amount: 1500}) }}</td>
        <td>{{ $t('prizes.gift_system_table.raws.2', {amount: 10}) }}</td>
        <td>{{ $t('prizes.gift_system_table.raws.3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('prizes.gift_system_table.raws.1', {amount: 5000}) }}</td>
        <td>{{ $t('prizes.gift_system_table.raws.2', {amount: 35}) }}</td>
        <td>{{ $t('prizes.gift_system_table.raws.3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('prizes.gift_system_table.raws.1', {amount: 10000}) }}</td>
        <td>{{ $t('prizes.gift_system_table.raws.2', {amount: 50}) }}</td>
        <td>{{ $t('prizes.gift_system_table.unlimited') }}</td>
      </tr>
      </tbody>
    </table>

    <div class="prizes-header2 color-text-primary my-3" v-t="'prizes.warranties_header'"></div>

    <div class="prizes-text mt-3 mb-5" v-html="$t('prizes.warranties_text')"></div>
  </div>
</template>

<script>
import BonusPrize from '@/components/feed/bonuses/BonusPrize';
import BonusTransactionsDialog from '@/components/feed/bonuses/BonusTransactionsDialog';
import {mapActions, mapGetters} from 'vuex';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';

export default {
  name: 'bonus-prizes',
  components: {BonusPrize, BonusTransactionsDialog, RouterLocalizedLink},
  data() {
    return {
      showTransactionsDialog: false
    }
  },
  mounted() {
    this.FETCH_PRIZES();
  },
  computed: {
    ...mapGetters('prize', [
      'PRIZES',
    ]),
    ...mapGetters('auth', [
      'USER',
    ]),
  },
  methods: {
    ...mapActions('prize', [
      'FETCH_PRIZES'
    ]),
  },
}
</script>
