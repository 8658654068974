export default {
  namespaced: true,
  state: {
    dialogs: {
      auth: {visible: false, tab: 'default'},
      'create-post': {visible: false},
      'main-menu': {visible: false},
      'user-menu': {visible: false},
      'verify-email': {visible: false},
    },
  },

  getters: {
    DIALOGS(state) {
      return state.dialogs;
    },

    IS_VISIBLE: (state) => (name) => {
      return state.dialogs[name].visible
    },
  },

  actions: {
    TOGGLE({state, commit}, {name}) {
      commit('UPDATE_VISIBILITY', {name, visible: !state.dialogs[name].visible})

      const xsOnly = Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 768;
      const menuName = name;
      if ((menuName === 'user-menu' || 'main-menu') && xsOnly) {
        if (state.dialogs[menuName].visible) {
          document.body.style.overflow = 'hidden';
          return
        }
        document.body.style.overflow = '';
      }
    },

    SHOW({commit}, {name, tab}) {
      commit('UPDATE_VISIBILITY', {name, visible: true})

      if (tab) {
        commit('UPDATE_TAB', {name, tab})
      }
    },

    HIDE({commit}, name) {
      commit('UPDATE_VISIBILITY', {name, visible: false})
    },

    CHANGE_TAB({commit}, {name, tab}) {
      commit('UPDATE_TAB', {name, tab})
    },

    CLOSE_ALL({state, commit}) {
      Object.keys(state.dialogs).map((key) => commit('UPDATE_VISIBILITY', {name: key, visible: false}))
    },

    CLOSE_EXCEPT({state, commit}, {name}) {
      Object.keys(state.dialogs).map((key) => commit('UPDATE_VISIBILITY', {name: key, visible: key === name}))
    }
  },
  mutations: {
    UPDATE_VISIBILITY({dialogs}, {name, visible}) {
      dialogs[name].visible = visible;
    },

    UPDATE_TAB({dialogs}, {name, tab}) {
      dialogs[name].tab = tab;
    },
  },
};
