<template>
  <div>
    <div class="w-100 login-label" v-t="'auth.register_step.pre_last_step'"/>

    <el-form
      :model="form"
      label-position="top"
      ref="form"
      :rules="rules"
    >
      <el-form-item prop="email" :label="$t('auth.register_step.your_email')">
        <el-input
            class="rounded-input"
            placeholder="my@email.com"
            :size="inputsSize"
            :disabled="defaultEmail.length > 0"
            v-model="form.email">
        </el-input>
      </el-form-item>

      <el-row v-if="emailIsFake">
        <el-col :span="12">
          <el-form-item prop="fname" :label="$t('auth.fname')">
            <el-input
                class="rounded-input me-2"
                v-model="form.fname"
                :size="inputsSize">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="lname" :label="$t('auth.lname')">
            <el-input
                class="rounded-input ms-2"
                v-model="form.lname"
                :size="inputsSize">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item prop="gender" :label="$t('auth.register_step.gender')">
        <el-select v-model="form.gender" class="rounded-select">
          <el-option
              :key="1"
              :label="$t('auth.register_step.male')"
              :value="1">
          </el-option>
          <el-option
              :key="0"
              :label="$t('auth.register_step.female')"
              :value="0">
          </el-option>
        </el-select>
      </el-form-item>

      <el-button
        type="primary"
        class="w-100"
        @click="validate"
      >
        <span v-t="'auth.register_step.to_last_step'"/>
      </el-button>
    </el-form>
  </div>
</template>

<script>
import {validator} from '@/mixins/validator';
import validateStep from '@/mixins/auth/validateStep';

export default {
  name: 'register-step-third',
  mixins: [validator, validateStep],
  props: {
    defaultEmail: {
      type: String,
      default: ''
    },
    defaultFname: {
      type: String,
      default: ''
    },
    defaultLname: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      step: 3,
      form: {
        email: this.defaultEmail,
        fname: this.defaultFname,
        lname: this.defaultLname,
        gender: 0,
        birthday: '',
        postal_code: ''
      },
      rules: {
        email: [
          {validator: this.backendValidator}
        ],
        fname: [
          {validator: this.backendValidator}
        ],
        lname: [
          {validator: this.backendValidator}
        ],
        gender: [
          {validator: this.backendValidator}
        ],
      }
    }
  },
  computed: {
    emailIsFake() {
      return this.defaultEmail.includes('privaterelay.appleid.com');
    }
  }
}
</script>
