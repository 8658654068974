<template>
  <div class="sidebar">
    <div class="side-bar-item">
      <div class="side-bar-item-title mb-3" v-t="'profile.stats.activity'"></div>
      <p>
        <b>{{ PROFILE.posts_count }}</b>&nbsp;
        <span v-t="'profile.stats.posts_count'"></span>
      </p>
      <p>
        <b>{{ PROFILE.promos_count }}</b>&nbsp;
        <span v-t="'profile.stats.promos_count'"></span>
      </p>
      <p>
        <b>{{ PROFILE.bargains_count }}</b>&nbsp;
        <span v-t="'profile.stats.bargains_count'"></span>
      </p>
      <p>
        <b>{{ PROFILE.questions_count }}</b>&nbsp;
        <span v-t="'profile.stats.questions_count'"></span>
      </p>
    </div>
    <div class="side-bar-item">
      <div class="side-bar-item-title mb-3" v-t="'profile.stats.popularity'"></div>
      <p>
        <b>{{ PROFILE.hits_to_posts_count }}</b>&nbsp;
        <span v-t="'profile.stats.hits_to_posts_count'"></span>
      </p>
      <p>
        <b>{{ PROFILE.comments_to_posts_count }}</b>&nbsp;
        <span v-t="'profile.stats.comments_to_posts_count'"></span>
      </p>
      <p>
        <b>{{ PROFILE.favourites_count }}</b>&nbsp;
        <span v-t="'profile.stats.favourites_count'"></span>
      </p>
    </div>
    <div class="side-bar-item filter-container">
      <div class="filter-header side-bar-item-title mb-3" @click="followersActive = !followersActive">
        <i :class="followersActive ? 'icon-minus' : 'icon-plus-1'"></i>&nbsp;
        <span class="filter-header__text" v-t="'profile.achievements.followers_count'"/>
      </div>
      <div class="filter-options" v-if="followersActive">
        <template v-for="(follower, i) in followersLimited" :key="i">
          <div class="followers-item">
            <router-link :to="{ name: 'profile', params: { userId: follower.id }}">
              <el-avatar :src="follower.avatar_url" shape="circle"
                         class="logged-user-avatar">
              </el-avatar>

              {{ follower.full_name }}
            </router-link>
          </div>
        </template>
        <div class="followers-item" v-if="FOLLOWERS.length > followersLimited.length">
          ...
        </div>
      </div>
    </div>
    <div class="side-bar-item filter-container">
      <div class="filter-header side-bar-item-title mb-3" @click="followingActive = !followingActive">
        <i :class="followingActive ? 'icon-minus' : 'icon-plus-1'"></i>&nbsp;
        <span class="filter-header__text" v-t="'profile.unfollow_btn'"/>
      </div>
      <div class="filter-options" v-if="followingActive">
        <template v-for="(following, i) in followingLimited" :key="i">
          <div class="following-item">
            <router-link :to="{ name: 'profile', params: { userId: following.id }}">
              <el-avatar :src="following.avatar_url" shape="circle"
                         class="logged-user-avatar">
              </el-avatar>

              {{ following.full_name }}
            </router-link>
          </div>
        </template>
        <div class="following-item" v-if="FOLLOWING.length > followingLimited.length">
          ...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'profile-stats',
  data() {
    return {
      followersActive: true,
      followingActive: true,
    }
  },
  computed: {
    ...mapGetters('profile', [
      'PROFILE',
      'FOLLOWERS',
      'FOLLOWING'
    ]),
    followersLimited() {
      return _.take(this.FOLLOWERS, 20);
    },
    followingLimited() {
      return _.take(this.FOLLOWING, 20);
    }
  },
}
</script>
