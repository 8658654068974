<template>
  <el-row justify="center" class="bg-secondary">
    <el-col :span="23">
      <div class="container news-container">
        <el-row type="flex" justify="space-between">
          <el-col :span="24">
            <div class="main-container-wrapper">
              <h1 class="news-header-title color-text-primary">
                <span v-t="'news.news_title'"></span>
              </h1>
              <div class="blog-category-wrap">
                <categories-list></categories-list>
              </div>
              <news-by-category-feed v-if="CURRENT_ARTICLE_CATEGORY.id"></news-by-category-feed>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import CategoriesList from '@/components/feed/news/CategoriesList';
import NewsByCategoryFeed from '@/views/news/NewsByCategoryFeed';
import {mapActions, mapGetters} from 'vuex';
import ArticleCategoryRoute from '@/router/guards/ArticleCategoryRoute';

export default {
  name: 'NewsCategoryView',
  components: {
    CategoriesList,
    NewsByCategoryFeed
  },
  computed: {
    ...mapGetters('article_category', [
      'CURRENT_ARTICLE_CATEGORY',
    ]),
  },
  // need this because Guards doesnt work when the same route params changing
  async beforeRouteUpdate(to, from, next) {
    if (from.params.categorySlug !== to.params.categorySlug) {
      await ArticleCategoryRoute(to, from, next);
    }
  },
  beforeMount() {
    this.RESET_ARTICLES();
  },
  methods: {
    ...mapActions('article', [
      'RESET_ARTICLES',
    ]),
  },
}
</script>
