<template>
  <div class="switch-container d-flex align-items-center justify-content-start">
    <button 
      class="locale-dropdown__btn"
      @click="changeLocale"
    >
      <samp
        class="locale-dropdown__nl"
        :class="{ 'locale-dropdown__changes__nl': localeSwitchValue }"
      >NL</samp> 
      <div
        class="locale-dropdown__container"
        :class="{ 'locale-dropdown__changes__container': localeSwitchValue }"
      >
        <i
          class="font-size-17 locale-dropdown__position"
          :class="{'icon-Dutch': !localeSwitchValue, 'icon-French': localeSwitchValue}"
        >
          <span class="path1"/>
          <span class="path2"/>
          <span class="path3"/>
        </i>
      </div>
      <samp
        class="locale-dropdown__fr"
        :class="{ 'locale-dropdown__changes__fr': localeSwitchValue }"
      >FR</samp> 
    </button>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  name: 'locale-switcher',
  data() {
    return {
      localeSwitchValue: false
    }
  },
  beforeMount() {
    if (!this.PAGES_LOADED) {
      this.FETCH_PAGES();
    }
  },
  mounted() {
    this.localeSwitchValue = this.localeSwitch;
  },
  computed: {
    ...mapGetters('auth', [
      'LOCALE',
      'GUEST',
    ]),
    ...mapGetters('page', [
      'PAGES',
      'PAGES_LOADED',
    ]),
    localeSwitch() {
      return 'fr' === this.LOCALE;
    }
  },
  methods: {
    ...mapActions('auth', [
      'UPDATE_LOCALE',
    ]),
    ...mapActions('page', [
      'FETCH_PAGES',
    ]),
    changeLocale() {
      const newLocale = this.localeSwitch ? 'nl' : 'fr';
      if (this.GUEST) {
        window.open(this.resolveRouteForLocale(this.$route, newLocale), '_self');
        return;
      }
      this.UPDATE_LOCALE(newLocale).then(() => {
        window.open(this.resolveRouteForLocale(this.$route, newLocale), '_self');
      });
    },
    resolveRouteForLocale(currentRoute, newLocale) {
      if (currentRoute.name === 'not_found') {
        const path = this.resolvePageForLocale(currentRoute, newLocale);
        if (typeof path === 'string' || path instanceof String) {
          return this.$localizePath(path, newLocale).replace('/nl', '');
        }
      }
      return this.$localizePath(this.$router.resolve({name: currentRoute.name, params: currentRoute.params}).href, newLocale).replace('/nl', '');
    },
    resolvePageForLocale(currentRoute, newLocale) {
      const route = _.get(currentRoute, 'params.pathMatch[0]', false);
      if (!route) {
        return false;
      }

      const oldPage = _.find(this.PAGES, {'route': route}, false);
      if (!oldPage) {
        return false;
      }

      const newPage = _.find(this.PAGES, function (need) {
        return need.slug === oldPage.slug && need.locale.slug === newLocale;
      }, false);
      if (!newPage) {
        return false;
      }

      const params = currentRoute.params;
      _.set(params, 'pathMatch[0]', newPage.route);
      return this.$router.resolve({name: currentRoute.name, params: params}).href;
    }
  },
  watch: {
    LOCALE(newValue) {
      this.localeSwitchValue = this.localeSwitch;
    }
  }
}
</script>

<style scoped>
.switch-container {
  min-height: 20px;
}
</style>
