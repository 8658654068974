<template>
  <div v-if="Object.keys(filterOptions).length || mustHave">
    <div :class="'filter-container ' + customClass">
      <slot name="header">
        <div class="filter-header" @click="openFilter" :class="{'active': filterOpened}">
          <span class="filter-header__text" v-text="filterLabel"/>
          <i class="trans-2" :class="filterOpened ? 'icon-arrow-3 rotate-90' : 'icon-arrow-3'"/>
        </div>
      </slot>
      <div class="filter-options" v-if="filterOpened">
        <el-checkbox-group v-model="checkedList" :max="max" @change="filterEvent" class="ms-2">
          <div v-for="(filterOption, i) in shortFiltersList" :key="i">
            <el-checkbox @click="setLastClickedOption(filterOption.key)" :key="i" :label="filterOption.key" class="big">
              {{ filterOption.label }}
            </el-checkbox>
          </div>
        </el-checkbox-group>

        <div class="more-btn mt-2">
          <span
            class="cursor-pointer"
            v-if="shortLength < Object.keys(filterOptions).length"
            v-text="shortFilters ? $t('filters.show_more') : $t('filters.show_less')"
            @click="shortFilters = !shortFilters"
          />
        </div>
      </div>
      <el-divider v-if="!hideDivider" class="bold-divider my-3"/>
    </div>
  </div>
</template>

<script>
import {needAuth} from '@/mixins/auth/needAuth';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'base-filter',
  mixins: [needAuth],
  props: {
    customClass: {
      type: String,
      default: ''
    },
    mustHave: {
      type: Boolean,
      default: false
    },
    filterKey: {
      type: String,
    },
    filterLabel: {
      type: String,
      default: '',
    },
    filterOptions: {
      type: Object,
      default() {
        return {}
      }
    },
    /**
     * Options will be set in custom order
     */
    customOrder: {
      type: Boolean,
      default: false
    },
    defaultChecked: {
      type: Array,
      default() {
        return []
      }
    },
    shortLength: {
      type: Number,
      default: 6,
    },
    max: {
      type: Number,
      default: undefined,
    },
    hideDivider: {
      type: Boolean,
      default: false,
    },
    onlyOneChoise: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * mapGetters
     */
    ...mapGetters('filter', [
      'OPENED_FILTERS',
      'ACTIVE_FILTERS'
    ]),
    /**
     * @returns {*|Iterator|AsyncIterator}
     */
    shortFiltersList() {
      return this.shortFilters ? _.take(this.filtersList, this.shortOptionsLength) : this.filtersList;
    },
    /**
     * Filters list
     * @returns {Object|*|*[]}
     */
    filtersList() {
      const options = this.filterOptions
      const keys = Object.keys(options);

      if (_.isArray(options)) {
        return options;
      }

      let resultArray = [];
      _.forEach(keys, function (key) {
        resultArray.push({
          key: key,
          label: options[key],
        });
      });

      if (!this.customOrder) {
        resultArray = _.sortBy(resultArray, 'label');
      }

      if (this.shortFilters) {
        resultArray = _.take(resultArray, this.shortLength);
      }

      return resultArray;
    },
    /**
     * Is filter opened
     * @returns {*}
     */
    filterOpened() {
      return _.get(this.OPENED_FILTERS, this.filterKey, true);
    }
  },
  data() {
    return {
      shortFilters: true,
      shortOptionsLength: this.shortLength,
      lastClickedOption: '',
      checkedList: this.defaultChecked,
    };
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_OPENED',
    ]),
    filterEvent() {
      if (this.onlyOneChoise && this.lastClickedOption) {
        this.checkedList = [this.lastClickedOption]
      }

      this.$emit('filter', this.checkedList);
      this.emitter.emit('close-filter')
    },
    setLastClickedOption(optionName) {
      if (!this.checkedList.includes(optionName)) {
        this.lastClickedOption = optionName
      } else {
        this.lastClickedOption = ''
      }
    },
    setCheckedList(values) {
      this.checkedList = values;
    },
    openFilter() {
      const filter = {};
      _.set(filter, this.filterKey, !this.filterOpened);
      this.SET_FILTER_OPENED(filter);
    }
  },
  watch: {
    defaultChecked() {
      this.checkedList = this.defaultChecked;
    },
  }
}
</script>
