<template>
  <el-dialog
    custom-class="locale-switcher-dialog primary-header-dialog"
    width="100vw"
    v-model="showLanguageSwitcher"
    :show-close="true"
    :close-on-click-modal="true"
    :close-on-press-escape="true"
    :lock-scroll="false"
    @close="closeLanguageDialog"
    center>

    <div class="mt-3 centered">
      <locale-switcher></locale-switcher>
    </div>

    <template v-slot:header>
      <span class="font-weight-medium font-size-19">
        {{ $t("header.locale_dialog.change_lang_header") }}
      </span>
    </template>

    <template #footer>
      <div class="mb-3 centered">
        <el-button plain
                   type="secondary"
                   class="font-weight-medium"
                   @click="showLanguageSwitcher = false">
          {{ $t('header.locale_dialog.cancel_btn') }}
        </el-button>
      </div>

      <div class="centered">
        <el-checkbox class="mx-3 big" v-model="neverAskAgainCheckbox">
          <span class="text-nowrap">
            {{ $t('header.locale_dialog.dont_ask_again_btn') }}
          </span>
        </el-checkbox>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import LocaleSwitcher from '@/components/header/LocaleSwitcher';

export default {
  name: 'locale-switcher-dialog',
  components: {LocaleSwitcher},
  data() {
    return {
      neverAskAgainCheckbox: false,
      showLanguageSwitcher: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.checkBrowserDefaultLanguage();
    }, 1500);
  },
  methods: {
    closeLanguageDialog() {
      const timeToIgnore = this.neverAskAgainCheckbox ? '1y' : 3600; // 1year vs 1hour

      cookies.set('ignore_language_dialog', true, timeToIgnore);
    },
    checkBrowserDefaultLanguage() {
      if (cookies.get('ignore_language_dialog')) {
        return;
      }

      let browserLanguage = (navigator.language || navigator.userLanguage).substr(0, 2);
      if (browserLanguage.toLowerCase() !== 'fr') {
        browserLanguage = 'nl';
      }

      if (browserLanguage !== cookies.get('locale')) {
        this.showLanguageSwitcher = true;
      }
    }
  }
}
</script>
