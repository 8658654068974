/**
 * Before using init `entityData` computed property in parent file
 * @type {{computed: {morphableData(): {morphable_type: string, morphable_id}}, methods: {processHit(*): void, processFavourite(): void}}}
 */
export const actions = {
    computed: {
        /**
         * @returns {{morphable_type: string, morphable_id}}
         */
        processData() {
            return {
                morphable_type: this.entityData.morphable_type,
                morphable_id: this.entityData.entity.id,
            };
        }
    },
    methods: {
        /**
         * processHit
         * @param withoutHitRemove
         * @returns {*|Promise<any>}
         */
        processHit(withoutHitRemove) {
            if (this.triggerLoginDialog()) {
                return;
            }

            if (withoutHitRemove === true && this.entityData.entity.is_hit) {
                return;
            }

            return this.entityData.entity.is_hit
                ? this.dispatchPromoAction('REMOVE_HIT')
                : this.dispatchPromoAction('SET_HIT');
        },
        /**
         * processDislike
         * @param withoutDislikeRemove
         * @returns {*|Promise<any>}
         */
        processDislike(withoutDislikeRemove = false) {
            if (this.triggerLoginDialog()) {
                return;
            }

            if (withoutDislikeRemove === true && this.entityData.entity.is_hit) {
                return;
            }

            return this.entityData.entity.is_dislike
                ? this.dispatchPromoAction('REMOVE_DISLIKE')
                : this.dispatchPromoAction('SET_DISLIKE')
        },
        /**
         * processFavourite
         * @returns {*|Promise<any>}
         */
        processFavourite() {
            if (this.triggerLoginDialog()) {
                return;
            }

            return this.entityData.entity.is_favourite
                ? this.dispatchPromoAction('REMOVE_FAVORITE')
                : this.dispatchPromoAction('SET_FAVORITE')
        },
        /**
         * Subscribe / Unsubscribe
         * @param isSubscribed
         * @returns {*|Promise<any>}
         */
        processSubscription(isSubscribed) {
            this.webshopLoginDialog();
            if (this.triggerLoginDialog()) {
                return;
            }

            return isSubscribed
                ? this.dispatchPromoAction('REMOVE_SUBSCRIPTION')
                : this.dispatchPromoAction('SET_SUBSCRIPTION')
        },
        /**
         * Hide
         */
        processHide() {
            this.webshopLoginDialog();
            if (this.triggerLoginDialog()) {
                return;
            }

            return this.dispatchPromoAction('HIDE')
        },
        /**
         * Restore
         */
        processRestore() {
            return this.dispatchPromoAction('RESTORE_HIDDEN')
        },
        /**
         * @param action
         * @returns {*}
         */
        dispatchPromoAction(action) {
            return this.$store.dispatch(this.vuexModule + '/' + action, this.processData);
        }
    }
};
