import store from '@/store';

export default async (to, from, next) => {
    await store.dispatch('promo/FETCH_CURRENT_PROMO', {online_promo: to.params.id});

    if (_.isNil(store.getters['promo/CURRENT_PROMO'])) {
        next({name: 'promos'})
    }

    next();
};
