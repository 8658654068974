/**
 * Constants
 * @type {{KIND: {DEFAULT: number, QUESTION: number, DISCOUNT: number}, POST_TYPES: {CASHBACK: number, SALE: number, FREE_PRODUCTS: number, ECOUPON: number, BARGAIN: number, REPORT: number, DEFAULT: number, DISCOUNT: number}}}
 */
const constants = {
    KIND: {
        DEFAULT: 1,
        DISCOUNT: 2,
        QUESTION: 3,
    },
    POST_TYPES: {
        // Parent types
        REPORT: 1,
        QUESTION: 6,
        BARGAIN: 7,
        // Subtypes
        CASHBACK: 2,
        ECOUPON: 3,
        DISCOUNT: 5,
        SALE: 8,
        DEFAULT: 9,
        FREE_PRODUCTS: 10
    },
    FOLDER_CLIENT_TYPES: {
        FOLDER_CLIENT_TYPE_PAID: 1,
        FOLDER_CLIENT_TYPE_SEMI_PAID: 2,
        FOLDER_CLIENT_TYPE_FREE: 3,
    }
};

export default constants;