<template>
  <default-grid :show-right-container="false" :show-left-container="false">
    <template v-slot:left-container>
      <left-bar show-user-menu></left-bar>
    </template>
    <template v-slot:main-container>
      <char-sorted-entities header="brand.all_brands"
                            :data="BRANDS"
                            :show-load="true"
                            :preview-count="BRANDS_PREVIEW_COUNT"
                            @load-char="loadMore"
                            route-name="brand.show"
                            route-param-name="brandSlug">
      </char-sorted-entities>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from '@/views/DefaultGrid';
import CharSortedEntities from '@/components/feed/CharSortedEntities';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'BrandsView',
  components: {CharSortedEntities, DefaultGrid},
  computed:{
    ...mapGetters('brand', [
      'BRANDS',
      'BRANDS_PREVIEW_COUNT',
    ]),
  },
  mounted() {
    this.FETCH_BRANDS({preview_count: this.BRANDS_PREVIEW_COUNT});
  },
  methods: {
    ...mapActions('brand', [
      'FETCH_BRANDS',
    ]),

    loadMore(char){
      this.FETCH_BRANDS({starts_from: char});
    }
  }
}
</script>
