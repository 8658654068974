export default {
    namespaced: true,
    state: {
        bonus_actions: {
            all: [],
            types: {},
            promise: null
        },
    },

    getters: {
        BONUS_ACTIONS(state) {
            return state.bonus_actions.all;
        },

        BONUS_ACTION_TYPES(state) {
            return state.bonus_actions.types;
        },
    },

    actions: {
        async FETCH_BONUS_ACTIONS({ state, commit }) {
            if (state.bonus_actions.promise) {
                return state.bonus_actions.promise;
            }

            const promise = axios.get('/bonus-actions').then((response) => {
                commit('UPDATE_BONUS_ACTIONS', response.data.data);
                commit('UPDATE_BONUS_ACTION_TYPES', response.data.types);
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_BONUS_ACTIONS', null);
            });

            commit('UPDATE_PROMISE_BONUS_ACTIONS', promise);
            return promise;
        },
    },

    mutations: {
        UPDATE_BONUS_ACTIONS(state, data) {
            return (state.bonus_actions.all = data);
        },

        UPDATE_BONUS_ACTION_TYPES(state, types) {
            return (state.bonus_actions.types = types);
        },

        UPDATE_PROMISE_BONUS_ACTIONS(state, data) {
            return state.bonus_actions.promise = data;
        }
    }
}
