<template>
  <div :class="className">
    <ins
        class="adsbygoogle"
        :style="{display: 'block'}"
        :data-ad-client="googleAdId"
        :data-ad-slot="slotter"
        data-ad-format="auto"
        data-full-width-responsive="true"
    ></ins>
  </div>
</template>

<script>
export default {
  name: 'AdSense',
  props: {
    timeout: {
      type: Number,
      default: 200
    },
    className: String,
    slotter: {
      type: String,
      default: '3900262111'
    },
  },
  data() {
    return {
      googleInit: null,
      googleAdId: 'ca-pub-9787170221354582'
    }
  },
  mounted() {
    this.googleInit = setTimeout(() => {
      if (typeof window !== 'undefined')
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, this.timeout);
  },
  unmounted() {
    if (this.googleInit) clearTimeout(this.googleInit);
  }
}
</script>
