<template>
  <base-filter
    ref="filter"
    :filter-label="$t('notifications.title')"
    :filter-options="filterOptions"
    :default-checked="[]"
    filter-key="notifications_filter"
    custom-order
    hide-divider
    only-one-choise
    @filter="filter"
  />
</template>

<script>
import BaseFilter from '@/components/left_bar/filters/BaseFilter';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'notifications-filter',
  components: {BaseFilter},
  data() {
    return {
      filterOptions: {
        'all': this.$t('notifications.filters.all'),
        'unread': this.$t('notifications.filters.unread'),
        'read': this.$t('notifications.filters.read'),
      },
    };
  },
  computed: {
    ...mapGetters('filter', [
      'FILTERS_VALUES',
    ]),
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_VALUE',
      'SET_FILTER_OPENED'
    ]),
    filter(checked) {
      this.SET_FILTER_VALUE({notifications_filter: checked});
    }
  },
}
</script>