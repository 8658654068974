import {createStore} from 'vuex'
import ad from '@/store/modules/ad';
import article from '@/store/modules/article';
import article_category from '@/store/modules/article_category';
import auth from '@/store/modules/auth';
import bonus_action from '@/store/modules/bonus_action';
import bookmark from '@/store/modules/bookmark';
import brand from '@/store/modules/brand';
import category from '@/store/modules/category';
import filter from '@/store/modules/filter';
import folder from '@/store/modules/folder';
import loader from '@/store/modules/loader';
import page from '@/store/modules/page';
import prize from '@/store/modules/prize';
import product from '@/store/modules/product';
import promo from '@/store/modules/promo';
import profile from '@/store/modules/profile';
import store from '@/store/modules/store';
import transaction from '@/store/modules/transaction';
import type from '@/store/modules/type';
import webshop from '@/store/modules/webshop';
import dialogs from '@/store/modules/dialogs';
import notifications from '@/store/modules/notifications';

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        ad,
        article,
        article_category,
        auth,
        bonus_action,
        bookmark,
        brand,
        category,
        dialogs,
        filter,
        'folder': folder,
        'folder-expired': folder,
        'folder-popular': folder,
        'folder-food': folder,
        'folder-garden': folder,
        'folder-media': folder,
        loader,
        notifications,
        'new-notifications': notifications,
        'old-notifications': notifications,
        page,
        prize,
        product,
        profile,
        promo,
        'posts': promo,
        'online-promos': promo,
        'folder-promos': promo,
        'promo-expired': promo,
        'promos-top': promo,
        'promos-expires-soon': promo,
        'promos-new': promo,
        'promo-hero': promo,
        store,
        transaction,
        type,
        webshop
    }
});
