<template>
  <default-grid :show-right-container="false" :show-left-container="false">
    <template v-slot:left-container>
      <left-bar show-user-menu></left-bar>
    </template>
    <template v-slot:main-container>
      <char-sorted-entities header="product.all_products"
                            :data="PRODUCTS"
                            :show-load="true"
                            :preview-count="PRODUCTS_PREVIEW_COUNT"
                            @load-char="loadMore"
                            route-name="product.show"
                            route-param-name="productSlug">
      </char-sorted-entities>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from '@/views/DefaultGrid';
import CharSortedEntities from '@/components/feed/CharSortedEntities';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'ProductsView',
  components: {CharSortedEntities, DefaultGrid},
  computed:{
    ...mapGetters('product', [
      'PRODUCTS',
      'PRODUCTS_PREVIEW_COUNT',
    ]),
  },
  mounted() {
    this.FETCH_PRODUCTS({preview_count: this.PRODUCTS_PREVIEW_COUNT});
  },
  methods: {
    ...mapActions('product', [
      'FETCH_PRODUCTS',
    ]),

    loadMore(char){
      this.FETCH_PRODUCTS({starts_from: char});
    }
  }
}
</script>
