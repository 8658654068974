<template>
  <el-dialog
    v-model="visible"
    :close-on-press-escape="false"
    :lock-scroll="false"
    close-on-click-modal
    show-close
    width="100vw"
    :custom-class="customClasses"
    center
    @close="closeDialog"
  >
    <div v-if="!['registered', 'verified', 'default'].includes(step)" class="el-dialog__body__nav-wrapper">
      <el-tag
        :effect="'login' === step ? 'plain' : 'dark'"
        class="cursor-pointer font-size-14"
        @click="$dialogs.changeTab('auth', 'register')"
      >
        <span v-t="'auth.register_for_free'"/>
      </el-tag>
      <el-tag
        :effect="'login' === step ? 'dark' : 'plain'"
        class="cursor-pointer font-size-14"
        @click="$dialogs.changeTab('auth', 'login')"
      >
        <span v-t="'auth.login'"/>
      </el-tag>
    </div>

    <template v-if="'default' === step">
      <default-step/>
    </template>

    <template v-if="'login' === step">
      <login-step @logged-in="closeDialog" @password-reset="closeDialog" :try-login-data="tryLoginData"/>
    </template>

    <template v-if="'register' === step">
      <register-step :try-register-data="tryRegisterData"/>
    </template>

    <template v-if="'registered' === step">
      <registered-step @submit="closeDialog"/>
    </template>

    <template v-if="'verified' === step">
      <verified-step/>
    </template>

    <template v-slot:header>
      <div>
        <i class="icon-arrow-4 icon-flipped float-start cursor-pointer" v-if="'default' !== step" @click="backDialog"/>
        <span v-t="'auth.only_logged_title'" v-if="'default' === step"/>
        <span v-t="'auth.verified_step.header'" v-else-if="'verified' === step"/>
        <span v-t="'auth.now_go_hunting'" v-else/>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import DefaultStep from '@/components/header/auth/Steps/DefaultStep.vue';
import LoginStep from '@/components/header/auth/Steps/LoginStep.vue';
import RegisteredStep from '@/components/header/auth/Steps/RegisteredStep.vue';
import RegisterStep from '@/components/header/auth/Steps/RegisterStep.vue';
import VerifiedStep from '@/components/header/auth/Steps/VerifiedStep';
import {mapState} from 'vuex';

export default {
  name: 'auth-dialog',
  components: {VerifiedStep, RegisterStep, LoginStep, RegisteredStep, DefaultStep},
  data() {
    return {
      tryLoginData: null,
      tryRegisterData: null,
      stepTabs: {
        'register': 'Gratis registreren',
        'login': 'Inloggen',
      }
    }
  },
  computed: {
    ...mapState('dialogs', {
      visible: ({dialogs}) => dialogs.auth.visible,
      step: ({dialogs}) => dialogs.auth.tab,
    }),
    customClasses() {
      const dialogClass = 'default' !== this.step ? 'login-dialog' : 'default-dialog'

      return dialogClass + ' primary-header-dialog'
    },
  },
  mounted() {
    this.emitter.on('try-login', (data) => {
      this.$dialogs.changeTab('auth', 'login')
      this.tryLoginData = data;
    });

    this.emitter.on('try-register', (data) => {
      this.$dialogs.changeTab('auth', 'register')
      this.tryRegisterData = data;
    });
  },
  methods: {
    backDialog() {
      this.tryLoginData = null;
      this.tryRegisterData = null;
      if ('default' === this.step) {
        this.closeDialog();
        return;
      }
      this.$dialogs.changeTab('auth', 'default')
    },
    closeDialog() {
      this.tryLoginData = null;
      this.tryRegisterData = null;
      this.show = false;
      this.$dialogs.hide('auth');
      setTimeout(() => this.$dialogs.changeTab('auth', 'default'), 200)
    }
  },
}
</script>
