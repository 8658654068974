<template>
  <el-row justify="center" class="bg-secondary">
    <el-col :span="23">
      <div class="container news-container">
        <el-row type="flex" justify="space-between">
          <el-col :span="24">
            <div class="main-container-wrapper">
              <h1 class="news-header-title color-text-primary">
                <span v-t="'news.news_title'"></span>
              </h1>
              <div class="blog-category-wrap">
                <categories-list></categories-list>
              </div>
              <news-feed></news-feed>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import CategoriesList from '@/components/feed/news/CategoriesList';
import NewsFeed from '@/views/news/NewsFeed';
import {mapActions} from 'vuex';

export default {
  name: 'NewsView',
  components: {
    CategoriesList,
    NewsFeed
  },
  beforeMount() {
    this.RESET_ARTICLES();
    this.RESET_CATEGORIES();
  },
  methods: {
    ...mapActions('article', [
      'RESET_ARTICLES',
    ]),
    ...mapActions('article_category', [
      'RESET_CATEGORIES',
    ])
  }
}
</script>
