<template>
  <div class="my-3">
    <div class="text-center color-primary font-size-120">
      <i class="icon-check"/>
    </div>

    <div class="color-primary text-center font-size-30" v-t="'auth.verified_step.label'"/>

    <div class="p-2 my-3" v-html="$t('auth.verified_step.text')"/>

    <el-button
      type="primary"
      class="w-100"
      @click="$dialogs.changeTab('auth', 'login')"
    >
      <span v-t="'auth.login'"/>
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'verified-step'
}
</script>
