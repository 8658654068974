<template>
  <el-row justify="center">
    <el-col :span="23">
      <div class="container">
        <el-row type="flex" justify="space-between">
          <el-col :span="6" v-if="mdAndUp && showLeftContainer">
            <aside>
              <slot name="left-container">
                <left-bar/>
              </slot>
            </aside>
          </el-col>

          <el-col :span="mainContainerSpan">
            <div class="main-container-wrapper">
              <slot name="main-container"/>
            </div>
          </el-col>

          <el-col :span="6" v-if="mdAndUp && showRightContainer">
            <aside>
              <slot name="right-container">
                <right-bar/>
              </slot>
            </aside>
          </el-col>
        </el-row>
      </div>
    </el-col>
  </el-row>

  <copied-discount-code-dialog></copied-discount-code-dialog>
</template>

<script>
import LeftBar from '@/components/left_bar/LeftBar';
import RightBar from '@/components/right_bar/RightBar';
import {discountCodes} from '@/mixins/post/discountCodes';
import CopiedDiscountCodeDialog from '@/components/feed/post/discount/CopiedDiscountCodeDialog.vue';

export default {
  name: 'default-grid',
  components: {CopiedDiscountCodeDialog, RightBar, LeftBar},
  props: {
    showBreadcrumb: {
      type: Boolean,
      default: true
    },
    showLeftContainer: {
      type: Boolean,
      default: true
    },
    showRightContainer: {
      type: Boolean,
      default: true
    }
  },
  mixins: [discountCodes],
  computed: {
    mainContainerSpan() {
      let spans = 24;
      if (this.showRightContainer) {
        spans -= 6
      }

      if (this.showLeftContainer) {
        spans -= 6
      }

      return this.mdAndUp ? spans : 24;
    },
  }
}
</script>
