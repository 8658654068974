<template>
  <div class="promos-section">
    <default-space>
      <div class="promos-section__container">
        <promos-carousel
          :promos="PROMOS"
          :is-loading="!!PROMOS_PROMISE"
          :custom-width="customWidth"
          :vuex-modules="{
            'post': 'promos-new',
            'online_promo': 'promos-new',
            'folder_promo': 'promos-new',
          }"
          show-load-more
          @more="$router.push({name: 'promos', query: { tab: 'recently-created' }})"
        >
          <template #header>
            <h2 class="promos-carousel-header__title" v-html="$t('landing.new_promos.title')"/>
            <router-localized-link
              class="promos-section__link"
              :to="{name: 'promos'}"
              :params="{tab: 'recently-created'}"
            >
              <span v-t="'landing.new_promos.subtitle'"/>
              <i class="icon-triangle-arrow ms-2"/>
            </router-localized-link>
          </template>
        </promos-carousel>
      </div>
    </default-space>
  </div>
</template>

<script>
import PromosCarousel from '@/components/feed/carousels/PromosCarousel.vue';
import {mapActions, mapGetters} from 'vuex';
import DefaultSpace from '@/views/DefaultSpace.vue';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink.vue';

export default {
  name: 'NewPromosSection',
  components: {RouterLocalizedLink, DefaultSpace, PromosCarousel},
  computed: {
    ...mapGetters('promos-new', [
      'PROMOS',
      'PROMOS_PROMISE',
      'PROMOS_TOTAL',
      'PROMOS_IS_COMPLETE',
    ]),
    customWidth() {
      if (this.xsOnly) {
        return '200px'
      } else if (this.lgAndUp) {
        return '25%'
      } else {
        return '33.3%'
      }
    }
  },
  data() {
    return {
      fetchOptions: {
        per_page: 15,
        only_valid_promos: 1,
        folders_included: 0,
        only_recently_created: 1,
        order: 'published_at',
      },
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    ...mapActions('promos-new', [
      'FETCH_PROMOS',
    ]),
    load() {
      return this.FETCH_PROMOS(this.fetchOptions);
    }
  }
}
</script>
