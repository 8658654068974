<template>
  <default-grid>
    <template v-slot:left-container>
      <div></div>
    </template>
    <template v-slot:main-container>
      <div class="feed-container"></div>
      <reset-password-dialog></reset-password-dialog>
    </template>
    <template v-slot:right-container>
      <div></div>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from '@/views/DefaultGrid';
import ResetPasswordDialog from '@/components/header/auth/ResetPasswordDialog';

export default {
  name: 'ResetPasswordView',
  components: {ResetPasswordDialog, DefaultGrid},
  data(){
    return {
      showResetDialog: true
    }
  }
}
</script>
