<template>
  <div class="auth-form">
    <div v-if="!xsOnly" class="container-image">
      <img src="@/assets/images/mascotte.svg" alt="">
    </div>
    <div class="centered" :class="{'w-100': xsOnly}">
      <div class='primary-form' :class="{'transform-primary-form': step === 'register'}">
        <div class="container-icon-settings">
          <span class="icon-settings-1"><span class="path1"></span><span class="path2"></span></span>
        </div>
        <div class="auth-form-close">
          <router-localized-link :to="{name: 'home'}">
            <span class="icon-close-4"></span>
          </router-localized-link>
        </div>

        <div class="container-header-title">
          <span>{{ $t('auth.now_go_hunting') }}</span>
        </div>

        <div>
          <login-step v-if="step === 'login'" @logged-in="closeDialog" @password-reset="closeDialog" :try-login-data="tryLoginData"/>
          <register-step v-else :try-register-data="tryRegisterData"/>
        </div>

        <router-localized-link v-if="step === 'login'"  :to="{name: 'register'}">
          <el-button type="primary" class="button-register w-100" v-t="'auth.register_for_free'"/>
        </router-localized-link>

        <div v-else class="container-go-log-in">
          <router-localized-link :to="{name: 'login'}">
            <span class="color-text-primary hover:text-underline" v-t="'header.login_button'"></span>
          </router-localized-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginStep from '@/components/header/auth/Steps/LoginStep.vue';
import RegisterStep from '@/components/header/auth/Steps/RegisterStep.vue';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink.vue';

export default {
  name: 'auth-form',
  components: {LoginStep, RegisterStep, RouterLocalizedLink},
  props: {
    stepDefault: {
      type: String,
      default: 'login',
    }
  },
  data() {
    return {
      step: this.stepDefault,
      tryLoginData: null,
      tryRegisterData: null,
    }
  },
  mounted() {
    this.emitter.on('try-register', (data) => {
      this.step = 'register';
      this.tryRegisterData = data;
    });

    this.emitter.on('try-login', (data) => {
      this.step = 'login';
      this.tryLoginData = data;
    });
  },
  methods: {
    closeDialog() {
      this.tryLoginData = null;
      this.tryRegisterData = null;
    },
  },
}
</script>
