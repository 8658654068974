import {mapActions, mapGetters} from 'vuex';

export const store_webshop_brand_product = {
    mounted() {
        //
    },
    data() {
        return {
            //
        };
    },
    computed: {
        ...mapGetters('filter', [
            'FILTERS_VALUES',
        ]),
        ...mapGetters('promo', [
            'PROMOS',
            'PROMOS_PROMISE',
            'PROMOS_TOTAL',
            'PROMOS_IS_COMPLETE',
        ]),
    },
    beforeMount() {
        this.resetResults();
        this.RESET_FILTERS().then(() => {
            this.SET_FILTER_VUEX_SOURCE({types_filter: 'promo'});
        });
    },
    beforeUnmount() {
        this.RESET_FILTERS();
    },
    methods: {
        ...mapActions('filter', [
            'SET_FILTER_ACTIVE',
            'SET_FILTER_INACTIVE',
            'SET_FILTER_VALUE',
            'SET_FILTER_VUEX_SOURCE',
            'RESET_FILTERS',
        ]),
        resetResults() {
            this.$store.dispatch('promo/RESET_PROMOS');
        },
        loadResults() {
            return this.$store.dispatch('promo/FETCH_PROMOS', this.fetchOptions);
        },
        tabChange(tabKey) {
            this.currentTab = tabKey;
            this.prepareFetchOptions();
            this.resetResults();
            this.loadResults();
        },
    },
    watch: {
        FILTERS_VALUES: {
            // the callback will be called immediately after the start of the observation
            deep: true,
            handler(newVal, oldVal) {
                this.prepareFetchOptions();
                this.resetResults();
                this.loadResults();
            }
        }
    }
};
